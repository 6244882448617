import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Button } from "primereact/button";
import { Paginator } from 'primereact/paginator';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import DocumentDetails from "./DocumentDetails.js";
import history from "../../Sources/js/history.js";
import Message from "../Helpers/Message.js";
import {fetch_url_post,is_in_array} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}
if(day<10) {
	day="0"+day;
}

let compareDate=year+month+day;

let defaultDataValues={
	documentsList: [],
	recipientsList: [],
	docTypesList: [],
};

let defaultInputValues={
	date_start:  new Date(),
	date_stop:  new Date(),
	deleted: false,
	doc_no: "",
	doc_from: "",
	doc_type: null,
	search_doc_no: "",
	search_doc_type: null,
	search_doc_created_by: null,
	search_doc_from: "",

};

let defaultDialogValues={
	openDocumentOperations: false,
	titleDocumentOperations: "",
};

let rows=10;

const DashboardSearch = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [blockScreen, setBlockScreen] = useState(false);
	const [filteredDocumentsList, setFilteredDocumentsList] = useState([]);
	const [filteredDocumentsListLength, setFilteredDocumentsListLength] = useState(0);
    const [documentsFirst, setDocumentsFirst] = useState(0);
	const [currentDocument, setCurrentDocument] = useState({});

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
        if(name==="search_doc_no") {
            //first filter according to search fields
            let tmp=dataValues.documentsList.filter((document)=>((document.doc_no==value)||(value==="")))
											.filter((document)=>(is_in_array(document.doc_type_id,inputValues.search_doc_type,1)))
											.filter((document)=>(is_in_array(document.doc_created_by_id,inputValues.search_doc_created_by,1)))
											.filter((document)=>((document.doc_from.toLowerCase().includes(inputValues.search_doc_from.toLowerCase()))||(inputValues.search_doc_from==="")));
            //next we will paginate the resulted array
            let tmp_paginated=[];
            let last=(rows>tmp.length?tmp.length:rows);
            for(var i=0;i<last;i++) {
                tmp_paginated.push(tmp[i]);
            }
            //last we will update filteredDocumentsList
            setFilteredDocumentsList(tmp_paginated);
            setFilteredDocumentsListLength(tmp.length);
        }
        if(name==="search_doc_type") {
			//first filter according to search fields
            let tmp=dataValues.documentsList.filter((document)=>((document.doc_no==inputValues.search_doc_no)||(inputValues.search_doc_no==="")))
											.filter((document)=>(is_in_array(document.doc_type_id,value,1)))
											.filter((document)=>(is_in_array(document.doc_created_by_id,inputValues.search_doc_created_by,1)))
											.filter((document)=>((document.doc_from.toLowerCase().includes(inputValues.search_doc_from.toLowerCase()))||(inputValues.search_doc_from==="")));
            //next we will paginate the resulted array
            let tmp_paginated=[];
            let last=(rows>tmp.length?tmp.length:rows);
            for(var i=0;i<last;i++) {
                tmp_paginated.push(tmp[i]);
            }
            //last we will update filteredDocumentsList
            setFilteredDocumentsList(tmp_paginated);
            setFilteredDocumentsListLength(tmp.length);
        }
        if(name==="search_doc_created_by") {
            //first filter according to search fields
            let tmp=dataValues.documentsList.filter((document)=>((document.doc_no==inputValues.search_doc_no)||(inputValues.search_doc_no==="")))
											.filter((document)=>(is_in_array(document.doc_type_id,inputValues.search_doc_type,1)))
											.filter((document)=>(is_in_array(document.doc_created_by_id,value,1)))
											.filter((document)=>((document.doc_from.toLowerCase().includes(inputValues.search_doc_from.toLowerCase()))||(inputValues.search_doc_from==="")));
            //next we will paginate the resulted array
            let tmp_paginated=[];
            let last=(rows>tmp.length?tmp.length:rows);
            for(var i=0;i<last;i++) {
                tmp_paginated.push(tmp[i]);
            }
            //last we will update filteredDocumentsList
            setFilteredDocumentsList(tmp_paginated);
            setFilteredDocumentsListLength(tmp.length);
        }
        if(name==="search_doc_from") {
            //first filter according to search fields
            let tmp=dataValues.documentsList.filter((document)=>((document.doc_no==inputValues.search_doc_no)||(inputValues.search_doc_no==="")))
											.filter((document)=>(is_in_array(document.doc_type_id,inputValues.search_doc_type,1)))
											.filter((document)=>(is_in_array(document.doc_created_by_id,inputValues.search_doc_created_by,1)))
											.filter((document)=>((document.doc_from.toLowerCase().includes(value.toLowerCase()))||(value==="")));
            //next we will paginate the resulted array
            let tmp_paginated=[];
            let last=(rows>tmp.length?tmp.length:rows);
            for(var i=0;i<last;i++) {
                tmp_paginated.push(tmp[i]);
            }
            //last we will update filteredDocumentsList
            setFilteredDocumentsList(tmp_paginated);
            setFilteredDocumentsListLength(tmp.length);
        }
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="operations") {
			setCurrentDocument(elementData);
			setDialogValues({...dialogValues,openDocumentOperations: true,titleDocumentOperations: "Detalii document nr. "+elementData.doc_no+" / "+elementData.doc_date});
		}
		if(what==="search") {
			oper="init_documents";
			let url="getDashboardDocuments";
			url+="?session_id="+props.session.sessId;
			let day=inputValues.date_start.getDate();
			let month=inputValues.date_start.getMonth()+1;
			let year=inputValues.date_start.getFullYear();
			if(month<10) {
				month="0"+month;
			}
			let date_start=year+"-"+month+"-"+day;
			day=inputValues.date_stop.getDate();
			month=inputValues.date_stop.getMonth()+1;
			year=inputValues.date_stop.getFullYear();
			if(month<10) {
				month="0"+month;
			}
			let date_stop=year+"-"+month+"-"+day;			
			let data={
				render: "search_document",
				start_date: date_start,
				stop_date: date_stop,
				include_deleted: inputValues.deleted,
				doc_no: inputValues.doc_no,
				doc_from: inputValues.doc_from,
				doc_type: inputValues.doc_type,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}
	}

	const changeHandler = evt => {
		//refreshes list in case of changes
		oper="init_documents";
		let url="getDashboardDocuments";
		url+="?session_id="+props.session.sessId;
		let data={
			render: "search_document",
			start_date: inputValues.date_start,
			stop_date: inputValues.date_stop,
			include_deleted: inputValues.deleted,
			doc_no: inputValues.doc_no,
			doc_from: inputValues.doc_from,
			doc_type: inputValues.doc_type,
		};
		fetch_url_post(url,data,success_handler,fail_handler);
	}

	const handleCloseOperations = (message) => {
		setDialogValues({...dialogValues,openDocumentOperations: false,titleDocumentOperations: ""});
		if(message) {
			setMessageText(message);
		}
	}	

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="init_documents") {
            let tmp=[];
			if(result.data.documentsList) {
				//form tooltip for recipients
				for(var i=0;i<result.data.documentsList.length;i++) {
					if(result.data.documentsList[i]) {
						if((result.data.documentsList[i].doc_recipients)&&(result.data.documentsList[i].doc_recipients_date)) {
							var tmp_recipients=result.data.documentsList[i].doc_recipients.split(",");
							var tmp_recipients_dates=result.data.documentsList[i].doc_recipients_date.split(",");
							result.data.documentsList[i].doc_recipients_tooltip="\n";
							if((tmp_recipients.length>0)&&(tmp_recipients_dates.length>0)) {
								for(var j=0;j<tmp_recipients.length;j++) {
									result.data.documentsList[i].doc_recipients_tooltip+=tmp_recipients_dates[j]+" trimis catre "+tmp_recipients[j]+"\n\n";
								}
							}
						}
                    }
                }
				for(var i=0;i<rows;i++) {
					if(result.data.documentsList[i]) {
                        tmp.push(result.data.documentsList[i]);
                    }
                }
				setDataValues({...dataValues,documentsList:result.data.documentsList,recipientsList:result.data.initDocuments.recipientsList,docTypesList:result.data.initDocuments.docTypesList});
                setFilteredDocumentsList(tmp);
				setFilteredDocumentsListLength(result.data.documentsList.length);
            }
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

    const onPageChange = (evt) => {
        setDocumentsFirst(evt.first);
        //first filter according to search fields
        let tmp=dataValues.documentsList.filter((document)=>((document.doc_no==inputValues.search_doc_no))||(inputValues.search_doc_no===""))
										.filter((document)=>(is_in_array(document.doc_type_id,inputValues.search_doc_type,1)))
										.filter((document)=>(is_in_array(document.doc_created_by_id,inputValues.search_doc_created_by,1)));
        //next we will paginate the resulted array
        let tmp_paginated=[];
        let last=(evt.first+rows>tmp.length?tmp.length:evt.first+rows);
        for(var i=evt.first;i<last;i++) {
            tmp_paginated.push(tmp[i]);
        }
        //last we will update filteredDocumentsTypes
        setFilteredDocumentsList(tmp_paginated);
    }

	useEffect(() => {
		if(props.session.sessId) {

		}else{
			history.push("/login")
		}
	},[]);

	const documentOperationsDialog = () => {
		return(
			<Dialog header={dialogValues.titleDocumentOperations} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDocumentOperations:false})} breakpoints={{"960px":"75vw"}} position="top" resizable={false} style={{"width":"99vw"}} contentStyle={{"minHeight":"666px","paddingBottom":"15px"}}>
				<DocumentDetails session={props.session} document={currentDocument} changeHandler={changeHandler} closeHandler={handleCloseOperations}/>
			</Dialog>
		);
	}

	const getStyleForEntry = (document) => {
		if((document.doc_due_date_compare>=compareDate)&&(document.user_due_date_compare>=compareDate)) {
			return({"backgroundColor":"#F8F9FA"});
		}
		if((document.doc_due_date_compare<compareDate)||(document.user_due_date_compare<compareDate)) {
			return({"backgroundColor":"#FF0000","color":"#FFFFFF"});
		}
	}

	return(
		<BlockUI blocked={blockScreen} fullScreen>
			<Grid container spacing={1} style={{}}>                
				<Grid item xs={12} sm={12} md={4} lg={3}>
					<div style={{"width":"48%","float":"left"}}>
						<Calendar name="date_start" id="date_start" dateFormat="dd/mm/yy" placeholder="Data start" style={{}} value={inputValues.date_start} onChange={(evt) => handleChange(evt)} showIcon readOnlyInput/>							
					</div>
					<div style={{"width":"48%","float":"right"}}>
						<Calendar name="date_stop" id="date_stop" minDate={inputValues.date_start} dateFormat="dd/mm/yy" placeholder="Data start" style={{}} value={inputValues.date_stop} onChange={(evt) => handleChange(evt)} showIcon readOnlyInput/>							
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={1}>
					<InputText name="doc_no" id="doc_no" value={inputValues.doc_no} onChange={(evt) => handleChange(evt)} placeholder="Nr.doc"/>
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={2}>
					<InputText name="doc_from" id="doc_from" value={inputValues.doc_from} onChange={(evt) => handleChange(evt)} placeholder="Provenienta"/>
				</Grid>
				<Grid item xs={12} sm={12} md={3} lg={2}>
					<MultiSelect name="doc_type" id="doc_type" value={inputValues.doc_type} style={{"width":"100%","height":"36px"}} options={dataValues.docTypesList} placeholder="Tip" onChange={(evt) => handleChange(evt)} optionLabel="name" filter showClear filterBy="name" showOnFocus display="chip" />
				</Grid>
				{!!(props.session.can_modify) &&
					<Grid item xs={12} sm={12} md={4} lg={2}>
						<div style={{"marginTop":"9px","width":"93%","float":"left"}} className="field-checkbox">
							<Checkbox inputId="deleted" checked={inputValues.deleted} onChange={(evt) => setInputValues({...inputValues,deleted:evt.checked})} />
							<label htmlFor="deleted">INCLUDE ANULATE</label>
						</div>
					</Grid>
				}
				<Grid item xs={6} sm={6} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
					<Button type="button" label="Cautare" icon="pi pi-search" style={{"marginTop":"3px"}} className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"search")} />
				</Grid>																						
				<Grid item xs={6} sm={6} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
					<Button type="button" label="Resetare" icon="pi pi-times" style={{"marginTop":"3px"}} className="p-button-sm p-button-danger" onClick={(evt) => {setDataOk(false);setInputValues(defaultInputValues)}} />
				</Grid>																						
				{!!(dataOk && dataValues.documentsList.length>0) &&
					<React.Fragment>
						<Grid item xs={12} sm={12} md={3} lg={3}>
							<InputText name="search_doc_from" id="search_doc_from" value={inputValues.search_doc_from} onChange={(evt) => handleChange(evt)} placeholder="Filtrare dupa provenienta"/>
						</Grid>
						<Grid item xs={12} sm={12} md={3} lg={3}>
							<MultiSelect name="search_doc_type" id="search_doc_type" value={inputValues.search_doc_type} style={{"width":"100%","height":"36px"}} options={dataValues.docTypesList} placeholder="Filtrare dupa tip" onChange={(evt) => handleChange(evt)} optionLabel="name" filter showClear filterBy="name" showOnFocus display="chip" />
						</Grid>
						<Grid item xs={12} sm={12} md={3} lg={3}>
							<MultiSelect name="search_doc_created_by" id="search_doc_created_by" value={inputValues.search_doc_created_by} style={{"width":"100%","height":"36px"}} options={dataValues.recipientsList} placeholder="Filtrare dupa emitent" onChange={(evt) => handleChange(evt)} optionLabel="name" filter showClear filterBy="name" showOnFocus display="chip" />
						</Grid>						
						<Grid item xs={12} sm={12} md={3} lg={3}>
							<InputText name="search_doc_no" id="search_doc_no" value={inputValues.search_doc_no} onChange={(evt) => handleChange(evt)} placeholder="Filtrare dupa numar"/>
						</Grid>
					</React.Fragment>
				}
			</Grid>
			<br clear="all"/>
			{!!(!dataOk) &&
				<React.Fragment>
					<div style={{"height":"163px"}}>
						<p style={{"width":"100%","marginTop":"87px","fontWeight":"600","color":"#FF0000","textAlign":"center"}}>Completati criteriile de cautare si apasati butonul "Cautare"</p>
					</div>
				</React.Fragment>
			}
			{!!(dataOk && filteredDocumentsList.length>0) &&
				<React.Fragment>
					<Grid container spacing={1} style={{}}>                
						<Grid item xs={12} sm={12} md={12} lg={12} key={document.id}>
							<Card style={{"backgroundColor":"#F8F9FA"}} className="tooltip_button">
								<Grid container spacing={1} style={{"marginTop":"-24px"}}>                
									<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
										Institutie												
									</Grid>																						
									<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
										Numar/data												
									</Grid>																						
									<Grid item xs={4} sm={4} md={4} lg={4} style={{"fontWeight":"600","textAlign":"center"}}>
										Categorie / Tip document / Provenienta
									</Grid>																						
									<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
										Trimis catre												
									</Grid>																						
									<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center"}}>
										Termen
									</Grid>																						
									<Grid item xs={3} sm={3} md={3} lg={3} style={{"fontWeight":"600","textAlign":"center"}}>
										Creat de catre / la data
									</Grid>																						
									<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
										Operatiuni												
									</Grid>																						
								</Grid>										
							</Card>
						</Grid>
						{filteredDocumentsList.map((document) =>
							<Grid item xs={12} sm={12} md={12} lg={12} key={document.id} name="dashboard_documents_details" id="dashboard_documents_details">
								{document.deleted===0 &&
									<Card style={getStyleForEntry(document)}>
										<Grid container spacing={1} style={{"marginTop":"-24px"}}>                
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
												{document.emittent}												
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center"}}>
												{document.doc_no}/{document.doc_date}												
											</Grid>																						
											<Grid item xs={4} sm={4} md={4} lg={4} style={{"textAlign":"center"}}>
												{document.doc_category} / {document.doc_type} / {document.doc_from}
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center"}}>
												<Button type="button" label="?" icon="pi pi-align-justify" tooltip={document.doc_recipients_tooltip} style={{"marginTop":"-9px","marginBottom":"-3px"}} className="p-button-sm p-button-primary tooltip_button" />
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center"}}>
												{document.doc_due_date}
											</Grid>																						
											<Grid item xs={3} sm={3} md={3} lg={3} style={{"textAlign":"center"}}>
												{document.doc_created_by} / {document.doc_created_at}
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
												<Button type="button" label="Operatiuni" icon="pi pi-align-justify" style={{"marginTop":"-9px","marginBottom":"-3px"}} className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"operations",document)} />
											</Grid>																						
										</Grid>										
									</Card>
								}
								{document.deleted===1 &&
									<Card style={{"backgroundColor":"#F8F9FA"}}>
										<Grid container spacing={1} style={{"marginTop":"-24px"}}>                
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left","textDecoration":"line-through"}}>
												{document.emittent}												
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center","textDecoration":"line-through"}}>
												{document.doc_no}/{document.doc_date}												
											</Grid>																						
											<Grid item xs={4} sm={4} md={4} lg={4} style={{"textAlign":"center","textDecoration":"line-through"}}>
												{document.doc_category} / {document.doc_type} / {document.doc_from}
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center","textDecoration":"line-through"}}>
												<Button type="button" label="?" icon="pi pi-align-justify" tooltip={document.doc_recipients_tooltip} style={{"marginTop":"-9px","marginBottom":"-3px"}} className="p-button-sm p-button-primary tooltip_button" />
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center","textDecoration":"line-through"}}>
												{document.doc_due_date}
											</Grid>																						
											<Grid item xs={3} sm={3} md={3} lg={3} style={{"textAlign":"center","textDecoration":"line-through"}}>
												{document.doc_created_by} / {document.doc_created_at}
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
												<Button type="button" label="Operatiuni" icon="pi pi-align-justify" style={{"marginTop":"-9px","marginBottom":"-3px"}} className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"operations",document)} />
											</Grid>																						
										</Grid>										
									</Card>
								}
							</Grid>
						)}
					</Grid>
					<Paginator first={documentsFirst} rows={rows} totalRecords={filteredDocumentsListLength} onPageChange={onPageChange}></Paginator>
				</React.Fragment>
			}
			{!!(dataOk && filteredDocumentsList.length===0) &&
				<div style={{"height":"163px"}}>
					<p style={{"width":"100%","marginTop":"87px","fontWeight":"600","color":"#FF0000","textAlign":"center"}}>Nu exista documente conform cautarii.</p>
				</div>
			}
			<Tooltip target=".tooltip_button" onBeforeShow={(evt)=>console.log("tooltip open")} change={filteredDocumentsList.length}></Tooltip>
			{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			{dialogValues.openDocumentOperations && documentOperationsDialog()}
		</BlockUI>
	);
};

export default DashboardSearch;
