import React from "react";
import {useState,useEffect} from "react";
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import Message from "./Helpers/Message.js";
import {Toolbar} from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import {Box} from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import history from "../Sources/js/history.js";
import MenuIcons from "./MenuIcons.js";
import Users from "./Sections/Users.js";
import NewDocument from "./Sections/NewDocument.js";
import Departments from "./Sections/Departments.js";
import Registers from "./Sections/Registers.js";
import NDocumentsTypes from "./Sections/NDocumentsTypes.js";
import Dashboard from "./Sections/Dashboard.js";
import DashboardDocumentsDetails from "./Sections/DashboardDocumentsDetails.js";
import MainDocumentsRegister from "./Sections/MainDocumentsRegister.js";
import UserSettings from "./Sections/UserSettings.js";
import Units from "./Sections/Units.js";
import NFluxes from "./Sections/NFluxes.js";

import UnitSettings from "./Sections/UnitSettings.js";
import {fetch_url_post} from "../Sources/js/app.js";

let oper="";
let defaultDialogValues={
	openUserSettings: false,
	titleUserSettings: "Setari utilizator",
};

export default function MainPage(props) {

	const [menuOpen, setMenuOpen] = useState(false);
	const [menuSections, setMenuSections] = useState([]);
	const [messageText, setMessageText] = useState(null);
	const [currentSection, setCurrentSection] = useState();
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);

	const handleButton = (evt,what) => {
		if(what==="menu") {
			setMenuOpen(!menuOpen);
		}
		if(what==="user_settings") {
			setDialogValues({...dialogValues,openUserSettings: true});
		}
	}

	const success_handler = (result) => {
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="getMenuSections") {
			setMenuSections(result.data);
		}
		if(oper==="validateSession") {
			props.loginHandler(result);
		}
	}

	const fail_handler = () => {
		setMessageText("Eroare de conexiune.");
	}

	useEffect(() => {
		setCurrentSection(translateSection(props.section));
	},[props.section]);

	useEffect(() => {
		window.localStorage.removeItem("docReturnPathXa7");
		if(props.section) {
			window.localStorage.setItem("docReturnPathXa7","/"+props.section);
		}else{
			window.localStorage.setItem("docReturnPathXa7","/");			
		}
		if(!props.session.sessId) {
			validateSession(window.localStorage.getItem("docSessIdXa7"));
		}
		setCurrentSection(translateSection(props.section));
	},[props.session.sessId]);

	useEffect(() => {
		if(props.session.sessId) {
			oper="getMenuSections";
			let url="getMenuSections";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}
	},[]);

	const validateSession = (sessionId) => {
		if(!sessionId) {
			history.push("/login");						
			return false;
		}
		oper="validateSession";
		let url="validateSession";
		url+="?session_id="+sessionId;
		let data={};
		fetch_url_post(url,data,success_handler,fail_handler);
	}

	const handleSection = (evt,section) => {
		//used only for menu sections and appbar sections
		setMenuOpen(false);
//		setCurrentSection(section);
		switch(section) {
			case "Panou principal":
				history.push("/");
				break;
			case "Document nou":
				history.push("/newDocument");
				break;
			case "Utilizatori":
				history.push("/users");
				break;
			case "Departamente":
				history.push("/departments");
				break;
			case "Registre":
				history.push("/registers");
				break;
			case "Nomenclator lucrari":
				history.push("/nDocumentsTypes");
				break;
			case "Registru intrari/iesiri":
				history.push("/mainDocumentsRegister");
				break;
			case "Setari unitate":
				history.push("/unitSettings");
				break;
			case "Institutii subordonate":
				history.push("/units");
				break;
			case "Nomenclator fluxuri":
				history.push("/nFluxes");
				break;
		}
	}

	const getCurrentSection = (section) => {
		//used to render current content from all sources
		switch(section) {
			case "newDocument":
				return(
					<NewDocument session={props.session} step={props.step}/>
				);
				break;
			case "users":
				return(
					<Users session={props.session}/>
				);
				break;
			case "departments":
				return(
					<Departments session={props.session}/>
				);
				break;
			case "registers":
				return(
					<Registers session={props.session}/>
				);
				break;
			case "nDocumentsTypes":
				return(
					<NDocumentsTypes session={props.session}/>
				);
				break;
			case "receivedDocuments":
				return(
					<DashboardDocumentsDetails session={props.session} type="received"/>
				);
				break;
			case "acceptedDocuments":
				return(
					<DashboardDocumentsDetails session={props.session} type="accepted"/>
				);
				break;
			case "emittedDocuments":
				return(
					<DashboardDocumentsDetails session={props.session} type="emitted"/>
				);
				break;
			case "recentEvents":
				return(
					<DashboardDocumentsDetails session={props.session} type="recent"/>
				);
				break;
			case "mainDocumentsRegister":
				return(
					<MainDocumentsRegister session={props.session}/>
				);
				break;
			case "unitSettings":
				return(
					<UnitSettings session={props.session}/>
				);
				break;
			case "units":
				return(
					<Units session={props.session}/>
				);
				break;
			case "nFluxes":
				return(
					<NFluxes session={props.session}/>
				);
				break;
			default:
				return(
					<Dashboard session={props.session} subsection={props.subsection}/>
				);
		}
	}

	const translateSection = (section) =>{
		//used for main dialog title
		switch(section) {
			case "newDocument":
				return "Document nou";
				break;
			case "users":
				return "Utilizatori";
				break;
			case "departments":
				return "Departamente";
				break;
			case "Registre":
				return "Registre";
				break;
			case "nDocumentsTypes":
				return "Nomenclator lucrari";
				break;
			case "receivedDocuments":
				return "Documente de acceptat";
				break;
			case "acceptedDocuments":
				return "Documente acceptate";
				break;
			case "emittedDocuments":
				return "Documente emise de mine";
				break;
			case "recentEvents":
				return "Miscari recente";
				break;
			case "mainDocumentsRegister":
				return "Registru intrari/iesiri";
				break;
			case "unitSettings":
				return "Setari";
				break;
			case "units":
				return "Institutii subordonate";
				break;
			case "nFluxes":
				return "Nomenclator fluxuri";
				break;
			default:
				return "Panou principal";
		}
	}

    const leftToolBarContents = (
        <React.Fragment>
			<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
				<Button label="Meniu" icon="pi pi-bars" className="p-button-sm p-button-primary" style={{"marginRight":"8px"}} onClick={(evt) => handleButton(evt,"menu")}/>
				<Button label="Panou principal" icon="pi pi-book" className="p-button-sm p-button-success" style={{"marginRight":"8px"}} onClick={(evt) => handleSection(evt,"Panou principal")}/>
				<Button label="Inregistrare" icon="pi pi-plus" className="p-button-sm p-button-primary" style={{"marginRight":"8px"}} onClick={(evt) => handleSection(evt,"Document nou")}/>
			</Box>
			<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
				<Button icon="pi pi-bars" className="p-button-sm p-button-primary" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"menu")}/>
				<Button icon="pi pi-book" className="p-button-sm p-button-success" style={{"marginRight":"6px"}} onClick={(evt) => handleSection(evt,"Panou principal")}/>
				<Button label="Inregistrare" icon="pi pi-plus" className="p-button-sm p-button-primary" style={{"marginRight":"6px"}} onClick={(evt) => handleSection(evt,"Document nou")}/>
			</Box>
        </React.Fragment>
    );

    const rightToolBarContents = (
        <React.Fragment>
			<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
				<p className="p-button-primary" style={{"marginRight":"12px","display":"inline","lineHeight":"2.1"}}>{props.session.name}</p>
				<Button label="Setari" icon="pi pi-cog" style={{"marginRight":"9px"}} className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"user_settings")}/>
				<Button label="Logout" icon="pi pi-power-off" className="p-button-sm p-button-danger" onClick={(evt) => props.logoutHandler(evt)}/>
			</Box>
			<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
				<Button icon="pi pi-cog" style={{"marginRight":"9px"}} className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"user_settings")}/>
				<Button icon="pi pi-power-off" className="p-button-sm p-button-danger" onClick={(evt) => props.logoutHandler(evt)}/>
			</Box>
        </React.Fragment>
    );

	const userSettingsDialog = () => {
		return(
			<Dialog header={dialogValues.titleUserSettings} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openUserSettings:false})} breakpoints={{"960px":"75vw"}} resizable={false} style={{"width":"36vw"}} contentStyle={{"minHeight":"333px","paddingBottom":"15px"}}>
				<UserSettings session={props.session} closeHandler={(evt)=>setDialogValues({...dialogValues,openUserSettings:false})}/>
			</Dialog>
		);
	}

	if(props.session.sessId) {
		return(
			<div style={{"paddingTop":"12px"}}>
				<Sidebar visible={menuOpen} onHide={() => setMenuOpen(false)} style={{"width":"360px"}}>
					<h3 style={{"width":"100","textAlign":"center","marginBottom":"30px"}}>Meniu aplicatie</h3>
					{menuSections.length>0 && menuSections.map((section,index) => 
						<div key={index} onClick={(evt) => handleSection(evt,section.name)}>
							<MenuIcons section={section.name}/>
						</div>
					)}
				</Sidebar>
				<div style={{"width":"97%","margin":"0 auto","marginBottom":"9px"}}>
					<Toolbar left={leftToolBarContents} right={rightToolBarContents} style={{"padding":"0.3rem","paddingLeft":"1rem","paddingRight":"1rem"}}/>
				</div>
				<div style={{"width":"100%"}}>
					<Card title={currentSection} style={{"width":"97%","minHeight":"87.9vh","margin":"0 auto"}}>
						{getCurrentSection(props.section)}				
					</Card>
				</div>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openUserSettings && userSettingsDialog()}
			</div>
		);
	}else{
		return(
			<LinearProgress />
		);
	}
}
