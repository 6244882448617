import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';

const Message = (props) => {

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const close_message=(event,reason) => {
		if(reason==="clickaway") {
			return;
		}
        props.closeHandler();
    }

    let severity="success";
    let duration=3000;

    if(props.text.indexOf("asteptati")>-1) {
        severity="info";
        duration=60000;
    }
    if((props.text.indexOf("roare")>-1)||(props.text.indexOf("ompletati")>-1)||(props.text.indexOf("rror")>-1)||(props.text.indexOf("esuata")>-1)||(props.text.indexOf("problema")>-1)) {
        severity="error";
    }

    return(
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={true}
            autoHideDuration={3000}
            onClose={close_message}
        >
            <Alert onClose={close_message} severity={severity} sx={{"width":"100%"}}>
                {props.text}
            </Alert>
        </Snackbar>      
    );
};

export default Message;