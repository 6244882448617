import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { Card } from "primereact/card";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post,get_due_date,is_in_array,format_date} from "../../Sources/js/app.js";

let oper="";

let today = new Date();

let defaultInputValues={
	id: "",
	user: {},
	due_date: today,
	tasks: "",
};

let defaultDataValues={
	fluxUsersList: [],
	fluxFilesList: [],
	usersList: [],
};

let defaultDialogValues={
	openTasksUser: false,
	titleTasksUser: "",
	openAddRecipient: false,
	titleAddRecipient: "",
};

let max_due_date=new Date();

const DocumentRecipients = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [currentUser, setCurrentUser] = useState(null);

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="days") {
			if(evt.target.validity.valid) {
				setInputValues({...inputValues,[name]:value});
			}
		}else{
			setInputValues({...inputValues,[name]:value});			
		}
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add_recipient") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddRecipient: true,titleAddRecipient: "Trimite catre utilizator"});
		}
		if(what==="user_tasks") {
			setCurrentUser(elementData);
			setDialogValues({...dialogValues,openTasksUser: true,titleTasksUser: "Sarcini utilizator"});
		}
		if(what==="save_recipient") {
			document.getElementById("user").classList.remove("required_input_error");
			document.getElementById("due_date").classList.remove("required_input_error");
			if(!inputValues.user.id) {
				setMessageText("Eroare: Selectati un utilizator din lista!");				
				document.getElementById("user").classList.add("required_input_error");
				return false;
			}
			if(!inputValues.due_date) {
				setMessageText("Eroare: Completati data limita!");				
				document.getElementById("due_date").classList.add("required_input_error");
				return false;
			}
			let tmp_date=new Date(inputValues.due_date);
			let data={
				id: inputValues.user.id,
				due_date: format_date(tmp_date,"yyyy-mm-dd"),
				user_name: inputValues.user.full_user_name,
				due_date_parsed: format_date(tmp_date,"dd-mm-yyyy"),
				tasks: inputValues.tasks,
			}
			let data_array=[];
			data_array.push(data);
			props.saveHandler(evt,data_array);
			setDialogValues({...dialogValues,openAddRecipient: false});
		}
		if(what==="apply_flux") {
			let data_array=[];
			dataValues.fluxUsersList.forEach(flux_user => {
				if(!is_in_array(flux_user,props.recipients,0,"user_id","id")) {
					let tmp_date=get_due_date(flux_user.days);
					console.log(format_date(tmp_date,"dd-mm-yyyy"));
					let data={
						id: flux_user.user_id,
						due_date: format_date(tmp_date,"yyyy-mm-dd"),
						user_name: flux_user.user_name,
						due_date_parsed: format_date(tmp_date,"dd-mm-yyyy"),
						tasks: flux_user.tasks,
					}
					data_array.push(data);
				}
			});
			props.saveHandler(evt,data_array);
		}
		if(what==="remove_recipient") {
			props.removeHandler(evt,elementData);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="get") {
			setDataValues({...dataValues,
							fluxUsersList:(result.data.fluxUsersList?result.data.fluxUsersList:[]),
							usersList:(result.data.usersList?result.data.usersList:[])
			});
            if(!dataOk) {
                setDataOk(true);
            }
			setInputValues(defaultInputValues);
			setDialogValues(defaultDialogValues);
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getFluxDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				doc_type_id: props.doc_type.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
			max_due_date=new Date(props.max_due_date);
			console.log(max_due_date);
		}else{
			history.push("/login")
		}
	},[]);

	const addRecipientDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddRecipient} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddRecipient:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={12} sm={6} md={9} lg={9}>
						<span className="p-float-label">
							<Dropdown name="user" id="user" style={{"width":"100%","height":"36px"}} value={inputValues.user} options={dataValues.usersList.filter((user)=>!is_in_array(user,props.recipients,0,"id","id"))} onChange={(evt) => handleChange(evt)} optionLabel="full_user_name"/>
							<label htmlFor="user">Utilizator*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>											
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<span className="p-float-label">
							<Calendar name="due_date" id="due_date" dateFormat="dd/mm/yy" style={{"width":"100%"}} value={inputValues.due_date} onChange={(evt) => handleChange(evt)} showIcon readOnlyInput maxDate={max_due_date}/>
							<label htmlFor="due_date">Data limita*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>											
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputTextarea name="tasks" id="tasks" style={{"width":"100%","minHeight":"48px"}} value={inputValues.tasks} onChange={(evt) => handleChange(evt)} rows={12}/>
							<label htmlFor="tasks">Sarcini</label>
						</span>
					</Grid>											
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right"}}>
						<Button type="button" label="Adauga utilizator" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" style={{"marginTop":"12px","marginRight":"3px"}} onClick={(evt) => handleButton(evt,"save_recipient")}/>									
						<Button type="button" label="Inchide fereastra" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{"marginTop":"12px"}} onClick={(evt) => setDialogValues({...dialogValues,openAddRecipient:false})}/>									
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const tasksUserDialog = () => {
		return(
			<Dialog header={dialogValues.titleTasksUser} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openTasksUser:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputTextarea name="tasks" id="tasks" style={{"width":"100%","minHeight":"48px"}} value={currentUser.tasks} onChange={(evt) => handleChange(evt)} rows={12}/>
							<label htmlFor="tasks">Sarcini</label>
						</span>
					</Grid>											
				</Grid>
			</Dialog>
		);
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<p style={{"marginBottom":"30px","fontWeight":"603","width":"100%","textAlign":"center"}}>Utilizatori definiti in flux asociat document</p>
				<div style={{"height":"300px","border":"0px solid black"}}>
					<Grid container spacing={1} alignItems="flex-start" name="flux_components" id="flux_components" style={{"marginBottom":"18px"}}>
						{dataValues.fluxUsersList.length>0 &&
							<React.Fragment>
								{dataValues.fluxUsersList.map((flux_user) =>
									<Grid item xs={12} sm={12} md={12} lg={6} key={flux_user.id} onClick={(evt) => handleButton(evt,"user_tasks",flux_user)}>
										<Card style={{"backgroundColor":"#F8F9FA","padding":"0px"}}>
											<Grid container spacing={1} alignItems="flex-start">
												<Grid item xs={8} sm={8} md={8} lg={8}>
													{flux_user.user_name}
												</Grid>											
												<Grid item xs={3} sm={3} md={3} lg={3}>
													{"ALOCAT "+flux_user.days+" zile"}
												</Grid>											
												<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"right"}}>
													<Button type="button" icon="pi pi-list" className="p-button-sm p-button-primary" style={{"marginTop":"-9px"}} onClick={(evt) => handleButton(evt,"user_tasks",flux_user)}/>									
												</Grid>											
											</Grid>
										</Card>
									</Grid>
								)}
							</React.Fragment>
						}
						{dataValues.fluxUsersList.length===0 &&
							<div style={{"height":"113px","width":"100%","textAlign":"center"}}>
								<p style={{"marginTop":"84px","fontWeight":"600","color":"#FF0000"}}>Nu exista utilizatori definiti in fluxul documentului.</p>
							</div>
						}
					</Grid>
				</div>
				<p style={{"marginBottom":"15px","fontWeight":"603","width":"100%","textAlign":"center"}}>Utilizatori asociati documentului</p>
				<div style={{"height":"300px","border":"0px solid black"}}>
					<Grid container spacing={1} alignItems="flex-start" name="recipients_list" id="recipients_list" style={{"marginBottom":"18px"}}>
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginBottom":"12px"}}>
							<Button type="button" label="Trimite catre" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" style={{"marginTop":"-12px","marginRight":"3px"}} onClick={(evt) => handleButton(evt,"add_recipient")}/>									
							{dataValues.fluxUsersList.length>0 &&
								<Button type="button" label="Aplica flux" icon="pi pi-sort-numeric-up-alt" className="p-button-sm p-button-success" style={{"marginTop":"-12px","marginRight":"3px"}} onClick={(evt) => handleButton(evt,"apply_flux")}/>									
							}
						</Grid>
						{!!(props.recipients) &&
							<React.Fragment>
							{props.recipients.map((recipient) =>
								<Grid item xs={12} sm={6} md={6} lg={6} key={recipient.id}>
									<Card style={{"backgroundColor":"#F8F9FA","padding":"0px"}}>
										<Grid container spacing={1} alignItems="flex-start">
											<Grid item xs={9} sm={9} md={9} lg={9}>
												{recipient.user_name}
											</Grid>											
											<Grid item xs={2} sm={2} md={2} lg={2}>
												{recipient.due_date_parsed}
											</Grid>											
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"right"}}>
												<Button type="button" icon="pi pi-trash" className="p-button-sm p-button-danger" style={{"marginTop":"-9px"}} onClick={(evt) => handleButton(evt,"remove_recipient",recipient)}/>									
											</Grid>											
										</Grid>
									</Card>
								</Grid>
							)}
							</React.Fragment>
						}
					</Grid>
				</div>
				{dialogValues.openTasksUser && tasksUserDialog()}
				{dialogValues.openAddRecipient && addRecipientDialog()}
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default DocumentRecipients;

