import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Button } from "primereact/button";
import { Paginator } from 'primereact/paginator';
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";
import history from "../../Sources/js/history.js";
import Message from "../Helpers/Message.js";
//import ExcelReport from "./ExcelReport.js";
import {fetch_url_post,fetch_download} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}
if(day<10) {
	day="0"+day;
}

let compareDate=year+month+day;

let defaultDataValues={
	documentsList: [],
	recipientsList: [],
	docTypesList: [],
};

let defaultInputValues={
	search_date_start:  new Date(),
	search_date_stop:  new Date(),
};

let rows=10;

const MainDocumentsRegister = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [filteredDocumentsList, setFilteredDocumentsList] = useState([]);
	const [filteredDocumentsListLength, setFilteredDocumentsListLength] = useState(0);
    const [documentsFirst, setDocumentsFirst] = useState(0);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="search") {
			oper="init_documents";
			let url="getMainDocumentsRegisterList";
			url+="?session_id="+props.session.sessId;
			let day=inputValues.search_date_start.getDate();
			let month=inputValues.search_date_start.getMonth()+1;
			let year=inputValues.search_date_start.getFullYear();
			if(month<10) {
				month="0"+month;
			}
			let search_date_start=year+"-"+month+"-"+day;
			day=inputValues.search_date_stop.getDate();
			month=inputValues.search_date_stop.getMonth()+1;
			year=inputValues.search_date_stop.getFullYear();
			if(month<10) {
				month="0"+month;
			}
			let search_date_stop=year+"-"+month+"-"+day;			
			let data={
				start_date: search_date_start,
				stop_date: search_date_stop,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}
		if(what==="xls_export") {
			oper="xls_export";
			let url="getMainDocumentsRegisterListXLS";
			url+="?session_id="+props.session.sessId;
			let day=inputValues.search_date_start.getDate();
			let month=inputValues.search_date_start.getMonth()+1;
			let year=inputValues.search_date_start.getFullYear();
			if(month<10) {
				month="0"+month;
			}
			let search_date_start=year+"-"+month+"-"+day;
			day=inputValues.search_date_stop.getDate();
			month=inputValues.search_date_stop.getMonth()+1;
			year=inputValues.search_date_stop.getFullYear();
			if(month<10) {
				month="0"+month;
			}
			let search_date_stop=year+"-"+month+"-"+day;			
			let data={
				start_date: search_date_start,
				stop_date: search_date_stop,
			};
			fetch_download(url,data,"Registru_intrari_iesiri.xls",success_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="init_documents") {
            let tmp=[];
			if(result.data.documentsList) {
				//form tooltip for recipients
				for(var i=0;i<result.data.documentsList.length;i++) {
					if(result.data.documentsList[i]) {
						if((result.data.documentsList[i].doc_recipients)&&(result.data.documentsList[i].doc_recipients_date)) {
							var tmp_recipients=result.data.documentsList[i].doc_recipients.split(",");
							var tmp_recipients_dates=result.data.documentsList[i].doc_recipients_date.split(",");
							result.data.documentsList[i].doc_recipients_tooltip="\n";
							if((tmp_recipients.length>0)&&(tmp_recipients_dates.length>0)) {
								for(var j=0;j<tmp_recipients.length;j++) {
									result.data.documentsList[i].doc_recipients_tooltip+="La data "+tmp_recipients_dates[j]+" trimis catre "+tmp_recipients[j]+"\n\n";
								}
							}
						}
                    }
                }
				for(var i=0;i<rows;i++) {
					if(result.data.documentsList[i]) {
                        tmp.push(result.data.documentsList[i]);
                    }
       			}
				setDataValues({...dataValues,documentsList:result.data.documentsList});
                setFilteredDocumentsList(tmp);
				setFilteredDocumentsListLength(result.data.documentsList.length);
            }
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

    const onPageChange = (evt) => {
        setDocumentsFirst(evt.first);
        let tmp=dataValues.documentsList;
        //next we will paginate the resulted array
        let tmp_paginated=[];
        let last=(evt.first+rows>tmp.length?tmp.length:evt.first+rows);
        for(var i=evt.first;i<last;i++) {
            tmp_paginated.push(tmp[i]);
        }
        //last we will update filteredDocumentsTypes
        setFilteredDocumentsList(tmp_paginated);
    }

	useEffect(() => {
		if(props.session.sessId) {

		}else{
			history.push("/login")
		}
	},[]);

	return(
		<BlockUI blocked={blockScreen} fullScreen>
			<Grid container spacing={1} style={{}}>                
				<Grid item xs={12} sm={4} md={4} lg={4}>
					<div style={{"width":"48%","float":"left"}}>
						<Calendar name="search_date_start" id="search_date_start" dateFormat="dd/mm/yy" placeholder="Data start" style={{}} value={inputValues.search_date_start} onChange={(evt) => handleChange(evt)} showIcon readOnlyInput/>							
					</div>
					<div style={{"width":"48%","float":"right"}}>
						<Calendar name="search_date_stop" id="search_date_stop" minDate={inputValues.search_date_start} dateFormat="dd/mm/yy" placeholder="Data start" style={{}} value={inputValues.search_date_stop} onChange={(evt) => handleChange(evt)} showIcon readOnlyInput/>							
					</div>
				</Grid>
				<Grid item xs={12} sm={3} md={3} lg={3} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
					<Button type="button" label="Genereaza registru" icon="pi pi-align-center" style={{"marginTop":"3px"}} className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"search")} />
				</Grid>																						
				{!!(dataOk && filteredDocumentsList.length>0) &&
					<React.Fragment>
						<Button type="button" label="Export excel" icon="pi pi-file-excel" style={{"marginTop":"12px","marginRight":"12px"}} className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"xls_export")} />
					</React.Fragment>
				}
			</Grid>
			<br clear="all"/>
			{!!(dataOk && filteredDocumentsList.length>0) &&
				<React.Fragment>
					<Grid container spacing={1} style={{}}>                
						<Grid item xs={12} sm={12} md={12} lg={12} key={document.id}>
							<Card style={{"backgroundColor":"#F8F9FA"}}>
								<Grid container spacing={1} style={{"marginTop":"-24px"}}>                
									<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
										Numar/data												
									</Grid>																						
									<Grid item xs={5} sm={5} md={5} lg={5} style={{"fontWeight":"600","textAlign":"center"}}>
										Categorie / Tip document / Provenienta
									</Grid>																						
									<Grid item xs={1} sm={1} md={1} lg={1} style={{"fontWeight":"600","textAlign":"center","paddingLeft":"0px"}}>
										Trimis catre												
									</Grid>																						
									<Grid item xs={2} sm={2} md={2} lg={2} style={{"fontWeight":"600","textAlign":"center"}}>
										Termen de rezolvare
									</Grid>																						
									<Grid item xs={3} sm={3} md={3} lg={3} style={{"fontWeight":"600","textAlign":"center"}}>
										Creat de catre / la data
									</Grid>																						
								</Grid>										
							</Card>
						</Grid>
						{filteredDocumentsList.map((document) =>
							<Grid item xs={12} sm={12} md={12} lg={12} key={document.id} name="dashboard_documents_details" id="dashboard_documents_details">
								{document.doc_due_date_compare>compareDate &&
									<Card style={{"backgroundColor":"#F8F9FA"}}>
										<Grid container spacing={1} style={{"marginTop":"-24px"}}>                
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center"}}>
												{document.doc_no}/{document.doc_date}												
											</Grid>																						
											<Grid item xs={5} sm={5} md={5} lg={5} style={{"textAlign":"center"}}>
												{document.doc_category} / {document.doc_type} / {document.doc_from}
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center"}}>
												<Button type="button" tooltip={document.doc_recipients_tooltip} label="?" icon="pi pi-align-justify" style={{"marginTop":"-9px","marginBottom":"-3px"}} className="p-button-sm p-button-primary tooltip_button" />
											</Grid>																						
											<Grid item xs={2} sm={2} md={2} lg={2} style={{"textAlign":"center"}}>
												{document.doc_due_date}
											</Grid>																						
											<Grid item xs={3} sm={3} md={3} lg={3} style={{"textAlign":"center"}}>
												{document.doc_created_by} / {document.doc_created_at}
											</Grid>																						
										</Grid>										
									</Card>
								}
								{document.doc_due_date_compare<=compareDate &&
									<Card style={{"backgroundColor":"#FF0000","color":"#FFFFFF"}}>
										<Grid container spacing={1} style={{"marginTop":"-24px"}}>                
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center"}}>
												{document.doc_no}/{document.doc_date}												
											</Grid>																						
											<Grid item xs={5} sm={5} md={5} lg={5} style={{"textAlign":"center"}}>
												{document.doc_category} / {document.doc_type}												
											</Grid>																						
											<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"center"}}>
												<Button type="button" tooltip={document.doc_recipients_tooltip} label="?" icon="pi pi-align-justify" style={{"marginTop":"-9px","marginBottom":"-3px"}} className="p-button-sm p-button-primary tooltip_button" />
											</Grid>																						
											<Grid item xs={2} sm={2} md={2} lg={2} style={{"textAlign":"center"}}>
												{document.doc_due_date}
											</Grid>																						
											<Grid item xs={3} sm={3} md={3} lg={3} style={{"textAlign":"center"}}>
												{document.doc_created_by} / {document.doc_created_at}
											</Grid>																						
										</Grid>										
									</Card>
								}
							</Grid>
						)}
					</Grid>
					<Paginator first={documentsFirst} rows={rows} totalRecords={filteredDocumentsListLength} onPageChange={onPageChange}></Paginator>
				</React.Fragment>
			}
			{!!(dataOk && filteredDocumentsList.length===0) &&
				<React.Fragment>
					<div style={{"height":"163px"}}>
						<p style={{"width":"100%","marginTop":"87px","fontWeight":"600","color":"#FF0000","textAlign":"center"}}>Nu exista documente conform cautarii.</p>
					</div>
				</React.Fragment>
			}
			<Tooltip target=".tooltip_button" onBeforeShow={(evt)=>console.log("tooltip open")} change={filteredDocumentsList.length}></Tooltip>
			{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
		</BlockUI>
	);
};

export default MainDocumentsRegister;



