import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import {confirmDialog} from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Card } from 'primereact/card';
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import DocumentDetailsRelated from "./DocumentDetailsRelated.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let expireList=[
	{label:"DA",value:"DA"},
	{label:"NU",value:"NU"},
];
let statusList=[
	{label:"IN LUCRU",value:"IN LUCRU"},
	{label:"REZOLVAT",value:"REZOLVAT"},
];
let expirePeriodList=[
	{label:"1 LUNA",value:1},
	{label:"2 LUNI",value:2},
	{label:"3 LUNI",value:3},
	{label:"6 LUNI",value:6},
	{label:"9 LUNI",value:9},
	{label:"12 LUNI",value:12},	
	{label:"18 LUNI",value:18},
	{label:"24 LUNI",value:24},
	{label:"36 LUNI",value:36},
	{label:"60 LUNI",value:60},
];

let docCategoryList=[
	{name: "Intrare"},
	{name:"Intern"},
	{name:"Iesire din oficiu"},
	{name:"Iesire relationata"},
];

let defaultInputValues={
	doc_status: "",
	doc_solved_resolution: "",
	doc_ranked_resolution: "",
	doc_expire: 0,
	doc_expire_period: 0,
	doc_category: "",
	doc_type: "",
	doc_due_date: "",
	doc_from: "",
};

let defaultDataValues={
	documentDetails: [],
	recipients:"",
	relatedDocument: {},
	docTypesList: [],
};

let defaultDialogValues={
	openDocumentDetailsRelated: false,
	titleDocumentDetailsRelated: "Detalii document relationat",
	openRankedSettings: false,
	titleRankedSettings: "Clasare document",
};


const DocumentDetailsDetails = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [blockScreen, setBlockScreen] = useState(false);
	const [dataOk, setDataOk] = useState(false);
	const [canModifyDoc, setCanModifyDoc] = useState(false);
	const [recipientsNo, setRecipientsNo] = useState(0);
	const [currentDocument, setCurrentDocument] = useState(null);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="save_status") {
			if((inputValues.doc_status==="CLASAT")&&(inputValues.doc_ranked_resolution==="")) {
				setMessageText("Eroare: Completati rezolutia de clasare!");
				return false;
			}
			if((inputValues.doc_expire=="DA")&&(inputValues.doc_expire_period=="0")) {
				setMessageText("Eroare: Selectati perioada de expirare!");
				return false;
			}
			oper="save_status";
			let url="setDocumentStatus";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				status: inputValues.doc_status,
				solved_resolution: inputValues.doc_solved_resolution,
				ranked_resolution: inputValues.doc_ranked_resolution,
				doc_expire: inputValues.doc_expire,
				doc_expire_period: inputValues.doc_expire_period,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="open_related") {
			if((inputValues.doc_status==="CLASAT")&&(inputValues.doc_ranked_resolution==="")) {
				setMessageText("Eroare: Completati rezolutia de clasare!");
				return false;
			}
			if((inputValues.doc_expire=="DA")&&(inputValues.doc_expire_period=="0")) {
				setMessageText("Eroare: Selectati perioada de expirare!");
				return false;
			}
			oper="open_related";
			let url="setDocumentStatus";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				status: inputValues.doc_status,
				solved_resolution: inputValues.doc_solved_resolution,
				ranked_resolution: inputValues.doc_ranked_resolution,
				doc_expire: inputValues.doc_expire,
				doc_expire_period: inputValues.doc_expire_period,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="related_details") {
			let no=elementData.split("/")[0].replace(/ /g,"");
			let year=elementData.split("/")[1].replace(/ /g,"");
			if((no.length>0)&&(year.length>3)) {
				oper="init_related_document";
				let url="getDashboardDocumentByDocNo";
				url+="?session_id="+props.session.sessId;
				let data={
					no: no,
					year: year,
				};
				fetch_url_post(url,data,success_handler,fail_handler);
			}else{
				setMessageText("Eroare: Completati corect detaliile documentului relationat!");
				return false;				
			}
		}
		if(what==="change_document_type") {
			if(!inputValues.doc_type) {
				setMessageText("Eroare: Alegeti tipul de document din lista!");
				return false;
			}
			oper="change_document_type";
			let url="changeDocumentDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				field: "doc_type",
				value: inputValues.doc_type,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="change_document_category") {
			if(!inputValues.doc_category) {
				setMessageText("Eroare: Alegeti categoria de document din lista!");
				return false;
			}
			oper="change_document_category";
			let url="changeDocumentDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				field: "doc_category",
				value: inputValues.doc_category,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="change_document_due_date") {
			if(!inputValues.doc_due_date) {
				setMessageText("Eroare: Completati termenul de rezolvare!");
				return false;
			}
			let tmp=inputValues.doc_due_date.split("-");
			let date_ok=1;
			if(tmp[2]<2022) {
				date_ok=0;
			}
			if((tmp[1]<1)||(tmp[1]>12)) {
				date_ok=0;
			}
			if((tmp[0]<0)||(tmp[0]>31)) {
				date_ok=0;
			}
			if(tmp[1]==="02") {
				if(tmp[0]>29) {
					date_ok=0;
				}
			}
			if((tmp[1]==="04")||(tmp[1]==="06")||(tmp[1]==="09")||(tmp[1]==="11")) {
				if(tmp[0]>30) {
					date_ok=0;
				}
			}
			if(date_ok===0) {
				setMessageText("Eroare: Data este incorecta la termenul de rezolvare!");
				return false;				
			}
			oper="change_document_due_date";
			let url="changeDocumentDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				field: "doc_due_date",
				value: tmp[2]+"-"+tmp[1]+"-"+tmp[0],
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="change_document_from") {
			if(!inputValues.doc_from) {
				setMessageText("Eroare: Introduceti provenienta documentului!");
				return false;
			}
			oper="change_document_from";
			let url="changeDocumentDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				field: "doc_from",
				value: inputValues.doc_from,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="change_document_description") {
			if(!inputValues.doc_description) {
				setMessageText("Eroare: Copmpletati descrierea documentului!");
				return false;
			}
			oper="change_document_description";
			let url="changeDocumentDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				field: "doc_description",
				value: inputValues.doc_description,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="init_document")||(oper==="save_status")) {
			setDataValues({...dataValues,
							documentDetails:(result.data.documentDetails?result.data.documentDetails:[]),
							recipients:(result.data.recipients?result.data.recipients:[]),
							docTypesList:(result.data.docTypesList?result.data.docTypesList:[])
			});
			setInputValues({...inputValues,...result.data.documentDetails});
			if(result.data.recipients) {
				setRecipientsNo(result.data.recipients.split(",").length);
			}
			//see if we can modify
			if(props.session.can_modify===1) {
				setCanModifyDoc(true);
			}else{
				setCanModifyDoc(false);
			}
			if(!dataOk) {
				setDataOk(true);
			}
			if(oper==="save_status") {
				props.changeHandler();				
				props.closeHandler("Starea documentului a fost modificata cu succes!");
			}
			oper="";
		}
		if(oper==="init_related_document") {
			setDataValues({...dataValues,relatedDocument:result.data.documentsList});
			setDialogValues({...dialogValues,openDocumentDetailsRelated: true,titleDocumentDetailsRelated: "Detalii document relationat"});
            setDataOk(true);
			oper="";
		}
		if((oper==="change_document_type")||(oper==="change_document_category")||(oper==="change_document_due_date")||(oper==="change_document_from")) {
			props.changeHandler();
			oper="";
		}
		//if we need related exit then move directly there
		if(oper==="open_related") {
			history.push("/newDocument/exitRelated/"+currentDocument.doc_no+"/"+currentDocument.doc_date.split("-")[2]);			
			return false;
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_document";
			let url="getDocumentDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
		//here we also add rights to rank and to delete document
		statusList=[
			{label:"IN LUCRU",value:"IN LUCRU"},
			{label:"REZOLVAT",value:"REZOLVAT"},
		];
		if(props.session.can_rank===1) {
			let tmp={label:"CLASAT",value:"CLASAT"};
			statusList.push(tmp);
		}
		if(props.session.can_delete===1) {
			let tmp={label:"ANULAT",value:"ANULAT"};
			statusList.push(tmp);
		}
	},[]);

	const documentDetailsRelatedDialog = () => {
		if(dataValues.relatedDocument) {
			return(
				<Dialog header={dialogValues.titleDocumentDetailsRelated} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDocumentDetailsRelated:false})} breakpoints={{"960px":"75vw"}} position="top" resizable={false} style={{"width":"99vw","marginTop":"84px"}} contentStyle={{"minHeight":"636px","paddingBottom":"15px"}}>
					<DocumentDetailsRelated session={props.session} document={dataValues.relatedDocument} changeHandler={props.changeHandler} closeHandler={props.closeHandler}/>
				</Dialog>
			);
		}else{
			return(
				<Dialog header={dialogValues.titleDocumentDetailsRelated} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDocumentDetailsRelated:false})} breakpoints={{"960px":"75vw"}} resizable={false} style={{"width":"30vw"}}>
					<p style={{"textAlign":"center","fontWeight":"603","color":"#FF0000","marginBottom":"30px"}}>Documentul cautat nu exista!<br/><br/><br/>Verificati datele introduse.</p>
				</Dialog>
			);			
		}
	}

	const rankedSettingsDialog = () => {
		return(
			<Dialog header={dialogValues.titleRankedSettings} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openRankedSettings:false})} resizable={false} style={{"width":"48vw"}} contentStyle={{"minHeight":"144px","paddingBottom":"15px"}}>
				<Grid container spacing={1} style={{"marginTop":"18px"}}>
					<Grid item xs={12} sm={12} md={4} lg={4} style={{"textAlign":"center"}}>
						<Card style={{"height":"57px","background":"#2196F3"}}>
							<p className="document_details" style={{"marginTop":"-21px"}} onClick={(evt) => handleButton(evt,"open_related",currentDocument)}>Deschide iesire relationata</p>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} style={{"textAlign":"center"}}>
						<Card style={{"height":"57px","background":"#2196F3"}}>
							<p className="document_details" style={{"marginTop":"-21px"}} onClick={(evt) => handleButton(evt,"save_status",currentDocument)}>Doar claseaza documentul</p>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} style={{"textAlign":"center"}}>
						<Card style={{"height":"57px","background":"#2196F3"}}>
							<p className="document_details" style={{"marginTop":"-21px"}} onClick={(evt) => setDialogValues({...dialogValues,openRankedSettings:false})}>Abandoneaza clasare</p>
						</Card>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const confirm = (evt,what,elementData) => {
		if(what==="save_status") {
			if((inputValues.doc_status==="CLASAT")&&(inputValues.doc_ranked_resolution==="")) {
				setMessageText("Eroare: Completati rezolutia de clasare!");
				return false;
			}
			if((inputValues.doc_expire=="DA")&&(inputValues.doc_expire_period=="0")) {
				setMessageText("Eroare: Selectati perioada de expirare!");
				return false;
			}
			let message="Sigur doriti modificarea?";
			if(inputValues.doc_status==="REZOLVAT") {
				if(recipientsNo===1) {
					message+=" Sunteti singurul participant in flux!";
				}
				if((elementData.doc_related_1!=="")||(elementData.doc_related_2!=="")||(elementData.doc_related_3!=="")) {
					message+=" ATENTIE! Exista documente relationate!"
				}				
				confirmDialog({
					message: message,
					header: "Confirmare modificare stare",
					icon: "pi pi-exclamation-triangle",
					acceptLabel: "Da",
					rejectLabel: "Nu",
					accept: () => handleButton(evt,what,elementData),
					reject: () => null,
				});
			}
			if(inputValues.doc_status==="CLASAT") {
				if((elementData.doc_related_1!=="")||(elementData.doc_related_2!=="")||(elementData.doc_related_3!=="")) {
					message+=" ATENTIE! Exista documente relationate!"
				}				
				if(elementData.doc_category==="Intrare") {
					setCurrentDocument(elementData);
					setDialogValues({...dialogValues,openRankedSettings: true,titleRankedSettings: "Setari clasare document"});
				}else{
					confirmDialog({
						message: message,
						header: "Confirmare modificare stare",
						icon: "pi pi-exclamation-triangle",
						acceptLabel: "Da",
						rejectLabel: "Nu",
						accept: () => handleButton(evt,what,elementData),
						reject: () => null,
					});					
				}
			}
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"marginTop":"18px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center"}}>
						<p style={{"fontSize":"18px","fontWeight":"600","marginTop":"-12px","marginBottom":"24px"}}>Detalii document</p>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={1}>
						<span className="p-float-label">
							<InputText name="doc_no" id="doc_no" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_no} disabled={true}/>
							<label htmlFor="doc_no">Numar</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_date" id="doc_date" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_date} disabled={true}/>
							<label htmlFor="doc_date">Data</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					{canModifyDoc &&
						<React.Fragment>
							<Grid item xs={6} sm={6} md={6} lg={3}>
								<div style={{"width":"81%","float":"left"}}>
									<span className="p-float-label">
										<Dropdown name="doc_category" id="doc_category" value={inputValues.doc_category} style={{"width":"100%","height":"36px"}} options={docCategoryList} onChange={(evt) => handleChange(evt)} optionLabel="name" optionValue="name" showOnFocus disabled={!canModifyDoc} />
										<label htmlFor="doc_type">Categorie</label>
									</span>
								</div>
								<div style={{"width":"17%","float":"right"}}>
									<Button type="button" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"width":"97%","margin":"0 auto","marginTop":"3px"}} onClick={(evt) => handleButton(evt,"change_document_category",dataValues.documentDetails)} disabled={!canModifyDoc}/>
								</div>
								<div style={{"height":"3px"}}></div>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={4}>
								<div style={{"width":"84%","float":"left"}}>
									<span className="p-float-label">
										<Dropdown name="doc_type" id="doc_type" value={inputValues.doc_type} style={{"width":"100%","height":"36px"}} options={dataValues.docTypesList} onChange={(evt) => handleChange(evt)} optionLabel="name" optionValue="id" filter filterBy="name" showOnFocus scrollHeight="300px" disabled={!canModifyDoc} />
										<label htmlFor="doc_type">Tip document</label>
									</span>
								</div>
								<div style={{"width":"14%","float":"right"}}>
									<Button type="button" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"width":"90%","margin":"0 auto","marginTop":"3px"}} onClick={(evt) => handleButton(evt,"change_document_type",dataValues.documentDetails)} disabled={!canModifyDoc}/>
								</div>
								<div style={{"height":"3px"}}></div>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<div style={{"width":"72%","float":"left"}}>
									<span className="p-float-label">
										<InputText name="doc_due_date" id="doc_due_date" style={{"width":"100%","color":"#000000 !important"}} value={inputValues.doc_due_date} onChange={(evt) => handleChange(evt)}/>
										<label htmlFor="doc_due">Termen de rezolvare</label>
									</span>
								</div>
								<div style={{"width":"24%","float":"right"}}>
									<Button type="button" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"width":"97%","margin":"0 auto","marginTop":"3px"}} onClick={(evt) => handleButton(evt,"change_document_due_date",dataValues.documentDetails)} disabled={!canModifyDoc}/>									
								</div>
								<div style={{"height":"3px"}}></div>
							</Grid>
						</React.Fragment>
					}
					{!!(!canModifyDoc) &&
						<React.Fragment>
							<Grid item xs={6} sm={6} md={6} lg={3}>
								<span className="p-float-label">
									<InputText name="doc_category" id="doc_category" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_category} disabled={true}/>
									<label htmlFor="doc_category">Categorie</label>
								</span>
								<div style={{"height":"3px"}}></div>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={4}>
								<span className="p-float-label">
									<InputText name="doc_type_name" id="doc_type_name" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_type_name} disabled={true}/>
									<label htmlFor="doc_type_name">Tip document</label>
								</span>
								<div style={{"height":"3px"}}></div>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<span className="p-float-label">
									<InputText name="doc_due_date" id="doc_due_date" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_due_date} disabled={true}/>
									<label htmlFor="doc_due">Termen de rezolvare</label>
								</span>
								<div style={{"height":"3px"}}></div>
							</Grid>
						</React.Fragment>
					}
					<Grid item xs={6} sm={6} md={6} lg={3}>
						<span className="p-float-label">
							<InputText name="doc_due_perodicity" id="doc_due_periodicity" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_due_periodicity} disabled={true}/>
							<label htmlFor="doc_due_periodicity">Termen de raportare</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					{canModifyDoc &&
						<React.Fragment>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<span className="p-float-label">
									<InputText name="doc_from_no_date" id="doc_from_no_date" style={{"width":"100%","color":"#000000 !important"}} value={inputValues.doc_from_no_date} disabled={true}/>
									<label htmlFor="doc_from_no_date">Nr/data intrare</label>
								</span>
								<div style={{"height":"3px"}}></div>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={5}>
								<div style={{"width":"87.3%","float":"left"}}>
									<span className="p-float-label">
										<InputText name="doc_from" id="doc_from" style={{"width":"100%","color":"#000000 !important"}} value={inputValues.doc_from} onChange={(evt) => handleChange(evt)} disabled={!canModifyDoc}/>
										<label htmlFor="doc_from">Provenienta</label>
									</span>
								</div>
									<div style={{"width":"10.5%","float":"right"}}>
										<Button type="button" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"width":"99%","margin":"0 auto","marginTop":"3px"}} onClick={(evt) => handleButton(evt,"change_document_from",dataValues.documentDetails)} disabled={!canModifyDoc}/>
									</div>
								<div style={{"height":"3px"}}></div>
							</Grid>
						</React.Fragment>
					}
					{!!(!canModifyDoc) &&
						<React.Fragment>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<span className="p-float-label">
									<InputText name="doc_from_no_date" id="doc_from_no_date" style={{"width":"100%","color":"#000000 !important"}} value={inputValues.doc_from_no_date} disabled={true}/>
									<label htmlFor="doc_from_no_date">Nr/data intrare</label>
								</span>
								<div style={{"height":"3px"}}></div>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={5}>
								<span className="p-float-label">
									<InputText name="doc_from" id="doc_from" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_from} disabled={true}/>
									<label htmlFor="doc_from">Provenienta</label>
								</span>
								<div style={{"height":"3px"}}></div>
							</Grid>
						</React.Fragment>
					}
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_created_at" id="doc_created_at" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_created_at} disabled={true}/>
							<label htmlFor="doc_created_at">Introdus la data</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<div style={{"width":"69%","float":"left"}}>
							<span className="p-float-label">
								<InputText name="doc_related_1" id="doc_related_1" style={{"width":"100%","color":"#000000 !important"}} value={(dataValues.documentDetails.doc_related_1?dataValues.documentDetails.doc_related_1:" - ")} disabled={true}/>
								<label htmlFor="doc_related_1">In legatura cu</label>
							</span>
						</div>
						<div style={{"width":"27%","float":"right"}}>
							<Button type="button" icon="pi pi-search" className="p-button-sm p-button-primary" style={{"width":"96%","margin":"0 auto","marginTop":"3px"}} onClick={(evt) => handleButton(evt,"related_details",dataValues.documentDetails.doc_related_1)} disabled={(dataValues.documentDetails.doc_related_1?false:true)}/>
						</div>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<div style={{"width":"69%","float":"left"}}>
							<span className="p-float-label">
								<InputText name="doc_related_2" id="doc_related_2" style={{"width":"100%","color":"#000000 !important"}} value={(dataValues.documentDetails.doc_related_2?dataValues.documentDetails.doc_related_2:" - ")} disabled={true}/>
								<label htmlFor="doc_related_2">In legatura cu</label>
							</span>
						</div>
						<div style={{"width":"27%","float":"right"}}>
							<Button type="button" icon="pi pi-search" className="p-button-sm p-button-primary" style={{"width":"96%","margin":"0 auto","marginTop":"3px"}} onClick={(evt) => handleButton(evt,"related_details",dataValues.documentDetails.doc_related_2)} disabled={(dataValues.documentDetails.doc_related_2?false:true)}/>
						</div>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<div style={{"width":"69%","float":"left"}}>
							<span className="p-float-label">
								<InputText name="doc_related_3" id="doc_related_3" style={{"width":"100%","color":"#000000 !important"}} value={(dataValues.documentDetails.doc_related_3?dataValues.documentDetails.doc_related_3:" - ")} disabled={true}/>
								<label htmlFor="doc_related_3">In legatura cu</label>
							</span>
						</div>
						<div style={{"width":"27%","float":"right"}}>
							<Button type="button" icon="pi pi-search" className="p-button-sm p-button-primary" style={{"width":"96%","margin":"0 auto","marginTop":"3px"}} onClick={(evt) => handleButton(evt,"related_details",dataValues.documentDetails.doc_related_3)} disabled={(dataValues.documentDetails.doc_related_3?false:true)}/>
						</div>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={4}>
						<span className="p-float-label">
							<InputText name="doc_created_by" id="doc_created_by" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.full_doc_created_by} disabled={true}/>
							<label htmlFor="doc_created_by">Introdus de catre</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_receiving_method" id="doc_receiving_method" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_receiving_method} disabled={true}/>
							<label htmlFor="doc_receiving_method">Calea de comunicare</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					{canModifyDoc &&
						<Grid item xs={12} sm={12} md={7} lg={7}>
							<div style={{"width":"92%","float":"left"}}>
							<span className="p-float-label">
								<InputTextarea name="doc_description" id="doc_description" style={{"width":"100%","minHeight":"153px","color":"#000000 !important"}} value={inputValues.doc_description} onChange={(evt) => handleChange(evt)} disabled={!canModifyDoc} rows={5}/>
								<label htmlFor="doc_description">Descriere</label>
							</span>
							</div>
								<div style={{"width":"7.5%","float":"right"}}>
									<Button type="button" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"width":"95%","margin":"0 auto","marginTop":"3px"}} onClick={(evt) => handleButton(evt,"change_document_description",dataValues.documentDetails)} disabled={!canModifyDoc}/>
								</div>
							<div style={{"height":"3px"}}></div>
						</Grid>
					}
					{!!(!canModifyDoc) &&
						<Grid item xs={12} sm={12} md={7} lg={7}>
							<span className="p-float-label">
								<InputTextarea name="doc_description" id="doc_description" style={{"width":"100%","minHeight":"153px","color":"#000000 !important"}} value={dataValues.documentDetails.doc_description} disabled={true} rows={5}/>
								<label htmlFor="doc_description">Descriere</label>
							</span>
							<div style={{"height":"3px"}}></div>
						</Grid>
					}
					<Grid item xs={12} sm={12} md={5} lg={5}>
						<span className="p-float-label">
							<InputTextarea name="doc_observations" id="doc_observations" style={{"width":"100%","minHeight":"153px","color":"#000000 !important"}} value={dataValues.documentDetails.doc_observations} disabled={true} rows={5}/>
							<label htmlFor="doc_observations">Observatii</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					{props.type!=="received" &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center"}}>
								<p style={{"fontSize":"18px","fontWeight":"600","marginTop":"-12px","marginBottom":"-3px"}}>Stare document</p>
								<div style={{"height":"3px"}}></div>
							</Grid>
							{dataValues.documentDetails.doc_status==="IN LUCRU" &&
								<Grid item xs={12} sm={2} md={2} lg={2} style={{"textAlign":"center","minHeight":"123px"}}>
									<span className="p-float-label">
										<Dropdown name="doc_status" id="doc_status" style={{"width":"100%","height":"36px"}} value={inputValues.doc_status} options={statusList} onChange={(evt) => handleChange(evt)} optionLabel="label"/>
										<label htmlFor="doc_status">Stare document</label>
									</span>
									<div style={{"height":"18px","clear":"all"}}></div>
									<Button type="button" label="Modifica stare doc." icon="pi pi-save" className="p-button-sm p-button-primary" style={{"width":"100%","marginTop":"-3px"}} onClick={(evt) => confirm(evt,"save_status",dataValues.documentDetails)} />
								</Grid>
							}
							{dataValues.documentDetails.doc_status!=="IN LUCRU" &&
								<Grid item xs={12} sm={2} md={2} lg={2} style={{"textAlign":"center","minHeight":"123px"}}>
									{!!canModifyDoc &&
										<React.Fragment>
											<span className="p-float-label">
												<Dropdown name="doc_status" id="doc_status" style={{"width":"100%","height":"36px"}} value={inputValues.doc_status} options={statusList} onChange={(evt) => handleChange(evt)} optionLabel="label" disabled={!canModifyDoc}/>
												<label htmlFor="doc_status">Stare document</label>
											</span>
											<div style={{"height":"18px","clear":"all"}}></div>
											<Button type="button" label="Modifica stare doc." icon="pi pi-save" className="p-button-sm p-button-primary" style={{"width":"100%","marginTop":"-3px"}} onClick={(evt) => confirm(evt,"save_status",dataValues.documentDetails)} />
										</React.Fragment>
									}
									{!!(!canModifyDoc) &&
										<p style={{"marginTop":"3px","fontWeight":"603"}}>Document {inputValues.doc_status}</p>
									}
								</Grid>
							}
							{inputValues.doc_status==="CLASAT" &&
								<React.Fragment>
									<Grid item xs={12} sm={12} md={8} lg={8}>
										<span className="p-float-label">
											<InputTextarea name="doc_ranked_resolution" id="doc_ranked_resolution" style={{"width":"100%","minHeight":"102px"}} value={inputValues.doc_ranked_resolution} onChange={(evt) => handleChange(evt)} rows={3}/>
											<label htmlFor="doc_ranked_resolution">Rezolutie clasare*</label>
										</span>
										<div style={{"height":"3px"}}></div>
									</Grid>
									<Grid item xs={12} sm={12} md={3} lg={2}>
										<span className="p-float-label">
											<Dropdown name="doc_expire" id="doc_expire" style={{"width":"100%","height":"36px"}} value={inputValues.doc_expire} options={expireList} onChange={(evt) => handleChange(evt)} optionLabel="label"/>
											<label htmlFor="doc_expire">Expira</label>
										</span>
										<div style={{"height":"12px"}}></div>
										{inputValues.doc_expire==="DA" &&
											<span className="p-float-label">
												<Dropdown name="doc_expire_period" id="doc_expire_period" style={{"width":"100%","height":"36px"}} value={inputValues.doc_expire_period} options={expirePeriodList} onChange={(evt) => handleChange(evt)} optionLabel="label"/>
												<label htmlFor="doc_expire_period">Perioada expirare</label>
											</span>
										}
									</Grid>
								</React.Fragment>
							}
						</React.Fragment>
					}
				</Grid>
				{dialogValues.openDocumentDetailsRelated && documentDetailsRelatedDialog()}
				{dialogValues.openRankedSettings && rankedSettingsDialog()}
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				<ConfirmDialog />
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default DocumentDetailsDetails;

