import {get_api_path} from "./paths.js";

export function Crypt_pass(utl,ps) {
	if(utl.length<1) {
		return(false);
	}
	if(ps.length<1) {
		return(false);
	}else{
		var d=new Date();
		var cd=d.toDateString().split(" ")[2];
		var dps=ps+cd;
		var md5 = require('md5');
		var mdp=md5(dps);
		return mdp;
	}
}

export function fetch_post_form(data) {
	let body={};
	body.data=data;
	var post_data={
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body), // body data type must match "Content-Type" header
	}
	console.log(post_data);
	return post_data;
}

export function fetch_url_post(url,data,success_handler,fail_handler,wait_handler) {
	var url_base=get_api_path(); //productie
	url=url_base+url;
	var post_data=fetch_post_form(data);
	fetch(url,post_data).then(res => res.json()).then(
														(result) => {
																		success_handler(result);
																	},
														(error) => {
																		fail_handler();
																	}
	)    
	//we need to signal operation to user if operation takes too long if we have wait_handler
	if(wait_handler) {
		var timerId = setTimeout(()=>{
			wait_handler();
		},600);
		return timerId;
	}else{
		return true;
	}
}

export function fetch_download(url,data,filename,success_handler) {
	var url_base=get_api_path(); //productie
	url=url_base+url;
	var post_data=fetch_post_form(data);
	fetch(url,post_data).then(response => {
		response.blob().then(blob => {
			let url=window.URL.createObjectURL(blob);
			let a=document.createElement("a");
			a.href=url;
			a.download=filename;
			a.click();
			success_handler({ok:1});
		});
	});
}

export function add_to_array(array,new_item,index) {
	if(!array) {
		array=[];
	}
	if(index) {
		array[index]=new_item;
	}else{
		array.push(new_item);
	}
	return array;
}

export function save_form_details(values,required_values,url,session_id,success_handler,fail_handler,message_handler,wait_handler) {
	//handle required fields
	let ok=1;
	for(var c_value in values) {
		let element=document.getElementById(c_value);
		//reset errors
		if(element) {
			element.classList.remove("required_input_error");
		}
		if(required_values.findIndex((req)=>req===c_value)!==-1) {
			//see what type the field is
			if(typeof(values[c_value])!=="object") {
				if((values[c_value]==="")||(!values[c_value])) {
					if(element) {
						element.classList.add("required_input_error");
					}
					message_handler("Completati campurile obligatorii marcate cu (*) !");
					ok=0;
				}
			}else{
				if(values[c_value]) {
					if(values[c_value].length===0) {
						if(element) {
							element.classList.add("required_input_error");
						}
						message_handler("Completati campurile obligatorii marcate cu (*) !");
						ok=0;
					}				
				}else{
					if(element) {
						element.classList.add("required_input_error");
					}
					message_handler("Completati campurile obligatorii marcate cu (*) !");
					ok=0;					
				}
			}
		}
	}
	if(ok) {
		url+="?session_id="+session_id;
		var timerId=fetch_url_post(url,values,success_handler,fail_handler,wait_handler);
		if(wait_handler) {
			return timerId;
		}else{
			return true;
		}
	}else{
		return false;
	}
}

export function is_in_array(element,array,return_true_if_no_array,element_field,array_field) {
	if((!array)||(array.length===0)) {
		if(return_true_if_no_array) {
			return true;
		}else{
			return false;
		}
	}
	if(!array_field) {
		array_field="id";
	}
	if(!element_field) {
		element_field="id";
	}	
	if(typeof(element)==="object") {
		element=element[element_field];
	}
	let found=false;
	array.forEach(c_element => {
		let search_element=(typeof(c_element==="object")?c_element[array_field]:c_element);
		if(search_element==element) {
			found=true;
		}
	});
	return found;
}

export function get_due_date(days) {
	//if we do not have a limit, calculate 30 days to set doc_due_date
	let doc_due_date = new Date();
	if(!days) {
		days=30;
	}
	doc_due_date.setDate(doc_due_date.getDate()+days);
	//verify for legal holidays
	let offset=0;
	let due_day=doc_due_date.getDate();
	let due_month=doc_due_date.getMonth()+1;
	if((due_day===1)&&(due_month===1)) {
		offset=4;
	}
	if((due_day===1)&&(due_month===5)) {
		offset=1;
	}
	if((due_day===15)&&(due_month===8)) {
		offset=1;
	}
	if((due_day===25)&&(due_month===12)) {
		offset=2;
	}
	doc_due_date.setDate(doc_due_date.getDate()+offset);
	//correct weekends
	if(doc_due_date.getDay()===6) {
		doc_due_date.setDate(doc_due_date.getDate()+2);	
	}
	if(doc_due_date.getDay()===0) {
		doc_due_date.setDate(doc_due_date.getDate()+1);	
	}
	//verify again for legal holidays
	offset=0;
	due_day=doc_due_date.getDate();
	due_month=doc_due_date.getMonth()+1;
	if((due_day===1)&&(due_month===1)) {
		offset=4;
	}
	if((due_day===1)&&(due_month===5)) {
		offset=1;
	}
	if((due_day===15)&&(due_month===8)) {
		offset=1;
	}
	if((due_day===25)&&(due_month===12)) {
		offset=2;
	}
	return doc_due_date;
}

export function format_date(date,format) {
	const map = {
        mm: (date.getMonth()+1>9?String(date.getMonth()+1):"0"+String(date.getMonth()+1)),
        dd: (date.getDate()>9?date.getDate():"0"+date.getDate()),
        yyyy: date.getFullYear()
    }
    return format.replace(/mm|dd|yyyy/gi, matched => map[matched])
}



















