import React from "react";
import {Button} from 'primereact/button';

export default function MenuIcons(props) {

	if(props.section==="Utilizatori") {
		return(
			<Button label={props.section} icon="pi pi-user" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Departamente") {
		return(
			<Button label={props.section} icon="pi pi-building" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Registre") {
		return(
			<Button label={props.section} icon="pi pi-book" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Nomenclator lucrari") {
		return(
			<Button label={props.section} icon="pi pi-list" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Registru intrari/iesiri") {
		return(
			<Button label={props.section} icon="pi pi-align-center" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Setari unitate") {
		return(
			<Button label={props.section} icon="pi pi-th-large" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Institutii subordonate") {
		return(
			<Button label={props.section} icon="pi pi-sitemap" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	if(props.section==="Istoric documente") {
		return(
			<Button label={props.section} icon="pi pi-calendar" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
		);
	}
	return(
		<Button label={props.section} icon="pi pi-user" className="p-button-rounded p-button-text p-button-lg p-button-danger p-button-sm" style={{"width":"96%"}}/>
	);
}

