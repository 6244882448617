import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { Card } from "primereact/card";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {confirmDialog} from 'primereact/confirmdialog';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post,is_in_array} from "../../Sources/js/app.js";

let oper="";
let defaultInputValues={
	id: "",
	user_id: "",
	tasks: "",
	days: "",
};

let defaultDataValues={
	fluxUsersList: [],
	usersList: [],
	usersList_filtered: [],
};

let defaultDialogValues={
	openAddEditUser: false,
	titleAddEditUser: "",
	openTasksUser: false,
	titleTasksUser: "",
};

const NFluxDetails = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [isEdit, setIsEdit] = useState(false);

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="days") {
			if(evt.target.validity.valid) {
				setInputValues({...inputValues,[name]:value});
			}
		}else{
			setInputValues({...inputValues,[name]:value});			
		}
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="save_user") {
			if(!inputValues.user_id) {
				setMessageText("Eroare: Selectati un utilizator din lista!");
				return false;
			}
			if(!inputValues.days) {
				setMessageText("Eroare: Completati numarul de zile alocate!");
				return false;
			}
			let url="addUserToFlux";
			url+="?session_id="+props.session.sessId;
			oper="save_user";
			let data={
				id: inputValues.id,
				flux_id: props.flux.id,
				user_id: inputValues.user_id,
				days: inputValues.days,
				tasks: inputValues.tasks,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete_user") {
			let url="deleteUserFromFlux";
			url+="?session_id="+props.session.sessId;
			oper="delete_user";
			let data={
				flux_id: props.flux.id,
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="add_user") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEditUser: true,titleAddEditUser: "Adaugare utiliztor in flux"});
		}
		if(what==="edit_user") {
			setIsEdit(true);
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEditUser: true,titleAddEditUser: "Editare utilizator"});
		}
		if(what==="user_tasks") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openTasksUser: true,titleTasksUser: "Sarcini utilizator"});
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save_user")||(oper==="delete_user")) {
			setDataValues({...dataValues,
							fluxUsersList:(result.data.fluxUsersList?result.data.fluxUsersList:[]),
							usersList:(result.data.usersList?result.data.usersList:[]),
							usersList_filtered:(result.data.usersList?result.data.usersList.filter((user)=>!is_in_array(user,result.data.fluxUsersList,0,"id","user_id")):[])
			});
            if(!dataOk) {
                setDataOk(true);
            }
			setInputValues(defaultInputValues);
			setDialogValues(defaultDialogValues);
			setIsEdit(false);
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getFluxDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				flux_id: props.flux.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);


	const confirmFlux = (evt,what,elementData) => {
		if((what==="delete_user")||(what==="delete_file")) {
			confirmDialog({
				message: "Sigur doriti stergerea? Operatia este ireversibila!",
				header: "Confirmare stergere",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Da",
				rejectLabel: "Nu",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	const addEditUserDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEditUser} visible={true} onHide={(evt)=>{setDialogValues({...dialogValues,openAddEditUser:false});setIsEdit(false)}} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={10} sm={10} md={10} lg={10}>
						{!isEdit &&
							<span className="p-float-label">
								<Dropdown name="user_id" id="user_id" style={{"width":"100%","height":"36px"}} value={inputValues.user_id} options={dataValues.usersList_filtered} onChange={(evt) => handleChange(evt)} optionLabel="full_user_name" optionValue="id"/>
								<label htmlFor="user_id">Utilizator*</label>
							</span>
						}
						{isEdit &&
							<span className="p-float-label">
								<Dropdown name="user_id" id="user_id" style={{"width":"100%","height":"36px"}} value={inputValues.user_id} options={dataValues.usersList} onChange={(evt) => handleChange(evt)} optionLabel="full_user_name" optionValue="id" disabled={true}/>
								<label htmlFor="user_id">Utilizator*</label>
							</span>
						}
						<div style={{"height":"3px"}}></div>
					</Grid>											
					<Grid item xs={2} sm={2} md={2} lg={2}>
						<span className="p-float-label">
							<InputText name="days" id="days" pattern="[0-9.]*" style={{"width":"100%"}} value={inputValues.days} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="days">Zile*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>											
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputTextarea name="tasks" id="tasks" style={{"width":"100%","minHeight":"48px"}} value={inputValues.tasks} onChange={(evt) => handleChange(evt)} rows={12}/>
							<label htmlFor="tasks">Sarcini</label>
						</span>
					</Grid>											
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right"}}>
						<Button label="Salveaza" icon="pi pi-check" className="p-button-sm" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"save_user")}/>
						<Button label="Abandon" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{"marginRight":"3px"}} onClick={(evt) => {setDialogValues({...dialogValues,openAddEditUser:false});setIsEdit(false)}}/>
					</Grid>											
				</Grid>
			</Dialog>
		);
	}

	const tasksUserDialog = () => {
		return(
			<Dialog header={dialogValues.titleTasksUser} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openTasksUser:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputTextarea name="tasks" id="tasks" style={{"width":"100%","minHeight":"48px"}} value={inputValues.tasks} onChange={(evt) => handleChange(evt)} rows={12}/>
							<label htmlFor="tasks">Sarcini</label>
						</span>
					</Grid>											
				</Grid>
			</Dialog>
		);
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} name="flux_components" id="flux_components" style={{"marginBottom":"18px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center","marginBottom":"18px"}}>
						<Button type="button" label="Adauga utilizator in flux" icon="pi pi-plus-circle" className="p-button-sm p-button-success" style={{}} onClick={(evt) => handleButton(evt,"add_user")}/>									
					</Grid>
					{dataValues.fluxUsersList.length>0 &&
						<React.Fragment>
							{dataValues.fluxUsersList.map((flux_user) =>
								<Grid item xs={6} sm={6} md={6} lg={6} key={flux_user.id}>
									<Card style={{"backgroundColor":"#F8F9FA","padding":"0px"}}>
										<Grid container spacing={1}>
											<Grid item xs={8} sm={8} md={8} lg={8}>
												{flux_user.user_name}
											</Grid>											
											<Grid item xs={4} sm={4} md={4} lg={4} style={{"textAlign":"right"}}>
												{"ALOCAT "+flux_user.days+" zile"}
											</Grid>											
											<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right"}}>
												<Button type="button" label="Editeaza " icon="pi pi-pencil" className="p-button-sm p-button-primary" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit_user",flux_user)}/>									
												<Button type="button" label="Sarcini" icon="pi pi-bars" className="p-button-sm p-button-primary" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"user_tasks",flux_user)}/>									
												<Button type="button" label="Sterge" icon="pi pi-trash" className="p-button-sm p-button-danger" style={{}} onClick={(evt) => confirmFlux(evt,"delete_user",flux_user)}/>									
											</Grid>											
										</Grid>
									</Card>
								</Grid>
							)}
						</React.Fragment>
					}
					{dataValues.fluxUsersList.length===0 &&
						<div style={{"height":"113px","width":"100%"}}>
							<p style={{"width":"100%","marginTop":"84px","fontWeight":"600","color":"#FF0000","textAlign":"center"}}>Nu exista utilizatori definiti.</p>
						</div>
					}
				</Grid>
				{dialogValues.openAddEditUser && addEditUserDialog()}
				{dialogValues.openTasksUser && tasksUserDialog()}
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default NFluxDetails;

