import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Button } from "primereact/button";
import { BlockUI } from 'primereact/blockui';
import { Card } from 'primereact/card';
import {Dialog} from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import {confirmDialog} from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post,is_in_array,get_due_date,format_date} from "../../Sources/js/app.js";

let oper="";
let today = new Date();

let defaultInputValues={
	id: "",
	user_id: "",
	due_date: today,
	tasks: "",
};

let defaultDataValues={
	recipientsList: [],
	usersList: [],
};

let defaultDialogValues={
	openAddEditRecipient: false,
	titleAddEditRecipient: "",
	openDetailsRecipient: false,
	titleDetailsRecipient: "",
};

let max_due_date=new Date();

const DocumentDetailsSendTo = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [blockScreen, setBlockScreen] = useState(false);
	const [dataOk, setDataOk] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [isEdit, setIsEdit] = useState(false);
	const [currentRecipient, setCurrentRecipient] = useState(null);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEditRecipient: true,titleAddEditRecipient: "Trimite catre utilizator"});
		}
		if(what==="edit") {
			let tmp=elementData.due_date.split("-");
			let tmp_date=tmp[2]+"-"+tmp[1]+"-"+tmp[0];
			let data={
				id: elementData.id,
				user_id: elementData.recipient_id,
				due_date: new Date(tmp_date),
				tasks: elementData.tasks,
			}
			setIsEdit(true);
			setInputValues(data);
			setDialogValues({...dialogValues,openAddEditRecipient: true,titleAddEditRecipient: "Modifica destinatar"});
		}
		if(what==="details") {
			setCurrentRecipient(elementData);
			setDialogValues({...dialogValues,openDetailsRecipient: true,titleDetailsRecipient: elementData.recipient});
		}
		if(what==="send_to") {
			document.getElementById("user_id").classList.remove("required_input_error");
			document.getElementById("due_date").classList.remove("required_input_error");
			if(!inputValues.user_id) {
				setMessageText("Eroare: Selectati un utilizator din lista!");				
				document.getElementById("user_id").classList.add("required_input_error");
				return false;
			}
			if(!inputValues.due_date) {
				setMessageText("Eroare: Completati data limita!");				
				document.getElementById("due_date").classList.add("required_input_error");
				return false;
			}
			let tmp_date=new Date(inputValues.due_date);
			let send_to=[];
			let c_send_to={};
			c_send_to.id=inputValues.user_id;
			c_send_to.due_date=format_date(tmp_date,"yyyy-mm-dd");
			c_send_to.tasks=inputValues.tasks;
			send_to.push(c_send_to);
			oper="send_to";
			let url="sendDocumentTo";
			url+="?session_id="+props.session.sessId;
			let data={
				id: inputValues.id,
				doc_id: props.document.id,
				send_to: send_to,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="remove") {
			oper="remove";
			let url="removeRecipient";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				doc_id: props.document.id,
				recipient_id: elementData.recipient_id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="init_document")||(oper==="send_to")||(oper==="remove")) {
			setDataValues({...dataValues,
							usersList:(result.data.usersList?result.data.usersList:[]),
							usersList_filtered:(result.data.usersList?result.data.usersList.filter((user)=>!is_in_array(user,result.data.recipientsList,0,"full_user_name","recipient")):[]),
							recipientsList:(result.data.recipientsList?result.data.recipientsList:[])
			});
			setInputValues(defaultInputValues);
			if(!dataOk) {
                setDataOk(true);
            }
			props.changeHandler();
			oper="";
			setDialogValues(defaultDialogValues);
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_document";
			let url="getDocumentRecipients";
			url+="?session_id="+props.session.sessId;
			let data={
				doc_id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
			let tmp=props.document.doc_due_date.split("-");
			let tmp_date=tmp[2]+"-"+tmp[1]+"-"+tmp[0];
			max_due_date=new Date(tmp_date);
		}else{
			history.push("/login")
		}
	},[]);

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_document";
			let url="getDocumentRecipients";
			url+="?session_id="+props.session.sessId;
			let data={
				doc_id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
			let tmp=props.document.doc_due_date.split("-");
			let tmp_date=tmp[2]+"-"+tmp[1]+"-"+tmp[0];
			max_due_date=new Date(tmp_date);
		}else{
			history.push("/login")
		}
	},[props.changed]);

	const confirm = (evt,what,elementData) => {
		if(what==="remove") {
			confirmDialog({
				message: "Sigur doriti stergerea? Operatia este ireversibila!",
				header: "Confirmare stergere",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Da",
				rejectLabel: "Nu",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	const addEditRecipientDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEditRecipient} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEditRecipient:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={12} sm={6} md={9} lg={9}>
						{!isEdit &&
							<span className="p-float-label">
								<Dropdown name="user_id" id="user_id" style={{"width":"100%","height":"36px"}} value={inputValues.user_id} options={dataValues.usersList_filtered} onChange={(evt) => handleChange(evt)} optionLabel="full_user_name" optionValue="id"/>
								<label htmlFor="user_id">Utilizator*</label>
							</span>
						}
						{isEdit &&
							<span className="p-float-label">
								<Dropdown name="user_id" id="user_id" style={{"width":"100%","height":"36px"}} value={inputValues.user_id} options={dataValues.usersList} onChange={(evt) => handleChange(evt)} optionLabel="full_user_name" optionValue="id" disabled={true}/>
								<label htmlFor="user_id">Utilizator*</label>
							</span>
						}
						<div style={{"height":"3px"}}></div>
					</Grid>											
					<Grid item xs={12} sm={6} md={3} lg={3}>
						<span className="p-float-label">
							<Calendar name="due_date" id="due_date" dateFormat="dd/mm/yy" style={{"width":"100%"}} value={inputValues.due_date} onChange={(evt) => handleChange(evt)} showIcon readOnlyInput maxDate={max_due_date}/>
							<label htmlFor="due_date">Data limita*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>											
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputTextarea name="tasks" id="tasks" style={{"width":"100%","minHeight":"48px"}} value={inputValues.tasks} onChange={(evt) => handleChange(evt)} rows={12}/>
							<label htmlFor="tasks">Sarcini</label>
						</span>
					</Grid>											
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right"}}>
						<Button type="button" label="Salveaza utilizator" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"marginTop":"0px","marginRight":"3px"}} onClick={(evt) => handleButton(evt,"send_to")}/>									
						<Button type="button" label="Inchide fereastra" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{"marginTop":"0px"}} onClick={(evt) => setDialogValues({...dialogValues,openAddEditRecipient:false})}/>									
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const detailsRecipientDialog = () => {
		return(
			<Dialog header={dialogValues.titleDetailsRecipient} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDetailsRecipient:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={12} sm={12} md={8} lg={8}>
						<p>Trimis de: <b>{currentRecipient.sended_by}</b></p>
					</Grid>											
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<p>La data: <b>{currentRecipient.created_at}</b></p>
					</Grid>											
					<Grid item xs={12} sm={12} md={8} lg={8}>
						<p>Acceptat: <b>{currentRecipient.accepted_at}</b></p>
					</Grid>											
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<p>Data limita: <b>{currentRecipient.due_date}</b></p>
					</Grid>											
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"marginTop":"12px"}}>
						<span className="p-float-label">
							<InputTextarea name="tasks" id="tasks" style={{"width":"100%","minHeight":"48px"}} value={currentRecipient.tasks} rows={12}/>
							<label htmlFor="tasks">Sarcini</label>
						</span>
					</Grid>											
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right"}}>
						<Button type="button" label="Inchide fereastra" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{"marginTop":"0px"}} onClick={(evt) => setDialogValues({...dialogValues,openDetailsRecipient:false})}/>									
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"marginTop":"-12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center"}}>
						<p style={{"fontSize":"18px","fontWeight":"600","marginTop":"12px"}}>Destinatari document</p>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"marginTop":"3px","marginBottom":"3px","textAlign":"right"}}>
						<Button type="button" label="Adauga un nou destinatar" icon="pi pi-plus-circle" className="p-button-sm p-button-primary" style={{"marginTop":"-12px"}} onClick={(evt) => handleButton(evt,"add")}/>									
					</Grid>
					{dataValues.recipientsList && dataValues.recipientsList.map((recipient) =>
						<Grid item xs={12} sm={6} md={6} lg={6} key={recipient.id}>
							<Card style={{"backgroundColor":"#F8F9FA","padding":"0px"}}>
								<Grid container spacing={1} alignItems="flex-start">
									<Grid item xs={2} sm={2} md={2} lg={2}>
										Trimis de
									</Grid>											
									<Grid item xs={10} sm={10} md={10} lg={10}>
										{recipient.sended_by}
									</Grid>											
									<Grid item xs={2} sm={2} md={2} lg={2}>
										Catre
									</Grid>											
									<Grid item xs={10} sm={10} md={10} lg={10}>
										{recipient.recipient}
									</Grid>											
									<Grid item xs={2} sm={2} md={2} lg={2}>
										{recipient.due_date_parsed}
									</Grid>											
									<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right"}}>
										<Button type="button" label="Detalii" icon="pi pi-list" className="p-button-sm p-button-secondary" style={{"marginTop":"-6px","marginRight":"3px"}} onClick={(evt) => handleButton(evt,"details",recipient)}/>									
										{!!((props.session.role==="admin")||
										((props.session.role==="dept_admin")&&(props.session.department_id===recipient.department_id))||
										((props.session.role==="unit_admin")&&(props.session.unit_id===recipient.unit_id)))&&
											<React.Fragment>
												<Button type="button" label="Editeaza" icon="pi pi-pencil" className="p-button-sm p-button-primary" style={{"marginTop":"-6px","marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",recipient)}/>									
												<Button type="button" label="Sterge" icon="pi pi-trash" className="p-button-sm p-button-danger" style={{"marginTop":"-6px"}} onClick={(evt) => confirm(evt,"remove",recipient)}/>									
											</React.Fragment>
										}
									</Grid>											
								</Grid>
							</Card>
						</Grid>
					)}
				</Grid>
				<ConfirmDialog />
				{dialogValues.openAddEditRecipient && addEditRecipientDialog()}
				{dialogValues.openDetailsRecipient && detailsRecipientDialog()}
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default DocumentDetailsSendTo;


