import React from "react";
import Grid from "@mui/material/Grid";
import { Button } from "primereact/button";
import {Box} from "@mui/system";
import history from "../../Sources/js/history.js";
import DashboardPerCategories from "./DashboardPerCategories.js";
import DashboardSearch from "./DashboardSearch.js";
import DashboardSearchExpired from "./DashboardSearchExpired.js";

const Dashboard = (props) => {

	const handleButton = (evt,what) => {
		history.push("/dashboard/"+what);
	}

	if((!props.subsection)||(props.subsection==="categories")) {
		return(
			<React.Fragment>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={6} sm={6} md={6} lg={6} style={{"textAlign":"left","marginBottom":"12px"}}>
							<Button type="button" label="Categorii doc." icon="pi pi-list" className="p-button-sm p-button-secondary" style={{}} />
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={6} style={{"textAlign":"right","marginBottom":"12px"}}>
							<Button type="button" label="Cautare doc." icon="pi pi-search" className="p-button-sm p-button-primary" style={{}} onClick={(evt) => handleButton(evt,"search")} />
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center","marginBottom":"12px"}}>
							<Button type="button" label="Documente pe categorii" icon="pi pi-list" className="p-button-sm p-button-secondary" style={{"marginRight":"12px"}} />
							<Button type="button" label="Cautare documente" icon="pi pi-search" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"search")} />
						</Grid>
					</Grid>
				</Box>
				<DashboardPerCategories session={props.session}/>
			</React.Fragment>
		);
	}
	if(props.subsection==="search") {
		return(
			<React.Fragment>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={6} sm={6} md={6} lg={6} style={{"textAlign":"left","marginBottom":"12px"}}>
							<Button type="button" label="Categorii doc." icon="pi pi-list" className="p-button-sm p-button-primary" style={{}} onClick={(evt) => handleButton(evt,"categories")} />
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={6} style={{"textAlign":"right","marginBottom":"12px"}}>
							<Button type="button" label="Cautare doc." icon="pi pi-search" className="p-button-sm p-button-secondary" style={{}} />
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center","marginBottom":"12px"}}>
							<Button type="button" label="Documente pe categorii" icon="pi pi-list" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"categories")} />
							<Button type="button" label="Cautare documente" icon="pi pi-search" className="p-button-sm p-button-secondary" style={{"marginRight":"12px"}} />
						</Grid>
					</Grid>
				</Box>
				<DashboardSearch session={props.session}/>
			</React.Fragment>
		);
	}
	if(props.subsection==="expire") {
		return(
			<React.Fragment>
				<Grid container spacing={1} style={{}}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center","marginBottom":"12px"}}>
						<Button type="button" label="Documente pe categorii" icon="pi pi-list" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"categories")} />
						<Button type="button" label="Cautare documente" icon="pi pi-search" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"search")} />
					</Grid>
				</Grid>
				<DashboardSearchExpired session={props.session} type="expire_7d"/>
			</React.Fragment>
		);
	}
	if(props.subsection==="expired") {
		return(
			<React.Fragment>
				<Grid container spacing={1} style={{}}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center","marginBottom":"12px"}}>
						<Button type="button" label="Documente pe categorii" icon="pi pi-list" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"categories")} />
						<Button type="button" label="Cautare documente" icon="pi pi-search" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"search")} />
					</Grid>
				</Grid>
				<DashboardSearchExpired session={props.session} type="expired"/>
			</React.Fragment>
		);
	}
};

export default Dashboard;
