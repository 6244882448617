import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Fieldset } from "primereact/fieldset";
import { Dialog } from "primereact/dialog";
import { BlockUI } from 'primereact/blockui';
import { Button } from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import history from "../../Sources/js/history.js";
import Message from "../Helpers/Message.js";
import AddFileToDocument from "./AddFileToDocument.js";
import DocumentDetailsRelated from "./DocumentDetailsRelated.js";
import DocumentRecipients from "./DocumentRecipients.js";
import {fetch_url_post,save_form_details,get_due_date} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let defaultInputValues={
	id: null,
	doc_no: null,
	doc_type: "",
	doc_type_field: "",
	doc_from: "",
	doc_to: "",
	doc_description: "",
	doc_due_date: get_due_date(),
	doc_urgent: 0,
	doc_observations: "",
	doc_related_no_1: "",
	doc_related_no_2: "",
	doc_related_no_3: "",
	doc_related_year_1: "",
	doc_related_year_2: "",
	doc_related_year_3: "",
	doc_date_short: day+"/"+month+"/"+year,
	doc_category: "Iesire din oficiu", //1 intrare 2 intern 3 iesire din oficiu 4 iesire relationata
};

let inputValuesRequired=["doc_type","doc_from","doc_to","doc_description"];

let defaultDataValues={
	recipientsList: [],
	docTypesList: [],
};

let defaultDialogValues={
	openAddSuccess: false,
	titleAddSuccess: "",
	openAddFile: false,
	titleAddFile: "",
	openDocumentDetailsRelated: false,
	titleDocumentDetailsRelated: "Detalii document relationat",
	openRecipients: false,
	titleRecipients: "",	
};

const DocumentExitFromOffice = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [blockScreen, setBlockScreen] = useState(false);

	const handleChange = evt => {
		const {name, value} = evt.target;
		let setInput=1;
		let doc_due_date=inputValues.doc_due_date;
		let doc_due_periodicity=inputValues.doc_due_periodicity;
		if(name==="doc_due") {
			if(value==="Pana la data") {
				doc_due_periodicity="";
			}
			if(value==="Raport periodic") {
				doc_due_date=null;
			}			
			if((value==="Azi")||(value==="Urgent")) {
				doc_due_date=year+"-"+month+"-"+day;
				doc_due_periodicity="";
			}
			if((value==="Nespecificat")||(value==="Clasare imediata")) {
				doc_due_date=null;
				doc_due_periodicity="";
			}
			setInputValues({...inputValues,[name]:value,doc_due_periodicity:doc_due_periodicity,doc_due_date:doc_due_date});
			setInput=0;
		}
		if(name==="doc_type") {
			setInputValues({...inputValues,[name]:value.id,doc_type_field:value});			
			setInput=0;
		}
		if(name==="doc_due_date") {
			let day=value.getUTCDate()+1;
			let month=value.getUTCMonth()+1;
			let year=value.getUTCFullYear();
			if(month<10) {
				month="0"+month;
			}
			let date=year+"-"+month+"-"+day;
			setInputValues({...inputValues,doc_due_date:date});
			setInput=0;
		}
		if(setInput) {
			setInputValues({...inputValues,[name]:value});
		}
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add_file_to_document") {
			setDialogValues({...dialogValues,openAddFile: true,titleAddFile: "Adauga fisier sau formular",openAddSuccess:false,titleAddSuccess:""});
		}
		if(what==="related_details") {
			let no="";
			let year="";
			if(elementData===1) {
				no=inputValues.doc_related_no_1.replace(/ /g,"");
				year=inputValues.doc_related_year_1.replace(/ /g,"");
			}
			if(elementData===2) {
				no=inputValues.doc_related_no_2.replace(/ /g,"");
				year=inputValues.doc_related_year_2.replace(/ /g,"");
			}
			if(elementData===3) {
				no=inputValues.doc_related_no_3.replace(/ /g,"");
				year=inputValues.doc_related_year_3.replace(/ /g,"");
			}
			if((no.length>0)&&(year.length>3)) {
				oper="init_related_document";
				let url="getDashboardDocumentByDocNo";
				url+="?session_id="+props.session.sessId;
				let data={
					no: no,
					year: year,
				};
				fetch_url_post(url,data,success_handler,fail_handler);
			}else{
				setMessageText("Eroare: Completati corect detaliile documentului relationat!");
				return false;				
			}
		}
		if(what==="recipients") {
			document.getElementById("doc_type").classList.remove("required_input_error");
			if(!inputValues.doc_type_field) {
				document.getElementById("doc_type").classList.add("required_input_error");
				setMessageText("Eroare: Selectati tipul de document!");				
				return false;
			}
			setDialogValues({...dialogValues,openRecipients: true,titleRecipients: "Destinatari document"});
		}
	}

	const handleCheckedSpecial = (evt) => {
		const {name, checked} = evt.target;
		if(checked) {
			setInputValues({...inputValues,[name]:1});
		}else{
			setInputValues({...inputValues,[name]:0});
		}
	}	

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="init_documents") {
			setDataValues({...dataValues,recipientsList:result.data.recipientsList,docTypesList:result.data.docTypesList});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="save") {
			if(result.new_id>0) {
				setDialogValues({...dialogValues,openAddSuccess: true,titleAddSuccess: "Documentul a fost creat cu succes"});
				setInputValues({...inputValues,id:result.new_id,doc_no:result.doc_no});				
			}
			if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="init_related_document") {
			setDataValues({...dataValues,relatedDocument:result.data.documentsList});
			setDialogValues({...dialogValues,openDocumentDetailsRelated: true,titleDocumentDetailsRelated: "Detalii document relationat"});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_documents";
			let url="initDocuments";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	useEffect(() => {
		if(props.save==="Iesire din oficiu") {
			save_details();
		}
	},[props.save]);

	const save_details = () => {
		oper="save";
		timerId=save_form_details(inputValues,inputValuesRequired,"saveDocument",props.session.sessId,success_handler,fail_handler,setMessageText,wait_handler);
		props.handleResetSave();
	}

	const resetForm = () => {
		setInputValues(defaultInputValues);
		setDialogValues({...dialogValues,openAddSuccess:false});
	}

	const addRecipients = (evt,recipients) => {
		let tmp=(inputValues.doc_recipient?[...inputValues.doc_recipient]:[]);
		recipients.forEach(recipient => {
			tmp.push(recipient);
		});
		setInputValues({...inputValues,doc_recipient:[...tmp]});					
	}

	const removeRecipient = (evt,recipient) => {
		let tmp=(inputValues.doc_recipient?[...inputValues.doc_recipient]:[]);
		tmp=tmp.filter((c_recipient)=>(c_recipient.id!=recipient.id));
		setInputValues({...inputValues,doc_recipient:[...tmp]});					
	}

	const documentDetailsRelatedDialog = () => {
		if(dataValues.relatedDocument) {
			return(
				<Dialog header={dialogValues.titleDocumentDetailsRelated} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDocumentDetailsRelated:false})} breakpoints={{"960px":"75vw"}} resizable={false} style={{"width":"99vw","marginTop":"84px"}} contentStyle={{"minHeight":"636px","paddingBottom":"15px"}}>
					<DocumentDetailsRelated session={props.session} document={dataValues.relatedDocument}/>
				</Dialog>
			);
		}else{
			return(
				<Dialog header={dialogValues.titleDocumentDetailsRelated} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDocumentDetailsRelated:false})} breakpoints={{"960px":"75vw"}} resizable={false} style={{"width":"30vw"}}>
					<p style={{"textAlign":"center","fontWeight":"603","color":"#FF0000","marginBottom":"30px"}}>Documentul cautat nu exista!<br/><br/><br/>Verificati datele introduse.</p>
				</Dialog>
			);			
		}
	}

	const addSuccessDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddSuccess} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddSuccess:false})} breakpoints={{"960px":"75vw"}} style={{"width":"75vw"}}>
				<Grid container spacing={1} style={{"marginBottom":"33px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<p style={{"fontSize":"18px","fontWeight":"600","textAlign":"center","color":"#FF0000"}}>Documentul a fost salvat cu numarul: {inputValues.doc_no}</p>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={3}>
						<Paper style={{"height":"150px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => setDialogValues({...dialogValues,openAddSuccess:false})}>
							<p className="new_document_selection" style={{"marginTop":"48px"}}>Ramanem in<br/>editare</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={3}>
						<Paper style={{"height":"150px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => resetForm()}>
							<p className="new_document_selection" style={{"marginTop":"48px"}}>Introducem<br/>alta intrare</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={3}>
						<Paper style={{"height":"150px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => history.push("/")}>
							<p className="new_document_selection" style={{"marginTop":"48px"}}>Iesire la Panou principal</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={3}>
						<Paper style={{"height":"150px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => handleButton(evt,"add_file_to_document",null)}>
							<p className="new_document_selection" style={{"marginTop":"48px"}}>Incarca fisier sau formular</p>
						</Paper>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const addFileDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddFile} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddFile:false})} breakpoints={{"960px":"75vw"}} position="top" resizable={false} style={{"width":"93vw"}} contentStyle={{"minHeight":"636px","paddingBottom":"15px"}}>
				<AddFileToDocument session={props.session} document={inputValues}/>				
			</Dialog>
		);
	}

	const recipientsDialog = () => {
		return(
			<Dialog header={dialogValues.titleRecipients} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openRecipients:false})} breakpoints={{"960px":"75vw"}} resizable={false} style={{"width":"84vw","marginTop":"84px"}} contentStyle={{"minHeight":"753px","paddingBottom":"15px"}}>
				<DocumentRecipients session={props.session} doc_type={inputValues.doc_type_field} max_due_date={inputValues.doc_due_date} saveHandler={addRecipients} removeHandler={removeRecipient} recipients={inputValues.doc_recipient}/>
			</Dialog>
		);
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<div>
					<Grid container spacing={1} style={{"paddingTop":"18px"}}>
						{inputValues.id &&
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<p style={{"fontWeight":"600","textAlign":"center","color":"#FF0000","marginBottom":"36px"}}>Documentul a fost salvat cu numarul {inputValues.doc_no} din data de {inputValues.doc_date_short}</p>
								<div style={{"height":"3px"}}></div>
							</Grid>
						}
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<span className="p-float-label">
								<Dropdown name="doc_type" id="doc_type" value={inputValues.doc_type_field} style={{"width":"100%","height":"36px"}} options={dataValues.docTypesList} onChange={(evt) => handleChange(evt)} optionLabel="name" filterBy="name" showOnFocus scrollHeight="300px" />
								<label htmlFor="doc_type">Tip document*</label>
							</span>
							<div style={{"height":"3px"}}></div>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<span className="p-float-label">
								<InputText name="doc_from" id="doc_from" style={{"width":"100%"}} value={inputValues.doc_from} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="doc_from">De unde provine*</label>
							</span>
							<div style={{"height":"3px"}}></div>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<span className="p-float-label">
								<InputText name="doc_to" id="doc_to" style={{"width":"100%"}} value={inputValues.doc_to} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="doc_to">Catre cine se trimite*</label>
							</span>
							<div style={{"height":"3px"}}></div>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Fieldset legend="Calea de comunicare" style={{"marginTop":"-12px"}}>
								<div style={{"width":"48%","float":"left"}}>
									<div className="field-radiobutton">
										<RadioButton inputId="drm1" name="doc_receiving_method" value="Personal" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Personal"} />
										<label htmlFor="drm1">Personal</label>
									</div>
									<div className="field-radiobutton">
										<RadioButton inputId="drm2" name="doc_receiving_method" value="Fax" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Fax"} />
										<label htmlFor="drm2">Fax</label>
									</div>
									<div className="field-radiobutton">
										<RadioButton inputId="drm3" name="doc_receiving_method" value="Nota telefonica" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Nota telefonica"} />
										<label htmlFor="drm3">Nota telefonica</label>
									</div>
									<div className="field-radiobutton">
										<RadioButton inputId="drm4" name="doc_receiving_method" value="Radio" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Radio"} />
										<label htmlFor="drm4">Radio</label>
									</div>					
									<div className="field-radiobutton">
										<RadioButton inputId="drm5" name="doc_receiving_method" value="Email" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Email"} />
										<label htmlFor="drm5">Email</label>
									</div>
								</div>
								<div style={{"width":"48%","float":"right"}}>
									<div className="field-radiobutton">
										<RadioButton inputId="drm6" name="doc_receiving_method" value="Site Web" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Site Web"} />
										<label htmlFor="drm6">Site Web</label>
									</div>
									<div className="field-radiobutton">
										<RadioButton inputId="drm7" name="doc_receiving_method" value="Canal criptat" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Canal criptat"} />
										<label htmlFor="drm7">Canal criptat</label>
									</div>
									<div className="field-radiobutton">
										<RadioButton inputId="drm8" name="doc_receiving_method" value="Curier" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Curier"} />
										<label htmlFor="drm8">Curier</label>
									</div>
									<div className="field-radiobutton">
										<RadioButton inputId="drm9" name="doc_receiving_method" value="Posta" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Posta"} />
										<label htmlFor="drm9">Posta</label>
									</div>					
									<div className="field-radiobutton">
										<RadioButton inputId="drm10" name="doc_receiving_method" value="Posta speciala" onChange={(evt) => handleChange(evt)} checked={inputValues.doc_receiving_method === "Posta speciala"} />
										<label htmlFor="drm10">Posta speciala</label>
									</div>
								</div>
							</Fieldset>
							<div style={{"height":"18px"}}></div>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<span className="p-float-label">
								<InputTextarea name="doc_description" id="doc_description" style={{"width":"100%"}} value={inputValues.doc_description} onChange={(evt) => handleChange(evt)} rows={7}/>
								<label htmlFor="doc_description">Scurta descriere*</label>
							</span>
							<div style={{"height":"3px"}}></div>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<span className="p-float-label">
								<InputTextarea name="doc_observations" id="doc_observations" style={{"width":"100%"}} value={inputValues.doc_observations} onChange={(evt) => handleChange(evt)} rows={7}/>
								<label htmlFor="doc_observations">Observatii</label>
							</span>
							<div style={{"height":"3px"}}></div>
						</Grid>
						{!!(!props.session.can_set_urgent) &&
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<span className="p-float-label">
									<Calendar name="doc_due_date" id="doc_due_date" dateFormat="dd/mm/yy" style={{"width":"100%"}} value={inputValues.doc_due_date} onChange={(evt) => handleChange(evt)} showIcon readOnlyInput/>
									<label htmlFor="doc_due_date">Data limita*</label>
								</span>
							</Grid>
						}
						{!!(props.session.can_set_urgent) &&
							<React.Fragment>
								<Grid item xs={12} sm={12} md={3} lg={2}>
									<span className="p-float-label">
										<Calendar name="doc_due_date" id="doc_due_date" dateFormat="dd/mm/yy" style={{"width":"100%"}} value={inputValues.doc_due_date} onChange={(evt) => handleChange(evt)} showIcon readOnlyInput/>
										<label htmlFor="doc_due_date">Data limita*</label>
									</span>
								</Grid>
								<Grid item xs={12} sm={12} md={3} lg={2} style={{"textAlign":"center"}}>
									<Checkbox name="doc_urgent" id="doc_urgent" inputId="doc_urgent" style={{"marginTop":"6px","marginRight":"9px"}} checked={(inputValues.doc_urgent===1?true:false)} onChange={(evt) => handleCheckedSpecial(evt)} />
									<label htmlFor="doc_urgent">Urgent</label>
								</Grid>
							</React.Fragment>
						}
						<Grid item xs={12} sm={12} md={3} lg={2} style={{"textAlign":"center"}}>
							<Button type="button" label="Alege destinatari document" icon="pi pi-share-alt" className="p-button-sm p-button-primary" style={{"marginTop":"1px"}} onClick={(evt) => handleButton(evt,"recipients",1)}/>
						</Grid>
						{inputValues.id &&
							<Grid item xs={12} sm={12} md={12} lg={6}>
								<Paper style={{"height":"87px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => handleButton(evt,"add_file_to_document",null)}>
									<p className="new_document_selection" style={{"marginTop":"30px"}}>Incarca un fisier sau un formular</p>
								</Paper>
							</Grid>
						}
					</Grid>
					{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
					{dialogValues.openAddSuccess && addSuccessDialog()}
					{dialogValues.openAddFile && addFileDialog()}
					{dialogValues.openDocumentDetailsRelated && documentDetailsRelatedDialog()}
					{dialogValues.openRecipients && recipientsDialog()}
				</div>
			</BlockUI>
		)
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);				
	}
};

export default DocumentExitFromOffice;
