import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {confirmDialog} from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { BlockUI } from 'primereact/blockui';
import NFluxDetails from "./NFluxDetails.js";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";
let defaultInputValues={
	id: 0,
	name: "",
	observations: "",
};

let defaultDataValues={
	fluxesList: [],
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
	openSettings: false,
	titleSettings: "",
};

let defaultInputError={
	disableSave: false,
	errorText: "",
}

const NFluxes = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [inputError, setInputError] = useState(defaultInputError);	
	const [blockScreen, setBlockScreen] = useState(false);
	const [currentFlux, setCurrentFlux] = useState({});

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="name") {
			let found=false;
			dataValues.fluxesList.forEach(element => {
				if(value.toUpperCase()===element.name.toUpperCase()) {
					found=true;
				}
			});
			let tmp={
				disableSave: found,
				errorText: (found===true?"Un flux cu acest nume exista deja!":""),
			}
			setInputError(tmp);
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Adaugare flux de lucru"});
			setInputError(defaultInputError);
		}
		if(what==="edit") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Editare "+elementData.name});
			setInputError(defaultInputError);
		}
		if(what==="delete") {
			let url="deleteFlux";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save") {
			if(inputValues.name==="") {
				setMessageText("Eroare: Completati campul nume flux!");				
				return false;
			}
			let url="saveFlux";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data=inputValues;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="settings") {
			setCurrentFlux(elementData);
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openSettings: true,titleSettings: "Setari flux"});
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save")||(oper==="delete")) {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,fluxesList:(result.data?result.data:[])});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getFluxes";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	const refresh = () => {
		oper="get";
		let url="getFluxes";
		url+="?session_id="+props.session.sessId;
		let data={};
		fetch_url_post(url,data,success_handler,fail_handler);		
	}

	const addEditDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="name">Nume</label>
						</span>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputText name="observations" id="observations" style={{"width":"100%"}} value={inputValues.observations} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="observations">Observatii</label>
						</span>
					</Grid>
					<Grid item xs={7} style={{"textAlign":"left"}}>
						<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"9px"}}>
							{inputError.errorText}
						</div>
					</Grid>
					<Grid item xs={5} style={{"textAlign":"right"}}>
						<React.Fragment>
							<Button label="Salveaza" icon="pi pi-check-circle" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")} disabled={inputError.disableSave}/>
							<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
						</React.Fragment>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const fluxSettingsDialog = () => {
		return(
			<Dialog header={dialogValues.titleSettings} visible={true} onHide={(evt)=>{setDialogValues({...dialogValues,openSettings:false});refresh()}} breakpoints={{"960px":"75vw"}} style={{"width":"84vw"}} contentStyle={{"minHeight":"753px","paddingBottom":"15px"}}>
				<NFluxDetails session={props.session} flux={currentFlux}/>
			</Dialog>
		);
	}

	const tableColTemplate = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<React.Fragment>
					<span className="p-column-title" style={{"width":"36%"}}>{column.header}</span>
					<p style={{"margin":"12px"}}>{row_data[column.field]}</p>
				</React.Fragment>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					<React.Fragment>
						<Button label="Editare" icon="pi pi-pencil" className="p-button-sm p-button-primary" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
						<Button label="Setari flux" icon="pi pi-sort-numeric-up-alt" className="p-button-sm p-button-success" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"settings",row_data)}/>
						<Button label="Sterge" icon="pi pi-trash" className="p-button-sm p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
					</React.Fragment>
				</span>
			);
		}
	}

	const confirm = (evt,what,elementData) => {
		if(what==="delete") {
			confirmDialog({
				message: "Sigur doriti stergerea? Operatia este ireversibila!",
				header: "Confirmare stergere",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Da",
				rejectLabel: "Nu",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
					<div className="datatable-responsive">
						<Button label="Adauga" icon="pi pi-plus-circle" className="p-button-sm p-button-success" style={{"float":"right","marginBottom":"12px"}} onClick={(evt) => {handleButton(evt,"add")}}/>
						<br clear="all"/>
						<DataTable 
							value={dataValues.fluxesList}
							stripedRows
							autoLayout={true}
							paginator rows={10} emptyMessage="Nu exista inregistrari" currentPageReportTemplate="Afisez {first} pana la {last} din {totalRecords} inregistrari"
							selectionMode="single"
							selection={currentFlux}
							onSelectionChange={evt=>{setCurrentFlux(evt.value)}}
							className="p-datatable-responsive p-datatable-sm"
							style={{"width":"100%"}}
						>
							<Column field="name" header="Nume flux" body={tableColTemplate}/>
							<Column field="reg_date_parsed" header="Adaugat" body={tableColTemplate}/>
							<Column field="users_count" header="Numar utilizatori" body={tableColTemplate}/>
							<Column field="buttons" header="" body={tableColTemplate} style={{"textAlign":"center"}}/>
						</DataTable>
					</div>
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
					<div>
						<Button label="Adauga" icon="pi pi-plus-circle" className="p-button-sm p-button-success" style={{"float":"right","marginBottom":"12px"}} onClick={(evt) => {handleButton(evt,"add")}}/>
						<br clear="all"/>
						<DataTable 
							value={dataValues.fluxesList}
							stripedRows
							paginator rows={10} emptyMessage="Nu exista inregistrari" currentPageReportTemplate="Afisez {first} pana la {last} din {totalRecords} inregistrari"
							selectionMode="single"
							selection={currentFlux}
							onSelectionChange={evt=>{setCurrentFlux(evt.value)}}
							style={{"width":"100%"}}
							responsiveLayout="scroll"
						>
							<Column field="name" header="Nume flux" sortable/>
							<Column field="reg_date_parsed" header="Adaugat" sortable/>
							<Column field="users_count" header="Numar utilizatori" body={tableColTemplate}/>
							<Column field="buttons" header="" body={tableColTemplate} style={{"textAlign":"center"}}/>
						</DataTable>
					</div>
				</Box>
				<ConfirmDialog />
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openSettings && fluxSettingsDialog()}
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default NFluxes;

