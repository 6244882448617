import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Mention } from 'primereact/mention';
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let defaultInputValues={
	comment: "",
};

let defaultDataValues={
	historyList: [],
	usersList: [],
};

const DocumentDetailsHistory = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [blockScreen, setBlockScreen] = useState(false);
	const [dataOk, setDataOk] = useState(false);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="init_document") {
			setDataValues({...dataValues,historyList:result.data.historyList,usersList:result.data.usersList});
			if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_document";
			let url="getDocumentHistory";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"marginTop":"-12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center"}}>
						<p style={{"fontSize":"18px","fontWeight":"600","marginTop":"12px"}}>Istoric document</p>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginBottom":"12px"}}>

					</Grid>
				</Grid>
				<div style={{"height":"480px","overflowY":"auto"}}>
					<Grid container spacing={1} name="history_list" id="history_list">
						{dataValues.historyList && dataValues.historyList.map((history_entry,index) =>
							<Grid item xs={12} sm={12} md={12} lg={12} key={index}>
								<Card subTitle={history_entry.title} style={{"backgroundColor":"#F8F9FA","padding":"0px"}}>
									<p style={{"marginTop":"-12px"}}>{history_entry.operation}</p>
								</Card>
							</Grid>
						)}
					</Grid>
				</div>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};
export default DocumentDetailsHistory;


