import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {Panel} from 'primereact/panel';
import {confirmDialog} from 'primereact/confirmdialog';
import { BlockUI } from 'primereact/blockui';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultInputValues={
	id: 0,
	name: "",
	register_id: "0",
	observations: "",
	active: 1,
};

let defaultDataValues={
	unitsList: [],
	registersList: [],
	departmentsList: [],
};

let defaultSelectedElements={
	unitsTable: null,
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
	openDepartments: false,
	titleDepartments: "",
};

let defaultInputError={
	disableSave: false,
	errorText: "",
};

let currentUnitName="";

const Units = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [selectedElements, setSelectedElements] = useState(defaultSelectedElements);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [inputError, setInputError] = useState(defaultInputError);	
	const [blockScreen, setBlockScreen] = useState(false);

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="name") {
			let found=false;
			dataValues.unitsList.forEach(element => {
				if(value.toUpperCase()===element.name.toUpperCase()) {
					found=true;
				}
			});
			let tmp={
				disableSave: found,
				errorText: (found===true?"Institutia exista deja exista deja!":""),
			}
			setInputError(tmp);
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Adaugare institutie"});
			setInputError(defaultInputError);
		}
		if(what==="edit") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Editare "+elementData.name});
			setInputError(defaultInputError);
		}
		if(what==="departments") {
			let url="getDepartmentsForUnit";
			url+="?session_id="+props.session.sessId;
			oper="get_departments_for_unit";
			let data={
				id: elementData.id,
			};
			currentUnitName=elementData.name;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete") {
			let url="deleteUnit";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save") {
			if(inputValues.name==="") {
				setMessageText("Eroare: Completati campul nume unitate!");				
				return false;
			}
			if(inputValues.register_id==="0") {
				setMessageText("Eroare: Selectati registrul alocat!");				
				return false;
			}
			let url="saveUnit";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data=inputValues;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save")||(oper==="delete")) {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,unitsList:result.unitsList,registersList:result.registersList});
			defaultInputValues.register_id=(result.registersList.length===1?result.registersList[0].id:"0");
			if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="get_departments_for_unit") {
			setDataValues({...dataValues,departmentsList:result.data});
			setDialogValues({...dialogValues,openDepartments: true,titleDepartments: "Lista departamente institutie: "+currentUnitName});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getUnits";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	const addEditDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputText name="full_name" id="full_name" style={{"width":"100%"}} value={inputValues.full_name} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="full_name">Nume</label>
						</span>
					</Grid>
					<Grid item xs={12} sm={12} md={5} lg={5}>
						<span className="p-float-label">
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="name">Prescurtare</label>
						</span>
					</Grid>
					<Grid item xs={12} sm={12} md={7} lg={7}>
						<span className="p-float-label">
							<Dropdown name="register_id" id="register_id" style={{"width":"100%","height":"36px"}} inputStyle={{"width":"100%"}} options={dataValues.registersList} optionLabel="name" optionValue="id" value={inputValues.register_id} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="register_id">Registru documente</label>
						</span>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span className="p-float-label">
							<InputText name="observations" id="observations" style={{"width":"100%"}} value={inputValues.observations} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="observations">Observatii</label>
						</span>
					</Grid>
					<Grid item xs={7} style={{"textAlign":"left"}}>
						<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"9px"}}>
							{inputError.errorText}
						</div>
					</Grid>
					<Grid item xs={5} style={{"textAlign":"right"}}>
						<React.Fragment>
							<Button label="Salveaza" icon="pi pi-check-circle" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")} disabled={inputError.disableSave}/>
							<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
						</React.Fragment>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const departmentsDialog = () => {
		return(
			<Dialog header={dialogValues.titleDepartments} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDepartments:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				{dataValues.departmentsList.length>0 &&
					<Grid container spacing={1} style={{}}>
						{dataValues.departmentsList.map((department) => 
							<Grid item xs={12} sm={12} md={12} lg={12} key={department.id}>
								<Panel header={department.name}>
									<p>
										{department.observations}
									</p>
								</Panel>
							</Grid>
						)}
					</Grid>
				}
				{dataValues.departmentsList.length===0 &&
					<Grid container spacing={1} style={{}}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<p style={{"textAlign":"center"}}>
								<b>Nu exista departamente pentru unitatea selectata.</b>
							</p>
						</Grid>
					</Grid>
				}
			</Dialog>
		);
	}

	const tableColTemplate = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<React.Fragment>
					<span className="p-column-title" style={{"width":"36%"}}>{column.header}</span>
					<p style={{"margin":"12px"}}>{row_data[column.field]}</p>
				</React.Fragment>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					{!!(row_data.name!=="Centrala") &&
						<React.Fragment>
							<Button label="Editare" icon="pi pi-pencil" className="p-button-sm p-button-primary" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
							<Button label="Departamente" icon="pi pi-sitemap" className="p-button-sm p-button-warning" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"departments",row_data)}/>
							<Button label="Sterge" icon="pi pi-trash" className="p-button-sm p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
						</React.Fragment>
					}
					{!!(row_data.name==="Centrala") &&
						<React.Fragment>
							<Button label="Departamente" icon="pi pi-sitemap" className="p-button-sm p-button-warning" style={{}} onClick={(evt) => handleButton(evt,"departments",row_data)}/>
						</React.Fragment>
					}
				</span>
			);
		}
	}

	const confirm = (evt,what,elementData) => {
		if(what==="delete") {
			confirmDialog({
				message: "Sigur doriti stergerea? Operatia este ireversibila!",
				header: "Confirmare stergere",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Da",
				rejectLabel: "Nu",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
					<div className="datatable-responsive">
						<Button label="Adauga" icon="pi pi-plus-circle" className="p-button-sm p-button-success" style={{"float":"right","marginBottom":"12px"}} onClick={(evt) => {handleButton(evt,"add")}}/>
						<br clear="all"/>
						<DataTable 
							value={dataValues.unitsList}
							stripedRows
							autoLayout={true}
							paginator rows={10} emptyMessage="Nu exista inregistrari" currentPageReportTemplate="Afisez {first} pana la {last} din {totalRecords} inregistrari"
							selectionMode="single"
							selection={selectedElements.unitsTable}
							onSelectionChange={evt=>{setSelectedElements({...selectedElements,unitsTable:evt.value})}}
							className="p-datatable-responsive p-datatable-sm"
							style={{"width":"100%"}}
						>
							<Column field="full_name" header="Nume unitate subordonata" body={tableColTemplate}/>
							<Column field="name" header="Prescurtare" body={tableColTemplate}/>
							<Column field="register_name" header="Registru" body={tableColTemplate}/>
							<Column field="reg_date_parsed" header="Adaugat" body={tableColTemplate}/>
							<Column field="buttons" header="" body={tableColTemplate} style={{"textAlign":"center"}}/>
						</DataTable>
					</div>
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
					<div>
						<Button label="Adauga" icon="pi pi-plus-circle" className="p-button-sm p-button-success" style={{"float":"right","marginBottom":"12px"}} onClick={(evt) => {handleButton(evt,"add")}}/>
						<br clear="all"/>
						<DataTable 
							value={dataValues.unitsList}
							stripedRows
							paginator rows={10} emptyMessage="Nu exista inregistrari" currentPageReportTemplate="Afisez {first} pana la {last} din {totalRecords} inregistrari"
							selectionMode="single"
							selection={selectedElements.unitsTable}
							onSelectionChange={evt=>{setSelectedElements({...selectedElements,unitsTable:evt.value})}}
							style={{"width":"100%"}}
							responsiveLayout="scroll"
						>
							<Column field="full_name" header="Nume unitate subordonata" sortable/>
							<Column field="name" header="Prescurtare" body={tableColTemplate}/>
							<Column field="register_name" header="Registru" sortable/>
							<Column field="reg_date_parsed" header="Adaugat" sortable/>
							<Column field="buttons" header="" body={tableColTemplate} style={{"textAlign":"center"}}/>
						</DataTable>
					</div>
				</Box>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openDepartments && departmentsDialog()}
			</BlockUI>
		);
	}else{
		return(
			<LinearProgress />
		);
	}
};

export default Units;

