import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import { BlockUI } from 'primereact/blockui';
import { Calendar } from "primereact/calendar";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {save_form_details} from "../../Sources/js/app.js";
import {get_api_path} from "../../Sources/js/paths.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let defaultInputValues={
	doc_no: "",
	doc_date: "",
	name: "",
	function: "",
	start_date: today,
	end_date: today,
	days: "",
	location: "",
	phone: "",
	replaced_by: "",
};

let inputValuesRequired=["name","function","start_date","end_date","days","replaced_by"];

const HolidayRequest = (props) => {

	var timerId=null;
	defaultInputValues.name=props.session.name;
	defaultInputValues.function=props.session.function;
	defaultInputValues.doc_id=props.document.id;
	defaultInputValues.doc_no=props.document.doc_no;
	defaultInputValues.doc_date=props.document.doc_date;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [messageText, setMessageText] = useState(null);
    const [blockScreen, setBlockScreen] = useState(false);
	const [pdfUrl, setPdfUrl] = useState(null);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="generate_pdf") {
			oper="generate_pdf";
			timerId=save_form_details(inputValues,inputValuesRequired,"pdfHolidayRequest",props.session.sessId,success_handler,fail_handler,setMessageText,wait_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="generate_pdf") {
            if(!pdfUrl) {
				setPdfUrl(get_api_path()+"getPdf"+"?session_id="+props.session.sessId+"&file="+result.data);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	if(!pdfUrl) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"marginBottom":"42px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<p style={{"fontWeight":"600","textAlign":"center","color":"#FF0000","marginBottom":"36px","fontSize":"24px"}}>Formular cerere de concediu</p>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<span className="p-float-label">
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="name">Nume si prenume*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<span className="p-float-label">
							<InputText name="function" id="function" style={{"width":"100%"}} value={inputValues.function} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="name">Functie*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<span className="p-float-label">
							<Calendar name="start_date" id="start_date" dateFormat="dd/mm/yy" style={{"width":"100%"}} value={inputValues.start_date} onChange={(evt) => handleChange(evt)} showIcon />
							<label htmlFor="start_date">Data inceput*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>				
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<span className="p-float-label">
							<Calendar name="end_date" id="end_date" dateFormat="dd/mm/yy" style={{"width":"100%"}} value={inputValues.end_date} onChange={(evt) => handleChange(evt)} showIcon />
							<label htmlFor="end_date">Data sfarsit*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<span className="p-float-label">
							<InputText name="days" id="days" style={{"width":"100%"}} value={inputValues.days} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="days">Numar zile*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<span className="p-float-label">
							<InputText name="phone" id="phone" style={{"width":"100%"}} value={inputValues.phone} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="phone">Numar telefon</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<span className="p-float-label">
							<InputText name="location" id="location" style={{"width":"100%"}} value={inputValues.location} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="location">Locatie contact</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<span className="p-float-label">
							<InputText name="replaced_by" id="replaced_by" style={{"width":"100%"}} value={inputValues.replaced_by} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="replaced_by">Inlocuitor*</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right"}}>
						<Button type="button" label="Genereaza cerere" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"generate_pdf",null)} />					
						<Button type="button" label="Inapoi la lista" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{"marginRight":"12px"}} onClick={(evt) => props.resetSectionHandler(0)} />					
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<p style={{"textAlign":"left","marginTop":"36px","fontSize":"15px"}}><b>Nota*</b><br/><br/>&nbsp;&nbsp;&nbsp;In campul "Data inceput" se va completa prima zi libera<br/>&nbsp;&nbsp;&nbsp;In campul "Data sfarsit" se va completa prima zi lucratoare.</p>
						<div style={{"height":"3px"}}></div>
					</Grid>
				</Grid>
                {messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
            </BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<div style={{"width":"100%","textAlign":"right"}}>
					<Button type="button" label="Inapoi la lista de fisiere" icon="pi pi-angle-left" className="p-button-sm p-button-primary" style={{"marginBottom":"12px"}} onClick={(evt) => props.resetSectionHandler(0)} />					
				</div>
				<iframe src={`${pdfUrl}#view=fitH`} title="PDF" width="100%" height="501px"></iframe>
			</React.Fragment>
		);
	}
};

export default HolidayRequest;
