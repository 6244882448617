import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Badge } from 'primereact/badge';
import DocumentDetailsDetails from "./DocumentDetailsDetails.js";
import AddFileToDocument from "./AddFileToDocument.js";
import DocumentDetailsComments from "./DocumentDetailsComments.js";
import DocumentDetailsHistory from "./DocumentDetailsHistory.js";
import DocumentDetailsSendTo from "./DocumentDetailsSendTo.js";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let defaultDialogValues={
	openDocumentRefuse: false,
	titleDocumentRefuse: "Refuzare document",
};

let defaultInputValues={
	reason: "",
};

const DocumentDetails = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [section, setSection] = useState("document_details");
	const [acceptedOrRefused, setAcceptedOrRefused] = useState(false);
	const [messageText, setMessageText] = useState(null);
	const [blockScreen, setBlockScreen] = useState(false);
	const [changed, setChanged] = useState(0);
	const [commentsNo, setCommentsNo] = useState(0);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what) => {
		let section_ok=0;
		if(what==="accept_document") {
			section_ok=1;
			oper="accept_document";
			let url="acceptDocument";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="refuse_document_dialog") {
			section_ok=1;
			setDialogValues({...dialogValues,openDocumentRefuse: true});
		}
		if(what==="refuse_document") {
			section_ok=1;
			if(inputValues.reason==="") {
				setMessageText("Eroare: Completati motivul refuzului!");
				return false;				
			}
			oper="refuse_document";
			let url="refuseDocument";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
				reason: inputValues.reason,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="related_exit") {
			section_ok=1;
			let doc_year=props.document.doc_date.split("-")[2];
			history.push("/newDocument/exitRelated/"+props.document.doc_no+"/"+doc_year);
		}
		if(section_ok===0) {
			setSection(what);
		}
	}

	const handleIncrementComments = (num) => {
		if(num>=0) {
			setCommentsNo(0);			
		}else{
			setCommentsNo(commentsNo+1);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="init_document") {
			if(!result.commentsNo) {
				setCommentsNo("O");
			}else{
				setCommentsNo(result.commentsNo);
			}
			oper="";
		}
		if((oper==="accept_document")||(oper==="refuse_document")) {
			setAcceptedOrRefused(true);
			props.changeHandler();
			if(oper==="accept_document") {
				props.closeHandler("Documentul a fost acceptat cu succes!");
			}else{
				props.closeHandler("Documentul a fost refuzat!");
			}
			setChanged(Math.random(9));
			setDialogValues({...dialogValues,openDocumentRefuse: false});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_document";
			let url="getDocumentShortDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login");
		}
	},[]);

	const getCurrentSection = () => {
		switch(section) {
			case "document_details":
				return(
					<DocumentDetailsDetails session={props.session} document={props.document} changeHandler={props.changeHandler} type={props.type} closeHandler={props.closeHandler}/>
				);
				break;
			case "document_files":
				return(
					<AddFileToDocument session={props.session} document={props.document}/>
				);
				break;
			case "document_comments":
				return(
					<DocumentDetailsComments session={props.session} document={props.document} incrementHandler={handleIncrementComments}/>
				);
				break;
			case "document_history":
				return(
					<DocumentDetailsHistory session={props.session} document={props.document}/>
				);
				break;
			case "send_to":
				return(
				<DocumentDetailsSendTo session={props.session} document={props.document} changeHandler={props.changeHandler} changed={changed}/>
			);
			break;
			default:
				return(
					<DocumentDetailsDetails session={props.session} document={props.document} changeHandler={props.changeHandler} type={props.type} closeHandler={props.closeHandler}/>
				);
		}
	}

	const documentRefuseDialog = () => {
		return(
			<Dialog header={dialogValues.titleDocumentRefuse} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openDocumentRefuse:false})} breakpoints={{"960px":"75vw"}} resizable={false} style={{"width":"57vw"}}>
				<div style={{"height":"24px"}}></div>
				<span className="p-float-label">
					<InputTextarea name="reason" id="reason" style={{"minHeight":"120px"}} value={inputValues.reason} onChange={(evt) => handleChange(evt)} rows={3}/>
					<label htmlFor="reason">Motiv refuz*</label>
				</span>
				<div style={{"height":"3px"}}></div>
				<div style={{"Width":"100%","textAlign":"right"}}>
					<Button type="button" label="Refuza document" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"refuse_document")} />
					<Button type="button" label="Abandoneaza" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{}} onClick={(evt) => setDialogValues({...dialogValues,openDocumentRefuse:false})} />
				</div>
			</Dialog>
		);
	}

	return(
		<BlockUI blocked={blockScreen} fullScreen>
			<Grid container spacing={1} style={{}}>
				<Grid item xs={2} sm={2} md={2} lg={2} style={{}}>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_details")}>Detalii document</p>
							</Card>
						</Grid>																						
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_files")}>Fisiere si formulare</p>
							</Card>
						</Grid>																						
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								{!!(!commentsNo) &&
									<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_comments")}>Comentarii</p>
								}
								{!!(commentsNo) &&
									<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_comments")}>Comentarii<Badge severity="danger" value={commentsNo} style={{"marginLeft":"12px"}}></Badge></p>
								}
							</Card>
						</Grid>																						
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_history")}>Istoric document</p>
							</Card>
						</Grid>																						
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"send_to")}>Destinatari</p>
							</Card>
						</Grid>																						
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"related_exit")}>Iesire relationata</p>
							</Card>
						</Grid>																						
						{props.type==="received" && acceptedOrRefused===false &&
							<React.Fragment>
								<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px","marginTop":"12px"}}>
									<Card style={{"height":"57px","background":"#689f38"}}>
										<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"accept_document")}>Accepta document</p>
									</Card>
								</Grid>																						
								<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
									<Card style={{"height":"57px","background":"#d32f2f"}}>
										<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"refuse_document_dialog")}>Refuza document</p>
									</Card>
								</Grid>																						
							</React.Fragment>
						}
					</Grid>
				</Grid>
				<Grid item xs={10} sm={10} md={10} lg={10} style={{}}>
					{getCurrentSection()}
				</Grid>
			</Grid>
			{dialogValues.openDocumentRefuse && documentRefuseDialog()}
			{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
		</BlockUI>
	);
};

export default DocumentDetails;


