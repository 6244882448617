import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from 'primereact/checkbox';
import { BlockUI } from 'primereact/blockui';
import { TabView, TabPanel } from 'primereact/tabview';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultInputValues={
	id: null,
	password: "",
	confirm_password: "",
	current_password: "",
	holliday: false,
	replaced_by: "",
};

let defaultDataValues={
	usersDetails: {},
};

const UserSettings = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}
	
	const handleChecked = (evt) => {
		const {name, checked} = evt.target;
		if(checked) {
			setInputValues({...inputValues,[name]:1});
		}else{
			setInputValues({...inputValues,[name]:0,replaced_by:0});
		}
	}	

	const handleButton = (evt,what) => {
		if(what==="save_password") {
			if((inputValues.current_password==="")||(inputValues.password==="")||(inputValues.confirm_password==="")) {
				setMessageText("Eroare: Completati campurile obligatorii!");				
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Eroare: Parola si confirmarea parolei trebuie sa fie identice!");				
				return false;
			}
			let url="saveUserPassword";
			url+="?session_id="+props.session.sessId;
			oper="save_password";
			let data={
				current_password: inputValues.current_password,
				password: inputValues.password,
				confirm_password: inputValues.confirm_password,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save_holliday_settings") {
			if((inputValues.holliday===1)&&((inputValues.replaced_by==="")||(inputValues.replaced_by==0))) {
				setMessageText("Eroare: Selectati inlocuitorul din lista!");				
				return false;
			}
			let url="saveHollidaySettings";
			url+="?session_id="+props.session.sessId;
			oper="save_holliday_settings";
			let data={
				holliday: inputValues.holliday,
				replaced_by: inputValues.replaced_by,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="get_user_settings") {
			setDataValues({...dataValues,usersList:result.usersList});
			setInputValues({...inputValues,holliday:result.userSettings.holliday,replaced_by:result.userSettings.replaced_by});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="save_password") {
			setInputValues(defaultInputValues);
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get_user_settings";
			let url="getUserSettings";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<TabView activeIndex={activeIndex} className="tabview-header-icon" onTabChange={(e) => setActiveIndex(e.index)}>
					<TabPanel leftIcon="pi pi-key" header="Schimbare parola">
						<Grid container spacing={1} style={{"paddingTop":"18px"}}>
							<Grid item xs={12} style={{"marginBottom":"18px"}}>
								<span className="p-float-label">
									<Password id="current_password" name="current_password" style={{"width":"100%"}} inputStyle={{"width":"100%"}} value={inputValues.current_password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
									<label htmlFor="current_password">Parola curenta*</label>
								</span>
							</Grid>
							<Grid item xs={12} style={{"marginBottom":"18px"}}>
								<span className="p-float-label">
									<Password id="password" name="password" style={{"width":"100%"}} inputStyle={{"width":"100%"}} value={inputValues.password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
									<label htmlFor="password">Parola*</label>
								</span>
							</Grid>
							<Grid item xs={12} style={{}}>
								<span className="p-float-label">
									<Password id="confirm_password" name="confirm_password" style={{"width":"100%"}} inputStyle={{"width":"100%"}} value={inputValues.confirm_password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
									<label htmlFor="confirm_password">Confirmare parola*</label>
								</span>
							</Grid>
							<Grid item xs={12} style={{"textAlign":"right","marginTop":"15px"}}>
								<Button label="Salveaza" icon="pi pi-check-circle" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_password")}/>
								<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>props.closeHandler(evt)}/>
							</Grid>
						</Grid>
					</TabPanel>
					<TabPanel leftIcon="pi pi-calendar" header="Setari concediu de odihna">
						<Grid container spacing={1} style={{"paddingTop":"18px"}}>
							<Grid item xs={12} style={{"textAlign":"center","marginBottom":"24px"}}>
								<Checkbox name="holliday" id="holliday" inputId="holliday" style={{"marginRight":"12px"}} checked={(inputValues.holliday==1?true:false)} onChange={(evt) => handleChecked(evt)} />
								<label htmlFor="holliday">In concediu de odihna</label>
							</Grid>
							<Grid item xs={12} style={{"marginBottom":"69px"}}>
								<span className="p-float-label">
									<Dropdown name="replaced_by" id="replaced_by" value={inputValues.replaced_by} style={{"width":"100%","height":"36px"}} options={dataValues.usersList} onChange={(evt) => handleChange(evt)} optionLabel="name" optionValue="id" showOnFocus />
									<label htmlFor="replaced_by">Inlocuitor in perioada concediului</label>
								</span>
							</Grid>
							<Grid item xs={12} style={{"textAlign":"right","marginTop":"18px"}}>
								<Button label="Salveaza" icon="pi pi-check-circle" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_holliday_settings")}/>
								<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>props.closeHandler(evt)}/>
							</Grid>
						</Grid>
					</TabPanel>
				</TabView>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
            </BlockUI>
		);
	}else{
		return(
			<LinearProgress />
		);
	}
};

export default UserSettings;

