import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import LinearProgress from "@mui/material/LinearProgress";
import history from "../../Sources/js/history.js";
import Message from "../Helpers/Message.js";
import DashboardDocumentsList from "./DashboardDocumentsList.js";
import {fetch_url_post,save_form_details} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let defaultInputValues={
	search_doc_no: "",
	search_doc_type: null,
	search_doc_created_by: null,
};

let defaultDataValues={
	dashboardList: {},
};

const DashboardPerCategories = (props) => {

	var timerId=null;

	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);
	const [inputValues, setInputValues] = useState(defaultInputValues);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="init_documents") {
			setDataValues({...dataValues,dashboardList:result.data.documentsList});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_documents";
			let url="getDashboardDocuments";
			url+="?session_id="+props.session.sessId;
			let data={
				render: "simple",
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{}}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Card title="Documente de acceptat" className="dashboard_section">
							<DashboardDocumentsList session={props.session} type="receivedDocuments" data={dataValues.dashboardList.received}/>							
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Card title="Documente acceptate" className="dashboard_section">
							<DashboardDocumentsList session={props.session} type="acceptedDocuments" data={dataValues.dashboardList.accepted}/>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Card title="Documente emise de mine" className="dashboard_section">
							<DashboardDocumentsList session={props.session} type="emittedDocuments" data={dataValues.dashboardList.emitted}/>
						</Card>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Card title="Miscari recente" className="dashboard_section">
							<DashboardDocumentsList session={props.session} type="recentEvents" data={dataValues.dashboardList.recent}/>
						</Card>
					</Grid>
				</Grid>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default DashboardPerCategories;
