import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Mention } from 'primereact/mention';
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post,fetch_download} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let defaultInputValues={
	comment: "",
};

let defaultDataValues={
	commentsList: [],
	usersList:[],
};

let defaultDialogValues={
	openAddComment: false,
	titleAddComment: "Adauga comentariu nou",
};

const DocumentDetailsComments = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [blockScreen, setBlockScreen] = useState(false);
	const [dataOk, setDataOk] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
    const [suggestions, setSuggestions] = useState([]);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="comment") {
			oper="add_comment";
			let url="addComment";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
				comment: inputValues.comment,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
		if(what==="add_comment") {
			setDialogValues({...dialogValues,openAddComment: true});
		}
		if(what==="download") {
			let url="downloadFile";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.ref_file_id,
                doc_id: props.document.id,
			};
			fetch_download(url,data,elementData.filename,success_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="init_document")||(oper==="add_comment")) {
			setDataValues({...dataValues,commentsList:result.data.commentsList,usersList:result.data.usersList});
			setInputValues({...inputValues,comment:""});
			setDialogValues({...dialogValues,openAddComment: false});
			if(oper==="init_document") {
				props.incrementHandler(0);
			}
			if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_document";
			let url="getDocumentComments";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

    const onSearch = (event) => {
        setTimeout(() => {
            const query = event.query;
            let suggestions;
            if(!query.trim().length) {
                suggestions = [...dataValues.usersList];
            }else{
                suggestions = dataValues.usersList.filter((user) => {
                    return user.full_user_name.toLowerCase().contains(query.toLowerCase());
                });
            }
            setSuggestions(suggestions);
        }, 250);
    }

	const addCommentDialog = () => {
		return(
			<Dialog header={dialogValues.titleAddComment} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddComment:false})} breakpoints={{"960px":"75vw"}} resizable={false} style={{"width":"60vw"}}>
				<div style={{"height":"24px"}}></div>
					<Mention name="comment" id="comment" suggestions={suggestions} onSearch={onSearch} style={{"width":"100%","minHeight":"300px"}} value={inputValues.comment} onChange={(evt) => handleChange(evt)} rows={5} field="full_user_name" placeholder="Introduceti comentariul aici. Apasati @ pentru a mentiona utilizatori" />
				<div style={{"height":"3px","clear":"both"}}></div>
				<div style={{"Width":"100%","textAlign":"right","marginTop":"12px"}}>
					<Button type="button" label="Adauga comentariu" icon="pi pi-comment" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"comment")}/>
					<Button type="button" label="Abandoneaza" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{}} onClick={(evt) => setDialogValues({...dialogValues,openAddComment:false})} />
				</div>
			</Dialog>
		);
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"marginTop":"-12px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center"}}>
						<p style={{"fontSize":"18px","fontWeight":"600","marginTop":"12px"}}>Comentarii document</p>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"right","marginBottom":"12px"}}>
						<Button type="button" label="Adauga comentariu" icon="pi pi-plus" className="p-button-sm p-button-primary" style={{"marginRight":"30px"}} onClick={(evt) => handleButton(evt,"add_comment")}/>
					</Grid>
				</Grid>
				<div style={{"height":"480px","overflowY":"auto"}}>
					<Grid container spacing={1} name="comments_list" id="comments_list">
						{dataValues.commentsList && dataValues.commentsList.map((comment,index) =>
							<Grid item xs={12} sm={12} md={12} lg={12} key={index}>
								<Card subTitle={comment.title} style={{"backgroundColor":"#F8F9FA","padding":"0px"}}>
									<p style={{"marginTop":"-12px"}}>{comment.comment}</p>
									{comment.ref_file_id>0 &&
										<Button type="button" label="Descarca fisier" icon="pi pi-cloud-download" className="p-button-sm p-button-secondary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"download",comment)}/>									
									}
								</Card>
							</Grid>
						)}
					</Grid>
				</div>
				{dialogValues.openAddComment && addCommentDialog()}
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default DocumentDetailsComments;


