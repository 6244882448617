import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from "primereact/dropdown";
import {confirmDialog} from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import LinearProgress from "@mui/material/LinearProgress";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post, is_in_array} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let expireList=[
	{label:"DA",value:"DA"},
	{label:"NU",value:"NU"},
];
let statusList=[
	{label:"IN LUCRU",value:"IN LUCRU"},
	{label:"REZOLVAT",value:"REZOLVAT"},
];
let expirePeriodList=[
	{label:"1 LUNA",value:1},
	{label:"2 LUNI",value:2},
	{label:"3 LUNI",value:3},
	{label:"6 LUNI",value:6},
	{label:"9 LUNI",value:9},
	{label:"12 LUNI",value:12},	
	{label:"18 LUNI",value:18},
	{label:"24 LUNI",value:24},
	{label:"36 LUNI",value:36},
	{label:"60 LUNI",value:60},
];

let defaultInputValues={
	doc_status: "",
	doc_solved_resolution: "",
	doc_ranked_resolution: "",
	doc_expire: 0,
	doc_expire_period: 0,
};

let defaultDataValues={
	documentDetails: [],
	recipients:"",
	relatedDocument: {},
};

const DocumentDetailsDetailsRelated = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [blockScreen, setBlockScreen] = useState(false);
	const [dataOk, setDataOk] = useState(false);
	const [canModifyDoc, setCanModifyDoc] = useState(false);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="save_status") {
			if((inputValues.doc_status==="CLASAT")&&(inputValues.doc_ranked_resolution==="")) {
				setMessageText("Eroare: Completati rezolutia de clasare!");
				return false;
			}
			if((inputValues.doc_expire=="DA")&&(inputValues.doc_expire_period=="0")) {
				setMessageText("Eroare: Selectati perioada de expirare!");
				return false;
			}
			oper="save_status";
			let url="setDocumentStatus";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
				status: inputValues.doc_status,
				solved_resolution: inputValues.doc_solved_resolution,
				ranked_resolution: inputValues.doc_ranked_resolution,
				doc_expire: inputValues.doc_expire,
				doc_expire_period: inputValues.doc_expire_period,
			};
			fetch_url_post(url,data,success_handler,fail_handler);			
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="init_document")||(oper==="save_status")) {
			setDataValues({...dataValues,
							documentDetails:(result.data.documentDetails?result.data.documentDetails:[]),
							recipients:(result.data.recipients?result.data.recipients:[])
			});
			setInputValues({...inputValues,...result.data.documentDetails});
			//see if we can modify
			if(props.session.can_modify===1) {
				setCanModifyDoc(true);
			}else{
				setCanModifyDoc(false);
			}
			if(!dataOk) {
				setDataOk(true);
			}
			if(oper==="save_status") {
				props.changeHandler();				
				props.closeHandler("Starea documentului a fost modificata cu succes!");
			}
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		console.log(props.document);
		if(props.session.sessId) {
			oper="init_document";
			let url="getDocumentDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
		//here we also add rights to rank and to delete document
		statusList=[
			{label:"IN LUCRU",value:"IN LUCRU"},
			{label:"REZOLVAT",value:"REZOLVAT"},
		];
		if(props.session.can_rank===1) {
			let tmp={label:"CLASAT",value:"CLASAT"};
			statusList.push(tmp);
		}
		if(props.session.can_delete===1) {
			let tmp={label:"ANULAT",value:"ANULAT"};
			statusList.push(tmp);
		}
	},[]);

	const confirm = (evt,what,elementData) => {
		if(what==="save_status") {
			let message="Sigur doriti modificarea?";
			if((elementData.doc_related_1!=="")||(elementData.doc_related_2!=="")||(elementData.doc_related_3!=="")) {
				message="Sigur doriti modificarea? Documentul are documente relationate. Starea documentului va fi transferata acestora!"
			}
			confirmDialog({
				message: message,
				header: "Confirmare modificare stare",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Da",
				rejectLabel: "Nu",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"marginTop":"18px"}}>
					<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center"}}>
						<p style={{"fontSize":"18px","fontWeight":"600","marginTop":"-12px","marginBottom":"24px"}}>Detalii document</p>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={1}>
						<span className="p-float-label">
							<InputText name="doc_no" id="doc_no" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_no} disabled={true}/>
							<label htmlFor="doc_no">Numar</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_date" id="doc_date" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_date} disabled={true}/>
							<label htmlFor="doc_date">Data</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={3}>
						<span className="p-float-label">
							<InputText name="doc_category" id="doc_category" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_category} disabled={true}/>
							<label htmlFor="doc_category">Categorie</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={4}>
						<span className="p-float-label">
							<InputText name="doc_type_name" id="doc_type_name" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_type_name} disabled={true}/>
							<label htmlFor="doc_type_name">Tip document</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_due_date" id="doc_due_date" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_due_date} disabled={true}/>
							<label htmlFor="doc_due">Termen de rezolvare</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={3}>
						<span className="p-float-label">
							<InputText name="doc_due_perodicity" id="doc_due_periodicity" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_due_periodicity} disabled={true}/>
							<label htmlFor="doc_due_periodicity">Termen de raportare</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_from_no_date" id="doc_from_no_date" style={{"width":"100%","color":"#000000 !important"}} value={inputValues.doc_from_no_date} disabled={true}/>
							<label htmlFor="doc_from_no_date">Nr/data intrare</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={5}>
						<span className="p-float-label">
							<InputText name="doc_from" id="doc_from" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_from} disabled={true}/>
							<label htmlFor="doc_from">Provenienta</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_created_at" id="doc_created_at" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_created_at} disabled={true}/>
							<label htmlFor="doc_created_at">Introdus la data</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_related_1" id="doc_related_1" style={{"width":"100%","color":"#000000 !important"}} value={(dataValues.documentDetails.doc_related_1?dataValues.documentDetails.doc_related_1:" - ")} disabled={true}/>
							<label htmlFor="doc_related_1">In legatura cu</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_related_2" id="doc_related_2" style={{"width":"100%","color":"#000000 !important"}} value={(dataValues.documentDetails.doc_related_2?dataValues.documentDetails.doc_related_2:" - ")} disabled={true}/>
							<label htmlFor="doc_related_2">In legatura cu</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_related_3" id="doc_related_3" style={{"width":"100%","color":"#000000 !important"}} value={(dataValues.documentDetails.doc_related_3?dataValues.documentDetails.doc_related_3:" - ")} disabled={true}/>
							<label htmlFor="doc_related_3">In legatura cu</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={4}>
						<span className="p-float-label">
							<InputText name="doc_created_by" id="doc_created_by" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.full_doc_created_by} disabled={true}/>
							<label htmlFor="doc_created_by">Introdus de catre</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={2}>
						<span className="p-float-label">
							<InputText name="doc_receiving_method" id="doc_receiving_method" style={{"width":"100%","color":"#000000 !important"}} value={dataValues.documentDetails.doc_receiving_method} disabled={true}/>
							<label htmlFor="doc_receiving_method">Calea de comunicare</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={7} lg={7}>
						<span className="p-float-label">
							<InputTextarea name="doc_description" id="doc_description" style={{"width":"100%","minHeight":"153px","color":"#000000 !important"}} value={dataValues.documentDetails.doc_description} disabled={true} rows={5}/>
							<label htmlFor="doc_description">Descriere</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={5} lg={5}>
						<span className="p-float-label">
							<InputTextarea name="doc_observations" id="doc_observations" style={{"width":"100%","minHeight":"153px","color":"#000000 !important"}} value={dataValues.documentDetails.doc_observations} disabled={true} rows={5}/>
							<label htmlFor="doc_observations">Observatii</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					{!!((props.type!=="received")&&(1===0)) &&
						<React.Fragment>
							<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"center"}}>
								<p style={{"fontSize":"18px","fontWeight":"600","marginTop":"-12px","marginBottom":"-3px"}}>Stare document</p>
								<div style={{"height":"3px"}}></div>
							</Grid>
							{dataValues.documentDetails.doc_status==="IN LUCRU" &&
								<Grid item xs={12} sm={2} md={2} lg={2} style={{"textAlign":"center","minHeight":"123px"}}>
									<span className="p-float-label">
										<Dropdown name="doc_status" id="doc_status" style={{"width":"100%","height":"36px"}} value={inputValues.doc_status} options={statusList} onChange={(evt) => handleChange(evt)} optionLabel="label"/>
										<label htmlFor="doc_status">Stare document</label>
									</span>
									<div style={{"height":"18px","clear":"all"}}></div>
									<Button type="button" label="Modifica stare doc." icon="pi pi-save" className="p-button-sm p-button-primary" style={{"width":"100%","marginTop":"-3px"}} onClick={(evt) => confirm(evt,"save_status",dataValues.documentDetails)} />
								</Grid>
							}
							{dataValues.documentDetails.doc_status!=="IN LUCRU" &&
								<Grid item xs={12} sm={2} md={2} lg={2} style={{"textAlign":"center","minHeight":"123px"}}>
									{!!canModifyDoc &&
										<React.Fragment>
											<span className="p-float-label">
												<Dropdown name="doc_status" id="doc_status" style={{"width":"100%","height":"36px"}} value={inputValues.doc_status} options={statusList} onChange={(evt) => handleChange(evt)} optionLabel="label" disabled={!canModifyDoc}/>
												<label htmlFor="doc_status">Stare document</label>
											</span>
											<div style={{"height":"18px","clear":"all"}}></div>
											<Button type="button" label="Modifica stare doc." icon="pi pi-save" className="p-button-sm p-button-primary" style={{"width":"100%","marginTop":"-3px"}} onClick={(evt) => confirm(evt,"save_status",dataValues.documentDetails)} />
										</React.Fragment>
									}
									{!!(!canModifyDoc) &&
										<p style={{"marginTop":"3px","fontWeight":"603"}}>Document {inputValues.doc_status}</p>
									}
								</Grid>
							}
							{inputValues.doc_status==="CLASAT" &&
								<React.Fragment>
									<Grid item xs={12} sm={12} md={8} lg={8}>
										<span className="p-float-label">
											<InputTextarea name="doc_ranked_resolution" id="doc_ranked_resolution" style={{"width":"100%","minHeight":"102px"}} value={inputValues.doc_ranked_resolution} onChange={(evt) => handleChange(evt)} rows={3}/>
											<label htmlFor="doc_ranked_resolution">Rezolutie clasare*</label>
										</span>
										<div style={{"height":"3px"}}></div>
									</Grid>
									<Grid item xs={12} sm={12} md={3} lg={2}>
										<span className="p-float-label">
											<Dropdown name="doc_expire" id="doc_expire" style={{"width":"100%","height":"36px"}} value={inputValues.doc_expire} options={expireList} onChange={(evt) => handleChange(evt)} optionLabel="label"/>
											<label htmlFor="doc_expire">Expira</label>
										</span>
										<div style={{"height":"12px"}}></div>
										{inputValues.doc_expire==="DA" &&
											<span className="p-float-label">
												<Dropdown name="doc_expire_period" id="doc_expire_period" style={{"width":"100%","height":"36px"}} value={inputValues.doc_expire_period} options={expirePeriodList} onChange={(evt) => handleChange(evt)} optionLabel="label"/>
												<label htmlFor="doc_expire_period">Perioada expirare</label>
											</span>
										}
									</Grid>
								</React.Fragment>
							}
						</React.Fragment>
					}
				</Grid>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				<ConfirmDialog />
			</BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default DocumentDetailsDetailsRelated;

