import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import { Card } from 'primereact/card';
import { BlockUI } from 'primereact/blockui';
import { Badge } from 'primereact/badge';
import DocumentDetailsDetailsRelated from "./DocumentDetailsDetailsRelated.js";
import AddFileToDocument from "./AddFileToDocument.js";
import DocumentDetailsComments from "./DocumentDetailsComments.js";
import DocumentDetailsHistory from "./DocumentDetailsHistory.js";
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let defaultInputValues={
	reason: "",
};

const DocumentDetailsRelated = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [section, setSection] = useState("document_details");
	const [messageText, setMessageText] = useState(null);
	const [blockScreen, setBlockScreen] = useState(false);
	const [changed, setChanged] = useState(0);
	const [commentsNo, setCommentsNo] = useState(0);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what) => {
		setSection(what);
	}

	const handleIncrementComments = (num) => {
		if(num>=0) {
			setCommentsNo(0);			
		}else{
			setCommentsNo(commentsNo+1);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}

		if(oper==="init_document") {
			if(!result.commentsNo) {
				setCommentsNo("O");
			}else{
				setCommentsNo(result.commentsNo);
			}
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="init_document";
			let url="getDocumentShortDetails";
			url+="?session_id="+props.session.sessId;
			let data={
				id: props.document.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login");
		}
	},[]);

	const getCurrentSection = () => {
		switch(section) {
			case "document_details":
				return(
					<DocumentDetailsDetailsRelated session={props.session} document={props.document} changeHandler={props.changeHandler}/>
				);
				break;
			case "document_files":
				return(
					<AddFileToDocument session={props.session} document={props.document}/>
				);
				break;
			case "document_comments":
				return(
					<DocumentDetailsComments session={props.session} document={props.document} incrementHandler={handleIncrementComments}/>
				);
				break;
			case "document_history":
				return(
					<DocumentDetailsHistory session={props.session} document={props.document}/>
				);
				break;
			default:
				return(
					<DocumentDetailsDetailsRelated session={props.session} document={props.document} changeHandler={props.changeHandler}/>
				);
		}
	}

	return(
		<BlockUI blocked={blockScreen} fullScreen>
			<Grid container spacing={1} style={{}}>
				<Grid item xs={2} sm={2} md={2} lg={2} style={{}}>
					<Grid container spacing={1} style={{}}>
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_details")}>Detalii document</p>
							</Card>
						</Grid>																						
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_files")}>Fisiere si formulare</p>
							</Card>
						</Grid>																						
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								{!!(!commentsNo) &&
									<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_comments")}>Comentarii</p>
								}
								{!!(commentsNo) &&
									<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_comments")}>Comentarii<Badge severity="danger" value={commentsNo} style={{"marginLeft":"12px"}}></Badge></p>
								}
							</Card>
						</Grid>																						
						<Grid item xs={12} sm={12} md={12} lg={12} style={{"padding":"12px"}}>
							<Card style={{"height":"57px","background":"#2196F3"}}>
								<p className="document_details" style={{"marginTop":"-14px"}} onClick={(evt) => handleButton(evt,"document_history")}>Istoric document</p>
							</Card>
						</Grid>																						
					</Grid>
				</Grid>
				<Grid item xs={10} sm={10} md={10} lg={10} style={{}}>
					{getCurrentSection()}
				</Grid>
			</Grid>
			{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
		</BlockUI>
	);
};

export default DocumentDetailsRelated;


