import React from "react";
import {useState} from "react";
import {Router,Switch,Route} from "react-router-dom";
import { locale, addLocale } from 'primereact/api';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./Sources/css/App.css";
import MainPage from "./Components/MainPage.js";
import LoginForm from "./Components/LoginForm";
import history from "./Sources/js/history.js";

const App = (props) => {

	const [session, setSession] = useState({});
	
	const loginHandler = (auth) => {
		setSession(auth.session);
		window.localStorage.removeItem("docSessIdXa7");
		window.localStorage.setItem("docSessIdXa7",auth.session.sessId);
		if(window.localStorage.getItem("docReturnPathXa7")) {

			history.push(window.localStorage.getItem("docReturnPathXa7"));
			window.localStorage.removeItem("docReturnPathXa7");
		}else{
			history.push("/");
		}
	}

	addLocale("ro", {
		firstDayOfWeek: 1,
		dayNames: ["duminica","luni","marti","miercuri","joi","vineri","sambata"],
		dayNamesShort: ["dum", "lun", "mar", "mie", "joi", "vin", "sam"],
		dayNamesMin: ["D", "L", "Ma", "Mi", "J", "V", "S"],
		monthNames: ["Ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"],
		monthNamesShort: ["ian", "feb", "mar", "apr", "mai", "iun", "iul", "aug", "sep", "oct", "nov", "dec"],
		today: "Astazi",
		clear: "Curata"
	});
	
	locale("ro");

	const logoutHandler = () => {
		window.localStorage.removeItem("docSessIdXa7");
		window.localStorage.removeItem("docReturnPathXa7");
		setSession({});
		history.push("/login");
	}

    return(
		<Router history={history}>
			<Switch>
				<Route exact path="/">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler}/>
				</Route>
				<Route exact path="/login">
					<LoginForm session={session} loginHandler={loginHandler}/>
				</Route>
				<Route exact path="/newDocument">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="newDocument"/>
				</Route>
				<Route exact path="/users">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="users"/>
				</Route>
				<Route exact path="/departments">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="departments"/>
				</Route>
				<Route exact path="/registers">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="registers"/>
				</Route>
				<Route exact path="/dashboard">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="dashboard"/>
				</Route>
				<Route exact path="/nDocumentsTypes">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="nDocumentsTypes"/>
				</Route>
				<Route path="/receivedDocuments">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="receivedDocuments"/>
				</Route>
				<Route path="/acceptedDocuments">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="acceptedDocuments"/>
				</Route>
				<Route path="/emittedDocuments">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="emittedDocuments"/>
				</Route>
				<Route path="/recentEvents">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="recentEvents"/>
				</Route>
				<Route exact path="/dashboard/categories">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="dashboard" subsection="categories"/>
				</Route>
				<Route exact path="/dashboard/search">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="dashboard" subsection="search"/>
				</Route>
				<Route exact path="/dashboard/expire">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="dashboard" subsection="expire"/>
				</Route>
				<Route exact path="/dashboard/expired">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="dashboard" subsection="expired"/>
				</Route>
				<Route path="/mainDocumentsRegister">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="mainDocumentsRegister"/>
				</Route>
				<Route exact path="/unitSettings">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="unitSettings"/>
				</Route>
				<Route exact path="/units">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="units"/>
				</Route>
				<Route path="/newDocument/entry">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="newDocument" step="entry"/>
				</Route>
				<Route path="/newDocument/intern">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="newDocument" step="intern"/>
				</Route>
				<Route path="/newDocument/exitFromOffice">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="newDocument" step="exitFromOffice"/>
				</Route>
				<Route path="/newDocument/exitRelated">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="newDocument" step="exitRelated"/>
				</Route>
				<Route exact path="/nFluxes">
					<MainPage session={session} loginHandler={loginHandler} logoutHandler={logoutHandler} section="nFluxes"/>
				</Route>
			</Switch>
		</Router>
	)
};

export default App;
