import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import { FileUpload } from 'primereact/fileupload';
import { InputText } from "primereact/inputtext";
import { Tag } from 'primereact/tag';
import { Button } from "primereact/button";
import { Paginator } from 'primereact/paginator';
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import {confirmDialog} from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { BlockUI } from 'primereact/blockui';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import HolidayRequest from "./HolidayRequest.js";
import NeedReport from "./NeedReport.js";
import {fetch_url_post,fetch_download} from "../../Sources/js/app.js";
import {get_api_path} from "../../Sources/js/paths.js";

let oper="";

let documents_types=[
    {
        name: "Cerere concediu",
        value: 1,
    },
    {
        name: "Referat de necesitate",
        value: 2,
    },
    {
        name: "Formular 2",
        value: 3,
    },
];

let defaultInputValues={
	search_form:  "",
    search_file: "",
    file_description: "",
};

let defaultDataValues={
	filesList: [],
};

let defaultDialogValues={
	openAddFile: false,
	titleAddFile: "",
	openFileDescription: false,
	titleFileDescription: "Descriere fisier",
};

let forms_rows=6;
let files_rows=3;
var currentFileId="";

const AddFileToDocument = (props) => {

	var timerId=null;

    const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
    const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
    const [section, setSection] = useState(0);
    const [formsFirst, setFormsFirst] = useState(0);
	const [filteredDocumentsTypes, setFilteredDocumentsTypes] = useState(documents_types.filter((document) => ((document.value>=formsFirst)&&(document.value<=formsFirst+forms_rows))));
	const [formsListLength, setFormListLength] = useState(documents_types.length);
    const [filesFirst, setFilesFirst] = useState(0);
	const [filteredFilesList, setFilteredFilesList] = useState([]);
	const [filesListLength, setFilesListLength] = useState(0);
    const [blockScreen, setBlockScreen] = useState(false);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
        if(name==="search_form") {
            //first filter according to search field
            let tmp=documents_types.filter((document)=>((document.name.toLowerCase().indexOf(value.toLowerCase())>-1))||(value===""));
            //next we will paginate the resulted array
            let tmp_paginated=[];
            let last=(forms_rows>tmp.length?tmp.length:forms_rows);
            for(var i=0;i<last;i++) {
                tmp_paginated.push(tmp[i]);
            }
            //last we will update filteredDocumentsTypes
            setFilteredDocumentsTypes(tmp_paginated);
            setFormListLength(tmp.length);
        }
        if(name==="search_file") {
            //first filter according to search field
            let tmp=dataValues.filesList.filter((file)=>((file.filename.toLowerCase().indexOf(value.toLowerCase())>-1))||(value===""));
            //next we will paginate the resulted array
            let tmp_paginated=[];
            let last=(files_rows>tmp.length?tmp.length:files_rows);
            for(var i=0;i<last;i++) {
                tmp_paginated.push(tmp[i]);
            }
            //last we will update filteredDocumentsTypes
            setFilteredFilesList(tmp_paginated);
            setFilesListLength(tmp.length);
        }
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add_file") {
			setDialogValues({...dialogValues,openAddFile: true,titleAddFile: "Incarca fisier"});
		}
		if(what==="download") {
			let url="downloadFile";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
                doc_id: props.document.id,
			};
			fetch_download(url,data,elementData.filename,success_handler);
		}
		if(what==="description") {
            currentFileId=elementData.id;
            setInputValues({...inputValues,file_description:elementData.description});            
            setDialogValues({...dialogValues,openFileDescription: true});
		}
		if(what==="save_description") {
			let url="saveFileDescription";
			url+="?session_id="+props.session.sessId;
			oper="save_description";
			let data={
				id: currentFileId,
                description: inputValues.file_description,
                doc_id: props.document.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="delete") {
			let url="deleteFile";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
                doc_id: props.document.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
        if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get_document_files")||(oper==="delete")) {
            let tmp=[];
            if(result.data) {
                for(var i=0;i<files_rows;i++) {
                    if(result.data[i]) {
                        tmp.push(result.data[i]);
                    }
                }
                setDataValues({...dataValues,filesList:result.data});
                setFilteredFilesList(tmp);
                setFilesListLength(result.data.length);
                setDialogValues({...dialogValues,openAddFile: false,titleAddFile: ""});
            }
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="save_description") {
            //modify file description in dataValues
            let tmp=dataValues.filesList;
            let index=tmp.findIndex((element)=>element.id===currentFileId);
            tmp[index].description=inputValues.file_description;
            setDataValues({...dataValues,filesList:tmp});
            setDialogValues({...dialogValues,openFileDescription: false});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
        if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
        setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get_document_files";
			let url="getDocumentFiles";
			url+="?session_id="+props.session.sessId;
            let data={
                doc_id: props.document.id,
            };
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	useEffect(() => {
		if(props.session.sessId) {
            if(section===0) {
                oper="get_document_files";
                let url="getDocumentFiles";
                url+="?session_id="+props.session.sessId;
                let data={
                    doc_id: props.document.id,
                };
                fetch_url_post(url,data,success_handler,fail_handler);
            }
		}else{
			history.push("/login")
		}
	},[section]);

	const getAddFormSection = () => {
		switch(section) {
			case 1:
                return(
                    <HolidayRequest session={props.session} document={props.document} resetSectionHandler={setSection}/>
                );
				break;
            case 2:
                return(
                    <NeedReport session={props.session} document={props.document} resetSectionHandler={setSection}/>
                );
				break;
            case 3:
                return(
                    <p>Formular 2</p>
                );
				break;
			default:
                return(
                    <p>Fisiere</p>
                )
				break;
		}
	}

    const onUpload = () => {
        setMessageText("Fisier(ele) au fost incarcate cu succes!");
        oper="get_document_files";
        let url="getDocumentFiles";
        url+="?session_id="+props.session.sessId;
        let data={
            doc_id: props.document.id,
        };
        timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
    }

    const onBeforeUpload = (evt) => {
        evt.formData.append("doc_id",props.document.id);
    }

    const chooseOptions = {label: "Cauta", className: "p-button-sm p-button-primary"};
    const uploadOptions = {label: "Incarca", className: "p-button-sm p-button-primary"};
    const cancelOptions = {label: "Sterge", className: "p-button-sm p-button-danger"};

    const uploadItemTemplate = (file, props) => {
        return (
            <div style={{"width":"100%","marginBottom":"3px"}}>
				<div style={{"width":"90%","float":"left"}}>
                	<Tag value={file.name} severity="warning" style={{"width":"100%","textAlign":"left","marginTop":"3px"}} />
				</div>
				<div style={{"width":"9%","float":"right","textAlign":"center"}}>
                	<Button type="button" icon="pi pi-times" className="p-button-sm p-button-danger" onClick={() => onTemplateRemove(file, props.onRemove)} />
				</div>
            </div>
        )
    }

    const onTemplateRemove = (file, callback) => {
        callback();
    }

    const onFormsPageChange = (evt) => {
        setFormsFirst(evt.first);
        //first filter according to search field
        let tmp=documents_types.filter((document)=>((document.name.toLowerCase().indexOf(inputValues.search_form.toLowerCase())>-1))||(inputValues.search_form===""));
        //next we will paginate the resulted array
        let tmp_paginated=[];
        let last=(evt.first+forms_rows>tmp.length?tmp.length:evt.first+forms_rows);
        for(var i=evt.first;i<last;i++) {
            tmp_paginated.push(tmp[i]);
        }
        //last we will update filteredDocumentsTypes
        setFilteredDocumentsTypes(tmp_paginated);
    }

    const onFilesPageChange = (evt) => {
        setFilesFirst(evt.first);
        //first filter according to search field
        let tmp=dataValues.filesList.filter((file)=>((file.filename.toLowerCase().indexOf(inputValues.search_file.toLowerCase())>-1))||(inputValues.search_file===""));
        //next we will paginate the resulted array
        let tmp_paginated=[];
        let last=(evt.first+files_rows>tmp.length?tmp.length:evt.first+files_rows);
        for(var i=evt.first;i<last;i++) {
            tmp_paginated.push(tmp[i]);
        }
        //last we will update filteredDocumentsTypes
        setFilteredFilesList(tmp_paginated);
    }

	const confirm = (evt,what,elementData) => {
		if(what==="delete") {
            confirmDialog({
				message: "Sigur doriti stergerea? Operatia este ireversibila!",
				header: "Confirmare stergere",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Da",
				rejectLabel: "Nu",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	const addFileDialog = () => {
        let base_url=get_api_path();
        let url=base_url+"uploadFile?session_id="+props.session.sessId;
        return(
			<Dialog header={dialogValues.titleAddFile} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddFile:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}} contentStyle={{"minHeight":"300px"}}>
                <FileUpload name="new_file" url={url} onUpload={onUpload} onBeforeUpload={onBeforeUpload} multiple accept="image/*|.pdf" maxFileSize={10000000}
                    emptyTemplate={<p style={{"width":"100%","textAlign":"center","margin":"0px"}}>Trageti fisierul(ele) aici pentru incarcare sau apasati butonul cauta.</p>}
                    chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} itemTemplate={uploadItemTemplate} />
			</Dialog>
		);
	}

	const fileDescriptionDialog = () => {
        return(
			<Dialog header={dialogValues.titleFileDescription} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openFileDescription:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}} contentStyle={{}}>
                <span className="p-float-label">
                    <InputTextarea name="file_description" id="file_description" style={{"width":"100%","minHeight":"141px"}} value={inputValues.file_description} onChange={(evt) => handleChange(evt)} rows={6}/>
                    <label htmlFor="file_description">Descriere fisier</label>
                </span>
                <div style={{"width":"100%","textAlign":"right"}}>
                    <Button type="button" label="Salveaza descriere" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"save_description",null)} />					
                    <Button type="button" label="Inchide fereastra" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{"marginRight":"12px"}} onClick={(evt) => setDialogValues({...dialogValues,openFileDescription:false})} />					
                </div>
			</Dialog>
		);
	}

    if(dataOk) {
        return(
			<BlockUI blocked={blockScreen} fullScreen>
                {section===0 &&
                    <div style={{}}>
                        <p style={{"fontSize":"18px","fontWeight":"600","textAlign":"center","color":"#FF0000","width":"100%","marginTop":"9px","marginBottom":"9px"}}>Adauga formular</p>
                        <div style={{"width":"100%","textAlign":"right","marginBottom":"12px"}}>
                            <InputText name="search_form" id="search_form" style={{"width":"30%"}} value={inputValues.search_form} onChange={(evt) => handleChange(evt)} placeholder="Cautare formular..."/>
                        </div>
                        {filteredDocumentsTypes.length>0 &&
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="flex-start" style={{"minHeight":"150px"}}>
                                    {filteredDocumentsTypes.map((document) =>
                                        <Grid item xs={12} sm={12} md={6} lg={4} key={document.value}>
                                            <Paper className="add_form_to_document_paper" style={{}} onClick={(evt) => setSection(document.value)}>
                                                <p className="new_document_selection" style={{"marginTop":"9px"}}>{document.name}</p>
                                            </Paper>
                                        </Grid>
                                    )}
                                </Grid>
                                <Paginator first={formsFirst} rows={forms_rows} totalRecords={formsListLength} onPageChange={onFormsPageChange}></Paginator>
                            </React.Fragment>
                        }
                        {filteredDocumentsTypes.length===0 &&
                            <div style={{"height":"113px"}}>
                                <p style={{"width":"100%","marginTop":"84px","fontWeight":"600","color":"#FF0000","textAlign":"center"}}>Nu exista formulare conform cautarii.</p>
                            </div>
                        }
                        <p style={{"fontSize":"18px","fontWeight":"600","textAlign":"center","color":"#FF0000","width":"100%","marginTop":"24px","marginBottom":"12px"}}>Fisiere incarcate in sistem</p>
                        <div style={{"width":"48%","textAlign":"left","marginBottom":"6px","float":"left"}}>
                            <Button type="button" label="Incarca alt fisier" icon="pi pi-plus" className="p-button-sm p-button-warning" style={{}} onClick={(evt) => handleButton(evt,"add_file",null)}/>					
                            {dataValues.filesList.length>0 &&
                                <Button type="button" label="Descarca arhiva" icon="pi pi-server" className="p-button-sm p-button-primary" style={{"marginLeft":"12px"}} onClick={(evt) => handleButton(evt,"download_archive",null)}/>					
                            }
                        </div>
                        <div style={{"width":"48%","textAlign":"right","marginBottom":"6px","float":"right"}}>
                            <InputText name="search_file" id="search_file" style={{"width":"63%"}} value={inputValues.search_file} onChange={(evt) => handleChange(evt)} placeholder="Cautare fisier..."/>
                        </div>
                        {filteredFilesList.length>0 &&
                            <React.Fragment>
                                <Grid container spacing={1} style={{"marginBottom":"27px"}}>                
                                    {filteredFilesList.map((file) =>
                                        <Grid item xs={12} sm={12} md={6} lg={4} key={file.id}>
                                            <Paper className="add_file_to_document_paper" style={{}}>
                                                <p className="add_file_to_document_text" style={{"marginTop":"9px","fontWeight":"600","textAlign":"center"}}>{file.filename}</p>
                                                <p className="add_file_to_document_text" style={{"textAlign":"left","paddingLeft":"30px","marginTop":"0px","marginBottom":"3px"}}> Incarcat de {file.user_name}</p>
                                                <p className="add_file_to_document_text" style={{"textAlign":"left","paddingLeft":"30px","marginTop":"0px"}}>La data de {file.reg_date_parsed}</p>
                                                <div style={{"width":"100%","textAlign":"center"}}>
                                                    <Button type="button" label="Descarca" icon="pi pi-download" className="p-button-sm p-button-warning" style={{}} onClick={(evt) => handleButton(evt,"download",file)} />					
                                                    <Button type="button" label="Detalii" icon="pi pi-align-justify" className="p-button-sm p-button-primary" style={{"marginLeft":"12px"}} onClick={(evt) => handleButton(evt,"description",file)} />					
                                                    <Button type="button" label="Sterge" icon="pi pi-trash" className="p-button-sm p-button-danger" style={{"marginLeft":"12px"}} onClick={(evt) => confirm(evt,"delete",file)} />					
                                                </div>
                                            </Paper>
                                        </Grid>
                                    )}
                                </Grid>
                                <Paginator first={filesFirst} rows={files_rows} totalRecords={filesListLength} onPageChange={onFilesPageChange}></Paginator>
                            </React.Fragment>
                        }
                        {filteredFilesList.length===0 &&
                            <React.Fragment>
                                <div style={{"height":"141px"}}>
                                    <p style={{"width":"100%","marginTop":"87px","fontWeight":"600","color":"#FF0000","textAlign":"center"}}>Nu exista fisiere conform cautarii.</p>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                }
                {section>0 &&
                    getAddFormSection()
                }
                {messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
                {dialogValues.openAddFile && addFileDialog()}
                {dialogValues.openFileDescription && fileDescriptionDialog()}
				<ConfirmDialog />
            </BlockUI>
        );            
    }else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default AddFileToDocument;
