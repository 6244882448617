import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Box} from "@mui/system";

const NewDocumentSelect = (props) => {

	if(!props.session.sessId) {
		return(null);
	}

	if(props.step==="select") {
		return(
				<React.Fragment>
					<Grid container spacing={1} style={{"paddingTop":"18px"}}>
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<Paper style={{"height":"150px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => props.handleChangeType(evt,"Intrare")}>
								<p className="new_document_selection">Intrare</p>
							</Paper>
						</Grid>
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<Paper style={{"height":"150px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => props.handleChangeType(evt,"Intern")}>
								<p className="new_document_selection">Intern</p>
							</Paper>
						</Grid>
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<Paper style={{"height":"150px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => props.handleChangeType(evt,"Iesire relationata")}>
								<p className="new_document_selection">Iesire relationata</p>
							</Paper>
						</Grid>
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<Paper style={{"height":"150px","background":"#2196F3","border":"1px solid #2196F3"}} onClick={(evt) => props.handleChangeType(evt,"Iesire din oficiu")}>
								<p className="new_document_selection">Iesire din oficiu</p>
							</Paper>
						</Grid>
					</Grid>
				</React.Fragment>
		)
	}else{
		return(
				<React.Fragment>
					<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
						<Grid container spacing={1} style={{"paddingTop":"18px"}}>
							<Grid item xs={2} sm={3} md={3} lg={4}>
								&nbsp;
							</Grid>
							<Grid item xs={5} sm={4} md={3} lg={2}>
								<Paper style={{"height":"63px","border":"1px solid #2196F3","marginTop":"-75px"}}>
									<p className="new_document_selection_none" style={{"marginTop":"12px","fontSize":"1.2rem"}}>Tip document<br/>{props.step}</p>
								</Paper>
							</Grid>
							<Grid item xs={5} sm={4} md={3} lg={3}>
								<Paper style={{"height":"63px","background":"#689F38","border":"1px solid #689F38","marginTop":"-75px"}} onClick={(evt) => props.handleSetSave(evt)}>
									<p className="new_document_selection" style={{"marginTop":"18px"}}>Salveaza</p>
								</Paper>
							</Grid>
							<Grid item xs={5} sm={4} md={3} lg={3}>
								<Paper style={{"height":"63px","background":"#D32F1F","border":"1px solid #D32F1F","marginTop":"-75px"}} onClick={(evt) => props.handleChangeType(evt,"select")}>
									<p className="new_document_selection" style={{"marginTop":"18px"}}>Iesire</p>
								</Paper>
							</Grid>
						</Grid>
					</Box>
					<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
						<Grid container spacing={1} style={{"marginTop":"-18px","marginBottom":"27px"}}>
							<Grid item xs={4} sm={4} md={4} lg={4}>
								<Paper style={{"height":"63px","border":"1px solid #2196F3"}}>
									<p className="new_document_selection_none" style={{"marginTop":"12px","fontSize":"1rem"}}>Tip document<br/>{props.step}</p>
								</Paper>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4}>
								<Paper style={{"height":"63px","background":"#689F38","border":"1px solid #689F38"}} onClick={(evt) => props.handleSetSave(evt)}>
									<p className="new_document_selection" style={{"marginTop":"18px","fontSize":"1rem"}}>Salveaza</p>
								</Paper>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4}>
								<Paper style={{"height":"63px","background":"#D32F1F","border":"1px solid #D32F1F"}} onClick={(evt) => props.handleChangeType(evt,"select")}>
									<p className="new_document_selection" style={{"marginTop":"18px","fontSize":"1rem"}}>Iesire</p>
								</Paper>
							</Grid>
						</Grid>
					</Box>
				</React.Fragment>
		)
	}
};

export default NewDocumentSelect;
