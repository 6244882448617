import React from "react";
import {useState} from "react";
import Container from "@mui/material/Container";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import Message from "./Helpers/Message.js";
import logo from "../Sources/images/doc.jpg";
import {fetch_url_post,Crypt_pass} from "../Sources/js/app.js";

const defaultInputValues={
	utl: "",
	ps: "",
}

let oper="";

const LoginForm = (props) => {

	const [inputValues, setInputValues] = React.useState(defaultInputValues);
	const [messageText, setMessageText] = useState(null);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what) => {
		if(what==="login") {
			if((inputValues.utl.length<3)||(inputValues.ps.length<3)) {
				setMessageText("Completati toate campurile!");
				return false;
			}
			oper="login";
			let data={
				utl: inputValues.utl,
				mdp: Crypt_pass(inputValues.utl,inputValues.ps),
			};
			fetch_url_post("login",data,success_handler,fail_handler);
		}
		if(what==="reset") {
			setInputValues(defaultInputValues);
		}
	}

	const success_handler=(result)=> {
		if(result.ok<1) {
			setMessageText("EROARE: "+result.error);	
		}
		if(result.message) {
			setMessageText(result.message);
		}
		if(oper==="login") {
			if(result.is_auth===1) {
				window.localStorage.setItem("documents_commented",0);
				window.localStorage.setItem("documents_accepted",0);
				window.localStorage.setItem("documents_refused",0);
				window.localStorage.setItem("documents_closed",0);
				props.loginHandler(result);
			}else{
				setMessageText("Autentificare esuata!");
			}
			oper="";
		}
	}

	const fail_handler=()=>{
		setMessageText("Eroare de conexiune.");
		return false;			
	}

    const header = (
        <img alt="Card" src={logo} onError={(e) => e.target.src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"}/>
    );
	
    const footer = (
        <div style={{"textAlign":"right"}}>
            <Button label="Autentificare" icon="pi pi-unlock" className="p-button-sm" style={{"marginRight":"6px","width":"126px"}} onClick={(evt) => handleButton(evt,"login")}/>
            <Button label="Reseteaza" icon="pi pi-times" className="p-button-secondary p-ml-2 p-button-sm" style={{"width":"126px"}} onClick={(evt) => handleButton(evt,"reset")}/>
        </div>
    );

	return(
		<Container maxWidth="sm" style={{"marginTop":"3%"}}>
			<Card title="Manager documente" footer={footer} header={header} style={{"width":"100%","margin":"0 auto"}}>
				<div style={{}}>
				</div>
				<span className="p-float-label">
					<InputText name="utl" id="utl" style={{"width":"100%"}} value={inputValues.utl} onChange={(evt) => handleChange(evt)}/>
					<label htmlFor="utl">Utilizator</label>
				</span>
				<div style={{"height":"12px"}}>
				</div>
				<span className="p-float-label">
					<Password name="ps" id="ps" style={{"width":"100%"}} inputStyle={{"width":"100%"}} value={inputValues.ps} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
					<label htmlFor="ps">Parola</label>
				</span>
			</Card>
			{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
		</Container>
	);
};

export default LoginForm;
