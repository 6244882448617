import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import {Box} from "@mui/system";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Checkbox} from "primereact/checkbox";
import {confirmDialog} from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { BlockUI } from 'primereact/blockui';
import { TabView, TabPanel } from 'primereact/tabview';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";

let defaultInputValues={
	id: null,
	user_name: "",
	password: "",
	confirm_password: "",
	name: "",
	department_id: "0",
	role: "",
	function: "",
	observations: "",
	email: "",
	active: 1,
	allowed_sections: [],
	can_modify: 0,
	can_close_register: 0,
	can_rank: 0,
	can_delete: 0,
	can_set_urgent: 0,
};

let defaultDataValues={
	usersList: [],
	departmentsList: [],
	usersMenuList: [],
};

let defaultSelectedElements={
	usersTable: null,
};

let defaultDialogValues={
	openAddEdit: false,
	titleAddEdit: "",
	openChangePassword: false,
	titleChangePassword: "Schimbare parola",
	openSections: false,
	titleSections: "",
};

let userRolesList=[
	{label: "Sef unitate",value: "unit_admin"},
	{label: "Sef departament",value: "dept_admin"},
	{label: "Utilizator",value: "utilizator"},
];

let activeList=[
	{label: "DA",value: 1},
	{label: "NU",value: 0},
];

let defaultInputError={
	disableSave: false,
	errorText: "",
};

let currentUser={};
let currentUserId=0;
let currentUserName="";

const Users = (props) => {

	var timerId=null;
	defaultInputValues.id=(props.userId?props.userId:null);

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [selectedElements, setSelectedElements] = useState(defaultSelectedElements);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [inputError, setInputError] = useState(defaultInputError);	
	const [blockScreen, setBlockScreen] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

	const handleChange = evt => {
		const {name, value} = evt.target;
		if(name==="user_name") {
			let found=false;
			dataValues.usersList.forEach(element => {
				if(value.toUpperCase()===element.user_name.toUpperCase()) {
					found=true;
				}
			});
			let tmp={
				disableSave: found,
				errorText: (found===true?"Utilizatorul exista deja!":""),
			}
			setInputError(tmp);
		}
		setInputValues({...inputValues,[name]:value});
	}

	const handleChecked = (evt,menu_id) => {
        let allowed_sections = [...inputValues.allowed_sections];
		if(evt.checked) {
            allowed_sections.push(menu_id);
        }else{
            allowed_sections.splice(allowed_sections.indexOf(menu_id),1);
		}
		setInputValues({...inputValues,allowed_sections:allowed_sections});
	}	
	
	const handleCheckedSpecial = (evt) => {
		const {name, checked} = evt.target;
		if(checked) {
			setInputValues({...inputValues,[name]:1});
		}else{
			setInputValues({...inputValues,[name]:0});
		}
	}	

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Adaugare utilizator"});
			setInputError(defaultInputError);
		}
		if(what==="edit") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Editare utilizator "+elementData.name});
			setInputError(defaultInputError);
		}
		if(what==="password") {
			setInputValues({...inputValues,id: elementData.id,password: "",confirm_password: ""});
			setDialogValues({...dialogValues,openChangePassword: true});
		}
		if(what==="delete") {
			let url="deleteUser";
			url+="?session_id="+props.session.sessId;
			oper="delete";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if((what==="save")||(what==="save_rights")) {
			if(inputValues.user_name==="") {
				setMessageText("Eroare: Completati campul Nume de utilizator!");				
				return false;
			}
			if(inputValues.name==="") {
				setMessageText("Eroare: Completati campul Nume si prenume!");				
				return false;
			}
			if(inputValues.role==="") {
				setMessageText("Eroare: Completati campul Tip utilizator!");				
				return false;
			}
			if(inputValues.email!=="") {
				if((inputValues.email.indexOf("@")===-1)||(inputValues.email.indexOf(".")===-1)) {
					setMessageText("Eroare: Introduceti o adresa de email valida!");				
					return false;
				}
			}
			if(inputValues.password==="") {
				setMessageText("Eroare: Completati campul Parola!");				
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Eroare: Completati campul Confirmare parola!");				
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Eroare: Parolele introduse nu sunt identice!");				
				return false;
			}
			let url="saveUser";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data=inputValues;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save_password") {
			if(inputValues.password==="") {
				setMessageText("Eroare: Completati campul Parola!");				
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Eroare: Completati campul Confirmare parola!");				
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Eroare: Parolele introduse nu sunt identice!");				
				return false;
			}
			let url="saveUserPassword";
			url+="?session_id="+props.session.sessId;
			oper="save_password";
			let data=inputValues;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="sections") {
			setInputValues({...inputValues,elementData});
			currentUser=elementData;
			currentUserId=elementData.id;
			currentUserName=elementData.name;
			let url="getUserMenuList";
			url+="?session_id="+props.session.sessId;
			oper="get_user_menu_list";
			let data={
				id: elementData.id,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
		if(what==="save_sections") {
			let url="saveUserMenuList";
			url+="?session_id="+props.session.sessId;
			oper="save_user_menu_list";
			let data={
				id: currentUserId,
				menu_sections: inputValues.allowed_sections,
			};
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
			currentUserId=0;
			currentUserName="";
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
			return false;
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get")||(oper==="save")||(oper==="delete")||(oper==="save_rights")) {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,usersList:result.usersList,departmentsList:result.departmentsList});
			defaultInputValues.department_id=(result.departmentsList.length===1?result.departmentsList[0].id:"0");
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="save_password") {
			setDialogValues(defaultDialogValues);
			setMessageText("Parola a fost schimbata cu succes!");
			oper="";
		}
		if(oper==="get_user_menu_list") {
			setDataValues({...dataValues,usersMenuList:result.data});
			let allowed_sections=[];
			result.data.forEach(element=>{
				if(element.allowed===1) {
					allowed_sections.push(element.id);
				}
			});
			currentUser.allowed_sections=allowed_sections;
			setInputValues(currentUser);
			setDialogValues({...dialogValues,openSections: true,titleSections: "Sectiuni utilizator "+currentUserName});
			oper="";
		}
		if(oper==="save_user_menu_list") {
			setInputValues({...inputValues,allowed_sections:[]});			
			setDialogValues({...dialogValues,openSections: false,titleSections: ""});
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getUsers";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	const addEditDialog = () => {
		if(inputValues.id) {
			return(
                <Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} breakpoints={{"960px":"75vw"}} style={{"width":"57vw"}}>
					<Grid container spacing={1} style={{"paddingTop":"24px"}}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<span className="p-float-label">
								<InputText name="user_name" id="user_name" style={{"width":"100%"}} value={inputValues.user_name} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="user_name">Utilizator</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<span className="p-float-label">
								<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="name">Nume si prenume</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<span className="p-float-label">
								<InputText name="function" id="function" style={{"width":"100%"}} value={inputValues.function} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="function">Functie</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<span className="p-float-label">
								<InputText name="email" id="email" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="email">Adresa email</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} style={{"marginTop":"6px"}}>
							<span className="p-float-label">
								<Dropdown name="department_id" id="department_id" style={{"width":"100%","height":"36px"}} options={dataValues.departmentsList} optionLabel="name" optionValue="id" value={inputValues.department_id} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="department_id">Departament</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4} style={{"marginTop":"6px"}}>
							<span className="p-float-label">
								<Dropdown name="role" id="role" style={{"width":"100%","height":"36px"}} options={userRolesList} value={inputValues.role} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="role">Tip utilizator</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={2} style={{"marginTop":"6px"}}>
							<span className="p-float-label">
								<Dropdown name="active" id="active" style={{"width":"100%","height":"36px"}} options={activeList} value={inputValues.active} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="active">Activ</label>
							</span>
						</Grid>						
						<Grid item xs={7} style={{"textAlign":"left"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"9px"}}>
								{inputError.errorText}
							</div>
						</Grid>
						<Grid item xs={5} style={{"textAlign":"right"}}>
							<React.Fragment>
								<Button label="Salveaza" icon="pi pi-check-circle" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")} disabled={inputError.disableSave}/>
								<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
							</React.Fragment>
						</Grid>
					</Grid>
				</Dialog>
			);
		}else{
			return(
                <Dialog header={dialogValues.titleAddEdit} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} breakpoints={{"960px":"75vw"}} style={{"width":"57vw"}}>
					<Grid container spacing={1} style={{"paddingTop":"24px"}}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<span className="p-float-label">
								<InputText name="user_name" id="user_name" style={{"width":"100%"}} value={inputValues.user_name} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="user_name">Utilizator</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<span className="p-float-label">
								<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="name">Nume si prenume</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<span className="p-float-label">
								<InputText name="function" id="function" style={{"width":"100%"}} value={inputValues.function} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="function">Functie</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<span className="p-float-label">
								<InputText name="email" id="email" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="email">Adresa email</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} style={{"marginTop":"6px"}}>
							<span className="p-float-label">
								<Dropdown name="department_id" id="department_id" style={{"width":"100%","height":"36px"}} options={dataValues.departmentsList} optionLabel="name" optionValue="id" value={inputValues.department_id} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="department_id">Departament</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4} style={{"marginTop":"6px"}}>
							<span className="p-float-label">
								<Dropdown name="role" id="role" style={{"width":"100%","height":"36px"}} options={userRolesList} value={inputValues.role} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="role">Tip utilizator</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={2} style={{"marginTop":"6px"}}>
							<span className="p-float-label">
								<Dropdown name="active" id="active" style={{"width":"100%","height":"36px"}} options={activeList} value={inputValues.active} onChange={(evt) => handleChange(evt)}/>
								<label htmlFor="active">Activ</label>
							</span>
						</Grid>						
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<span className="p-float-label">
								<Password name="password" id="password" style={{"width":"100%"}} value={inputValues.password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
								<label htmlFor="password">Parola</label>
							</span>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<span className="p-float-label">
								<Password name="confirm_password" id="confirm_password" style={{"width":"100%"}} value={inputValues.confirm_password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
								<label htmlFor="confirm_password">Confirmare parola</label>
							</span>
						</Grid>
						<Grid item xs={7} style={{"textAlign":"left"}}>
							<div style={{"color":"#FF0000","fontWeight":"600","paddingTop":"9px"}}>
								{inputError.errorText}
							</div>
						</Grid>
						<Grid item xs={5} style={{"textAlign":"right"}}>
							<React.Fragment>
								<Button label="Salveaza" icon="pi pi-check-circle" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save")} disabled={inputError.disableSave}/>
								<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}/>
							</React.Fragment>
						</Grid>
					</Grid>
				</Dialog>
			);
		}
	}

	const changePasswordDialog = () => {
		return(
			<Dialog header={dialogValues.titleChangePassword} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openChangePassword:false})} breakpoints={{"960px":"75vw"}} style={{"width":"50vw"}}>
				<Grid container spacing={1} style={{"paddingTop":"18px"}}>
					<Grid item xs={12}>
						<span className="p-float-label">
							<Password id="password" name="password" style={{"width":"100%"}} value={inputValues.password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
							<label htmlFor="password">Parola</label>
						</span>
					</Grid>
					<Grid item xs={12}>
						<span className="p-float-label">
							<Password id="confirm_password" name="confirm_password" style={{"width":"100%"}} value={inputValues.confirm_password} onChange={(evt) => handleChange(evt)} feedback={false} toggleMask/>
							<label htmlFor="confirm_password">Confirmare parola</label>
						</span>
					</Grid>
					<Grid item xs={12} style={{"textAlign":"right"}}>
						<Button label="Salveaza" icon="pi pi-check-circle" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_password")}/>
						<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>setDialogValues({...dialogValues,openChangePassword:false})}/>
					</Grid>
				</Grid>
			</Dialog>
		);
	}

	const sectionsDialog = () => {
		return(
			<Dialog header={dialogValues.titleSections} visible={true} onHide={(evt)=>setDialogValues({...dialogValues,openSections:false})} breakpoints={{"960px":"75vw"}} style={{"width":"48vw"}} contentStyle={{"minHeight":"507px","paddingBottom":"15px"}}>
				<TabView activeIndex={activeIndex} className="tabview-header-icon" onTabChange={(e) => setActiveIndex(e.index)}>
					<TabPanel leftIcon="pi pi-key" header="Sectiuni">
						<Grid container spacing={1} style={{"paddingTop":"18px"}}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<p style={{"textAlign":"center","fontWeight":"600"}}>Drepturi sectiuni aplicatie</p>
							</Grid>
							{dataValues.usersMenuList && dataValues.usersMenuList.map((menu) => 
								<Grid item xs={12} sm={12} md={12} lg={12} key={menu.id}>
									<Paper>
										{inputValues.allowed_sections.indexOf(menu.id)===-1 &&
											<p style={{"float":"left","marginLeft":"48px","marginTop":"0px"}}>{menu.name}</p>
										}
										{inputValues.allowed_sections.indexOf(menu.id)>-1 &&
											<p style={{"float":"left","marginLeft":"48px","marginTop":"0px"}}><b>{menu.name}</b></p>
										}
										<p style={{"float":"right","textAlign":"center","marginRight":"48px","marginTop":"0px"}}>
											<Checkbox name="sections" id="sections" onChange={(evt) => handleChecked(evt,menu.id)} checked={(inputValues.allowed_sections.indexOf(menu.id)>-1?true:false)}></Checkbox>
										</p>
									</Paper>
								</Grid>
							)}
							<Grid item xs={12} style={{"textAlign":"right","marginTop":"30px"}}>
								<Button label="Salveaza" icon="pi pi-check" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_sections")}/>
								<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>setDialogValues({...dialogValues,openSections:false})}/>
							</Grid>
						</Grid>
					</TabPanel>
					<TabPanel leftIcon="pi pi-calendar" header="Drepturi speciale">
						<Grid container spacing={1} style={{"paddingTop":"18px"}}>
							<Grid item xs={12} sm={12} md={12} lg={12} style={{"marginTop":"30px"}}>
								<p style={{"textAlign":"center","fontWeight":"600"}}>Drepturi speciale</p>
							</Grid>
							<Grid item xs={9} sm={9} md={9} lg={9}>
								{inputValues.can_modify==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}>Poate modifica documente</p>
								}
								{inputValues.can_modify!==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}><b>Poate modifica documente</b></p>
								}
							</Grid>								
							<Grid item xs={3} sm={3} md={3} lg={3}>
								<p style={{"textAlign":"center","marginTop":"0px"}}>
									<Checkbox name="can_modify" id="can_modify" onChange={(evt) => handleCheckedSpecial(evt)} checked={(inputValues.can_modify==1?true:false)}></Checkbox>
								</p>
							</Grid>								
							<Grid item xs={9} sm={9} md={9} lg={9}>
								{inputValues.can_close_register==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}>Poate inchide registrul</p>
								}
								{inputValues.can_close_register!==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}><b>Poate inchide registrul</b></p>
								}
							</Grid>								
							<Grid item xs={3} sm={3} md={3} lg={3}>
								<p style={{"textAlign":"center","marginTop":"0px"}}>
									<Checkbox name="can_close_register" id="can_close_register" onChange={(evt) => handleCheckedSpecial(evt)} checked={(inputValues.can_close_register==1?true:false)}></Checkbox>
								</p>
							</Grid>								
							<Grid item xs={9} sm={9} md={9} lg={9}>
								{inputValues.can_rank==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}>Poate clasa documente</p>
								}
								{inputValues.can_rank!==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}><b>Poate clasa documente</b></p>
								}
							</Grid>								
							<Grid item xs={3} sm={3} md={3} lg={3}>
								<p style={{"textAlign":"center","marginTop":"0px"}}>
									<Checkbox name="can_rank" id="can_rank" onChange={(evt) => handleCheckedSpecial(evt)} checked={(inputValues.can_rank==1?true:false)}></Checkbox>
								</p>
							</Grid>								
							<Grid item xs={9} sm={9} md={9} lg={9}>
								{inputValues.can_delete==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}>Poate sterge documente</p>
								}
								{inputValues.can_delete!==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}><b>Poate sterge documente</b></p>
								}
							</Grid>								
							<Grid item xs={3} sm={3} md={3} lg={3}>
								<p style={{"textAlign":"center","marginTop":"0px"}}>
									<Checkbox name="can_delete" id="can_delete" onChange={(evt) => handleCheckedSpecial(evt)} checked={(inputValues.can_delete==1?true:false)}></Checkbox>
								</p>
							</Grid>								
							<Grid item xs={9} sm={9} md={9} lg={9}>
								{inputValues.can_set_urgent==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}>Poate seta urgenta</p>
								}
								{inputValues.can_set_urgent!==0 &&
									<p style={{"marginLeft":"48px","marginTop":"0px"}}><b>Poate seta urgenta</b></p>
								}
							</Grid>								
							<Grid item xs={3} sm={3} md={3} lg={3}>
								<p style={{"textAlign":"center","marginTop":"0px"}}>
									<Checkbox name="can_set_urgent" id="can_set_urgent" onChange={(evt) => handleCheckedSpecial(evt)} checked={(inputValues.can_set_urgent==1?true:false)}></Checkbox>
								</p>
							</Grid>								
							<Grid item xs={12} style={{"textAlign":"right","marginTop":"126px"}}>
								<Button label="Salveaza" icon="pi pi-check" className="p-button-sm" style={{"marginRight":"6px"}} onClick={(evt) => handleButton(evt,"save_rights")}/>
								<Button label="Inchide" icon="pi pi-times" className="p-button-secondary p-button-sm" onClick={(evt)=>setDialogValues({...dialogValues,openSections:false})}/>
							</Grid>
						</Grid>
					</TabPanel>
				</TabView>
			</Dialog>
		);
	}

	const tableColTemplate = (row_data,column) => {
		if(column.field!=="buttons") {
			return(
				<React.Fragment>
					<span className="p-column-title" style={{"width":"36%"}}>{column.header}</span>
					<p style={{"margin":"12px"}}>{row_data[column.field]}</p>
				</React.Fragment>
			);
		}
		if(column.field==="buttons") {
			return(
				<span>
					{!!(row_data.user_name!=="administrator") &&
						<React.Fragment>
							<Button label="Parola" icon="pi pi-unlock" className="p-button-sm p-button-warning" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"password",row_data)}/>
							<Button label="Editare" icon="pi pi-pencil" className="p-button-sm p-button-primary" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"edit",row_data)}/>
							<Button label="Sectiuni" icon="pi pi-plus-circle" className="p-button-sm p-button-warning" style={{"marginRight":"3px"}} onClick={(evt) => handleButton(evt,"sections",row_data)}/>
							<Button label="Sterge" icon="pi pi-trash" className="p-button-sm p-button-danger" onClick={(evt) => confirm(evt,"delete",row_data)}/>
						</React.Fragment>
					}
					{!!(row_data.user_name==="administrator") &&
						<React.Fragment>
							<Button label="Parola" icon="pi pi-unlock" className="p-button-sm p-button-warning" onClick={(evt) => handleButton(evt,"password",row_data)}/>
						</React.Fragment>
					}
				</span>
			);
		}
	}

	const confirm = (evt,what,elementData) => {
		if(what==="delete") {
			confirmDialog({
				message: "Sigur doriti stergerea? Operatia este ireversibila!",
				header: "Confirmare stergere",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Da",
				rejectLabel: "Nu",
				accept: () => handleButton(evt,what,elementData),
				reject: () => null,
			});
		}
	}

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}>
					<div className="datatable-responsive">
						<Button label="Adauga" icon="pi pi-plus-circle" className="p-button-sm p-button-success" style={{"float":"right","marginBottom":"12px"}} onClick={(evt) => {handleButton(evt,"add")}}/>
						<br clear="all"/>
						<DataTable 
							value={dataValues.usersList}
							stripedRows
							autoLayout={true}
							paginator rows={10} emptyMessage="Nu exista inregistrari" currentPageReportTemplate="Afisez {first} pana la {last} din {totalRecords} inregistrari"
							selectionMode="single"
							selection={selectedElements.usersTable}
							onSelectionChange={evt=>{setSelectedElements({...selectedElements,usersTable:evt.value})}}
							className="p-datatable-responsive p-datatable-sm"
							style={{"width":"100%"}}
						>
							<Column field="full_user_name" header="Utilizator" body={tableColTemplate}/>
							<Column field="name" header="Nume" body={tableColTemplate}/>
							<Column field="active" header="Activ" body={tableColTemplate}/>
							<Column field="role" header="Tip utilizator" body={tableColTemplate}/>
							<Column field="reg_date_parsed" header="Adaugat" body={tableColTemplate}/>
							<Column field="buttons" header="" body={tableColTemplate}/>
						</DataTable>
					</div>
				</Box>
				<Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}>
					<div>
						<Button label="Adauga" icon="pi pi-plus-circle" className="p-button-sm p-button-success" style={{"float":"right","marginBottom":"12px"}} onClick={(evt) => {handleButton(evt,"add")}}/>
						<br clear="all"/>
						<DataTable 
							value={dataValues.usersList}
							stripedRows
							paginator rows={10} emptyMessage="Nu exista inregistrari" currentPageReportTemplate="Afisez {first} pana la {last} din {totalRecords} inregistrari"
							selectionMode="single"
							selection={selectedElements.usersTable}
							onSelectionChange={evt=>{setSelectedElements({...selectedElements,usersTable:evt.value})}}
							style={{"width":"100%"}}
							responsiveLayout="scroll"
						>
							<Column field="full_user_name" header="Utilizator" sortable/>
							<Column field="name" header="Nume" sortable/>
							<Column field="active" header="Activ" sortable/>
							<Column field="role" header="Tip utilizator" sortable/>
							<Column field="reg_date_parsed" header="Adaugat" sortable/>
							<Column field="buttons" header="" body={tableColTemplate} style={{"textAlign":"center"}}/>
						</DataTable>
					</div>
				</Box>
				<ConfirmDialog />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openChangePassword && changePasswordDialog()}
				{dialogValues.openSections && sectionsDialog()}
            </BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<LinearProgress />
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);		
	}
};

export default Users;

