import React from "react";
import Grid from "@mui/material/Grid";
import { Button } from "primereact/button";
import { Badge } from 'primereact/badge';
import history from "../../Sources/js/history.js";

let documents={
	commented: 0,
	accepted: 0,
	refused: 0,
	closed: 0,
}

const DashboardDocumentsList = (props) => {

	const handleButton = (evt,what) => {
		if(what==="commented") {
			history.push("/"+props.type+"/"+what+"/"+props.data.commented);
		}
		if(what==="accepted") {
			history.push("/"+props.type+"/"+what+"/"+props.data.accepted);
		}
		if(what==="refused") {
			history.push("/"+props.type+"/"+what+"/"+props.data.refused);
		}
		if(what==="closed") {
			history.push("/"+props.type+"/"+what+"/"+props.data.closed);
		}
		if((what==="all")||(what==="urgent")||(what==="today")||(what==="seven_days")||(what==="outdated")) {
			history.push("/"+props.type+"/"+what);			
		}
	}

	if(props.type==="recentEvents") {
//		console.log(props.data.commented+" vs "+window.localStorage.getItem("documents_commented"));
//		console.log(props.data.accepted+" vs "+window.localStorage.getItem("documents_accepted"));
//		console.log(props.data.refused+" vs "+window.localStorage.getItem("documents_refused"));
//		console.log(props.data.closed+" vs "+window.localStorage.getItem("documents_closed"));
		if(props.data.commented!=window.localStorage.getItem("documents_commented")) {
			documents.commented=1;
		}else{
			documents.commented=0;
		}

		if(props.data.accepted!=window.localStorage.getItem("documents_accepted")) {
			documents.accepted=1;
		}else{
			documents.accepted=0;
		}

		if(props.data.refused!=window.localStorage.getItem("documents_refused")) {
			documents.refused=1;
		}else{
			documents.refused=0;
		}

		if(props.data.closed!=window.localStorage.getItem("documents_closed")) {
			documents.closed=1;
		}else{
			documents.closed=0;
		}
	}
	if(props.data) {
		if(props.type==="recentEvents") {
			return(
				<Grid container spacing={1} style={{}}>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#A855F7"}}>Comentarii recente</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
						{documents.commented===1 &&
							<Badge value="!" severity="danger"></Badge>
						}
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"commented")} />
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#689F38"}}>Acceptari recente</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
						{documents.accepted===1 &&
							<Badge value="!" severity="danger"></Badge>
						}
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-success" onClick={(evt) => handleButton(evt,"accepted")} />
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#FF0000"}}>Refuzuri recente</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
						{documents.refused===1 &&
							<Badge value="!" severity="danger"></Badge>
						}
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-danger" onClick={(evt) => handleButton(evt,"refused")} />
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#A855F7"}}>Inchideri recente</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
						{documents.closed===1 &&
							<Badge value="!" severity="danger"></Badge>
						}
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"closed")} />
					</Grid>
				</Grid>
			);
		}else{
			return(
				<Grid container spacing={1} style={{}}>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#A855F7"}}>Toate documentele</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#A855F7"}}>{props.data.total}</p>
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-primary" onClick={(evt) => handleButton(evt,"all")} />
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#FF0000"}}>Urgente</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#FF0000"}}>{props.data.urgent}</p>
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-danger" onClick={(evt) => handleButton(evt,"urgent")} />
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#FF0000"}}>De rezolvat astazi</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#FF0000"}}>{props.data.today}</p>
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-danger" onClick={(evt) => handleButton(evt,"today")} />
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#689F38"}}>Urmatoarele 7 zile</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#689F38"}}>{props.data.seven_days}</p>
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-success" onClick={(evt) => handleButton(evt,"seven_days")} />
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={7} style={{"textAlign":"left","backgroundColor":"#FF0000"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#FFFFFF"}}>Cu termen depasit</p>
					</Grid>
					<Grid item xs={1} sm={1} md={1} lg={1} style={{"textAlign":"left","backgroundColor":"#FF0000"}}>
						<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#FFFFFF"}}>{props.data.outdated}</p>
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<Button type="button" label="Afiseaza" icon="pi pi-list" className="p-button-sm p-button-danger" onClick={(evt) => handleButton(evt,"outdated")} />
					</Grid>
				</Grid>
			);
		}
	}else{
		return(
			<Grid container spacing={1} style={{}}>
				<Grid item xs={12} sm={12} md={12} lg={12} style={{"textAlign":"left"}}>
					<p className="p_dashboard_label" style={{"fontWeight":"600","color":"#FF0000"}}>Eroare la citirea listei de documente.</p>
				</Grid>
			</Grid>
		);
	}
};

export default DashboardDocumentsList;


