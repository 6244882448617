import React from "react";
import {useState,useEffect} from "react";
import { useLocation } from "react-router-dom";
import NewDocumentSelect from "../Sections/NewDocumentSelect.js";
import DocumentEntry from "./DocumentEntry.js";
import DocumentInternal from "./DocumentInternal.js";
import DocumentExitFromOffice from "./DocumentExitFromOffice.js";
import DocumentExitRelated from "./DocumentExitRelated.js";
import history from "../../Sources/js/history.js";

let relatedDocDetails={};

const NewDocument = (props) => {

	const [step, setStep] = useState("select");
	const [save, setSave] = useState(null);

	const location = useLocation();

	const handleChangeType = (evt,type) => {
		setStep(type);
	}

	const handleSetSave = evt => {
		setSave(step);
	}

	const handleResetSave = evt => {
		setSave(null);
	}
	
	useEffect(() => {
		if(!props.session.sessId) {
			history.push("/login");
		}
		relatedDocDetails={};
		relatedDocDetails.doc_related_no_1=location.pathname.split("/")[3];
		relatedDocDetails.doc_related_year_1=location.pathname.split("/")[4];
		relatedDocDetails.doc_related_no_2=location.pathname.split("/")[5];
		relatedDocDetails.doc_related_year_2=location.pathname.split("/")[6];
		relatedDocDetails.doc_related_no_3=location.pathname.split("/")[7];
		relatedDocDetails.doc_related_year_3=location.pathname.split("/")[8];
		if(props.step==="entry") {
			setStep("Intrare");
		}
		if(props.step==="intern") {
			setStep("Intern");
		}
		if(props.step==="exitFromOffice") {
			setStep("Iesire din oficiu");
		}
		if(props.step==="exitRelated") {
			setStep("Iesire relationata");
		}
	},[]);

	return(
		<React.Fragment>
			<NewDocumentSelect step={step} session={props.session} handleChangeType={handleChangeType} handleSetSave={handleSetSave}/>
			{step==="Intrare" &&
				<DocumentEntry session={props.session} save={save} handleResetSave={handleResetSave} relatedDocDetails={relatedDocDetails}/>
			}
			{step==="Intern" &&
				<DocumentInternal session={props.session} save={save} handleResetSave={handleResetSave} relatedDocDetails={relatedDocDetails}/>
			}
			{step==="Iesire din oficiu" &&
				<DocumentExitFromOffice session={props.session} save={save} handleResetSave={handleResetSave} relatedDocDetails={relatedDocDetails}/>
			}
			{step==="Iesire relationata" &&
				<DocumentExitRelated session={props.session} save={save} handleResetSave={handleResetSave} relatedDocDetails={relatedDocDetails}/>
			}
		</React.Fragment>
	)
};

export default NewDocument;
