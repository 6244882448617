import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import { BlockUI } from 'primereact/blockui';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";

let oper="";
let defaultInputValues={
	id: 1,
	name: "",
	address: "",
	reg_no: "",
	phone: "",
	email: "",
	observations: "",
};

const UnitSettings = (props) => {

	var timerId=null;

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [blockScreen, setBlockScreen] = useState(false);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="save") {
			let url="saveUnitSettings";
			url+="?session_id="+props.session.sessId;
			oper="save";
			let data=inputValues;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="get") {
            if(!dataOk) {
                setDataOk(true);
            }
			if(result.data) {
				setInputValues(result.data);
			}
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get";
			let url="getUnitSettings";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	if(dataOk) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<Grid container spacing={1} style={{"paddingTop":"24px"}}>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<span className="p-float-label">
							<InputText name="name" id="name" style={{"width":"100%"}} value={inputValues.name} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="name">Nume</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<span className="p-float-label">
							<InputText name="address" id="address" style={{"width":"100%"}} value={inputValues.address} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="address">Adresa</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<span className="p-float-label">
							<InputText name="reg_no" id="reg_no" style={{"width":"100%"}} value={inputValues.reg_no} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="reg_no">CUI</label>
						</span>
						<div style={{"height":"3px"}}></div>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<span className="p-float-label">
							<InputText name="phone" id="phone" style={{"width":"100%"}} value={inputValues.phone} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="phone">Telefon</label>
						</span>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<span className="p-float-label">
							<InputText name="email" id="email" style={{"width":"100%"}} value={inputValues.email} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="email">Email</label>
						</span>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<span className="p-float-label">
							<InputText name="observations" id="observations" style={{"width":"100%"}} value={inputValues.observations} onChange={(evt) => handleChange(evt)}/>
							<label htmlFor="observations">Observatii</label>
						</span>
					</Grid>
					<Grid item xs={12} style={{"textAlign":"right"}}>
						<React.Fragment>
							<Button label="Salveaza" icon="pi pi-check-circle" className="p-button-sm" style={{}} onClick={(evt) => handleButton(evt,"save")}/>
						</React.Fragment>
					</Grid>
				</Grid>
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</BlockUI>
		);
	}else{
		return(
			<LinearProgress />
		);
	}
};

export default UnitSettings;

