import React from "react";
import {useState,useEffect} from "react";
import Grid from "@mui/material/Grid";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import { BlockUI } from 'primereact/blockui';
import { InputTextarea } from "primereact/inputtextarea";
import { Card } from 'primereact/card';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post} from "../../Sources/js/app.js";
import {get_api_path} from "../../Sources/js/paths.js";

let oper="";
let today = new Date();
let day=today.getUTCDate();
let month = today.getMonth()+1;
let year = today.getFullYear();
if(month<10) {
	month="0"+month;
}

let defaultItems=[...[],{
	iid: Math.random(99),
	name: "",
	characteristics: "",
	quantity: "",
	price: "",
	total_price: 0,
}];

const NeedReport = (props) => {

	var timerId=null;

	const [messageText, setMessageText] = useState(null);
    const [blockScreen, setBlockScreen] = useState(false);
	const [pdfUrl, setPdfUrl] = useState(null);
	const [items, setItems] = useState(defaultItems);

	const handleChange = (evt,elementData) => {
		const {name, value} = evt.target;
		if(evt.target.validity.valid) {
			let i=items.findIndex((item)=>item.iid===elementData.iid);
			let tmp_items=[...items];
			let tmp_item={...tmp_items[i],[name]:value};;
			if(name==="quantity") {
				if(!isNaN(value)) {
					tmp_item.total_price=items[i].price*value;
				}else{
					setMessageText("Cantitatea trebuie sa fie un numar!");
				}
			}
			if(name==="price") {
				if(!isNaN(value)) {
					tmp_item.total_price=items[i].quantity*value;
				}else{
					setMessageText("Pretul trebuie sa fie un numar!");
				}
			}
			tmp_items[i]=tmp_item;
			setItems(tmp_items);
		}
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add_item") {
			setItems(items=>[...items,{
				iid: Math.random(99),
				name: "",
				characteristics: "",
				quantity: "",
				price: "",
				total_price: 0,
			}]);
		}
		if(what==="delete_item") {
			setItems(items.filter((item)=>item.iid!==elementData.iid));
		}
		if(what==="generate_pdf") {
			if(items.length<1) {
				setMessageText("Adaugati cel putin un reper!");
				return false;				
			}
			let url="pdfNeedReport";
			url+="?session_id="+props.session.sessId;
			oper="generate_pdf";
			let data={};
			data.doc_id=props.document.id;
			data.doc_no=props.document.doc_no;
			data.doc_date=props.document.doc_date;
			data.items=items;
			timerId=fetch_url_post(url,data,success_handler,fail_handler,wait_handler);
		}
	}

	const success_handler = (result) => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
			setMessageText("");
		}
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
			return false;
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="generate_pdf") {
            if(!pdfUrl) {
				setPdfUrl(get_api_path()+"getPdf"+"?session_id="+props.session.sessId+"&file="+result.data);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setBlockScreen(false);
		if(timerId) {
			clearTimeout(timerId);
		}
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const wait_handler = () => {
		setBlockScreen(true);
		setMessageText("Operatiunea este in curs de executie. Va rugam asteptati...");
	}

	if(!pdfUrl) {
		return(
			<BlockUI blocked={blockScreen} fullScreen>
				<div style={{"height":"521px"}}>
				<p style={{"fontWeight":"600","textAlign":"center","color":"#FF0000","marginBottom":"36px","fontSize":"24px"}}>Referat de necesitate si oportunitate</p>
				<div style={{"textAlign":"left","marginBottom":"3px","width":"48%","float":"left"}}>
					<Button type="button" label="Adauga reper" icon="pi pi-plus" className="p-button-sm p-button-warning" style={{}} onClick={(evt) => handleButton(evt,"add_item",null)} />					
				</div>
				<div style={{"textAlign":"right","marginBottom":"3px","width":"48%","float":"right"}}>
					<Button type="button" label="Genereaza referat" icon="pi pi-check" className="p-button-sm p-button-primary" style={{"marginRight":"12px"}} onClick={(evt) => handleButton(evt,"generate_pdf",null)} />					
					<Button type="button" label="Inapoi la lista" icon="pi pi-times" className="p-button-sm p-button-secondary" style={{"marginRight":"12px"}} onClick={(evt) => props.resetSectionHandler(0)} />					
				</div>
				<div style={{"marginBottom":"12px","clear":"both"}}></div>
				{items.map((item,i) =>
					<Card style={{"marginTop":"12px"}} key={i}>
						<Grid container spacing={1} style={{}}>
							<Grid item xs={12} sm={12} md={12} lg={5}>
								<span className="p-float-label" style={{"marginBottom":"18px"}}>
									<InputTextarea name="name" id="name" style={{"width":"100%","height":"102px"}} value={item.name} onChange={(evt) => handleChange(evt,item)} rows={10}/>
									<label htmlFor="name">Denumire produse/servicii/lucrari*</label>
								</span>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={5}>
								<span className="p-float-label" style={{"marginBottom":"18px"}}>
									<InputTextarea name="characteristics" id="characteristics" style={{"width":"100%","height":"102px"}} value={item.characteristics} onChange={(evt) => handleChange(evt,item)} rows={10}/>
									<label htmlFor="characteristics">Caracteristici</label>
								</span>
							</Grid>				
							<Grid item xs={12} sm={12} md={12} lg={1}>
								<span className="p-float-label">
									<InputText name="price" id="price" pattern="[0-9.]*" style={{"width":"100%"}} value={item.price} onChange={(evt) => handleChange(evt,item)}/>
									<label htmlFor="price">Pret unit.</label>
								</span>
								<div style={{"height":"12px"}}></div>
								<span className="p-float-label">
									<InputText name="total_price" id="total_price" style={{"width":"100%","color":"#FF0000"}} value={item.total_price} disabled={true}/>
									<label htmlFor="total_price">Total</label>
								</span>								
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={1}>
								<span className="p-float-label">
									<InputText name="quantity" id="quantity" pattern="[0-9.]*" style={{"width":"100%"}} value={item.quantity} onChange={(evt) => handleChange(evt,item)}/>
									<label htmlFor="name">Cantitate*</label>
								</span>
								{items.length>1 &&
									<Button type="button" label="Sterge" icon="pi pi-trash" className="p-button-sm p-button-danger" style={{"marginTop":"14px"}} onClick={(evt) => handleButton(evt,"delete_item",item)} />					
								}
							</Grid>
						</Grid>
					</Card>
				)}
				</div>
                {messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
            </BlockUI>
		);
	}else{
		return(
			<React.Fragment>
				<div style={{"width":"100%","textAlign":"right"}}>
					<Button type="button" label="Inapoi la lista de fisiere" icon="pi pi-angle-left" className="p-button-sm p-button-primary" style={{"marginBottom":"12px"}} onClick={(evt) => props.resetSectionHandler(0)} />					
				</div>
				<iframe src={`${pdfUrl}#view=fitH`} title="PDF" width="100%" height="501px"></iframe>
                {messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
			</React.Fragment>
		);
	}
};

export default NeedReport;
